import { processRequest } from '../../services/Api';
import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import { notificationsActionTypes } from './Constants';
import * as notificationsActions from './Actions';
import { page, perPage } from './Selectors';
import * as errorHandlerActions from '../errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			notificationsActionTypes.GET_NOTIFICATIONS_REQUEST,
			handleGetNotificationsRequest,
		),
		yield takeLatest(
			notificationsActionTypes.UPDATE_NOTIFICATION_STATUS_REQUEST,
			handleNotificationStatusRequest,
		),
	]);
}

export function* handleGetNotificationsRequest() {
	try {
		const currentPage = yield select(page);
		const perPageItems = yield select(perPage);

		const requestPayload = {
			page: currentPage,
			per_page: perPageItems,
		};
		const { data } = yield call(
			processRequest,
			'users/notifications',
			'GET',
			requestPayload,
		);

		yield put(notificationsActions.getNotificationsSuccess(data));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(notificationsActions.getNotificationsError());
	}
}

export function* handleNotificationStatusRequest(action) {
	try {
		const { notificationId } = action.payload;
		const requestPayload = {
			notification: {
				read: true,
			},
		};

		yield call(
			processRequest,
			`users/notifications/${notificationId}`,
			'PATCH',
			requestPayload,
		);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'ignore'));
		yield put(notificationsActions.updateNotificationStatusError());
	}
}
