import keyMirror from 'keymirror';
import _ from 'lodash';
import i18n from '../../utils/localization/i18next';
import { BRAND_NAME, EXIT_SURVEY_URL } from '../../services/Constants';

export const profileTypes = keyMirror({
	GET_USER_DETAILS: null,
	GET_USER_DETAILS_SUCCESS: null,
	GET_USER_DETAILS_ERROR: null,

	SET_AVATAR_IMAGE: null,
	SET_AVATAR_IMAGE_SUCCESS: null,
	SET_AVATAR_IMAGE_ERROR: null,

	CHECK_PUBLIC_NAME: null,
	CHECK_PUBLIC_NAME_REC: null,
	CHECK_PUBLIC_NAME_RES: null,
	SET_PERSONAL_DATA_REQUEST: null,
	SET_PERSONAL_DATA_SUCCESS: null,
	SET_PERSONAL_DATA_ERROR: null,

	TOGGLE_CHANGE_PASSWORD_MODAL: null,
	SET_NEW_PASSWORD: null,
	SET_NEW_PASSWORD_SUCCESS: null,
	SET_NEW_PASSWORD_ERROR: null,

	TOGGLE_CHANGE_EMAIL_MODAL: null,
	CHECK_EMAIL: null,
	SET_NEW_EMAIL: null,
	SET_NEW_EMAIL_SUCCESS: null,
	SET_NEW_EMAIL_ERROR: null,

	EMAIL_PREFERENCES_REQUEST: null,

	TOGGLE_DELETE_MODAL: null,
	DELETE_ACCOUNT_REQUEST: null,
	DELETE_ACCOUNT_SUCCESS: null,
	DELETE_ACCOUNT_ERROR: null,

	GET_NOTIFICATION_PREFERENCES_REQUEST: null,
	GET_NOTIFICATION_PREFERENCES_SUCCESS: null,
	GET_NOTIFICATION_PREFERENCES_ERROR: null,

	UPDATE_NOTIFICATION_PREFERENCES_REQUEST: null,
	UPDATE_NOTIFICATION_PREFERENCES_SUCCESS: null,
	UPDATE_NOTIFICATION_PREFERENCES_ERROR: null,

	CLEAN_PROFILE_SETTINGS_STATE: null,

	GET_USER_EDUCATION_DETAILS: null,
	GET_USER_EDUCATION_DETAILS_ERROR: null,

	UPDATE_USER_LOCALE_REQUEST: null,

	GET_USER_SUBSCRIPTION: null,
	GET_USER_SUBSCRIPTION_SUCCESS: null,
	GET_USER_SUBSCRIPTION_ERROR: null,

	CANCEL_SUBSCRIPTION_REQUEST: null,
	CANCEL_SUBSCRIPTION_SUCCESS: null,
	CANCEL_SUBSCRIPTION_ERROR: null,

	TOGGLE_CANCEL_SUBSCRIPTION_MODAL: null,
});

export const PERSONAL_INFO_FORM = 'personalInfoForm';

export const getSettingsTabs = (t) => [
	{
		title: t('profile_settings.tabs.account'),
		value: 'mijn-profiel',
		index: 0,
		link: '/mijn-profiel',
	},
	{
		title: t('profile_settings.tabs.subscription'),
		value: 'lidmaatschap',
		index: 1,
		link: '/mijn-profiel/lidmaatschap',
	},
];

export const groupBySections = (items) => {
	return (
		!!items.length &&
		_(items)
			.groupBy((prop) => prop.channel)
			.value()
	);
};

export const groupByCategory = (items) => {
	return (
		!!items.length &&
		_(items)
			.groupBy((prop) => prop.action.category)
			.value()
	);
};

export const checkIfAllSelected = (item) => item.enabled === true;
export const checkIfAllDeselected = (item) => item.enabled === false;

export const handlePreferencesStatus = (preferences, setPreferences) => {
	const ungrouped = _.flatMap(preferences);
	const everySelected = ungrouped.every(checkIfAllSelected);
	const everyDeselected = ungrouped.every(checkIfAllDeselected);

	if (everySelected) setPreferences('Alle');
	if (everyDeselected) setPreferences('Geen');
	if (!everySelected && !everyDeselected) setPreferences('Sommigen');
};

export const handleOnChangePreference = (preferences, category, term, value, setPreferences) => {
	const preferencesData = { ...preferences };

	preferencesData[category].map((item) => {
		if (item.action.term === term) {
			item.enabled = value;
		}
	});
	setPreferences(preferencesData);
};

export const NotificationSettingsDescription = i18n.t(
	'profile_settings.notifications.settings_description',
	{ brandName: BRAND_NAME },
);

export const DEFAULT_COUNTRY_ID = 1;

export const findElement = (data, key, value) =>
	data && data.options.find((item) => item[key] === value);

export const openExitSurveyLink = (data) => {
	let url = EXIT_SURVEY_URL;
	if (data) {
		url += '#' + new URLSearchParams(data).toString();
	}
	window.open(url, '_blank');
};
