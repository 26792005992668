import { onboardingActionTypes } from './Constants';

const initialState = {
	loading: false,
	grades: [],
};

export default function onboardingReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data } = payload || {};

	switch (type) {
		case onboardingActionTypes.SAVE_GRADE_REQUEST:
		case onboardingActionTypes.GET_GRADES_REQUEST:
		case onboardingActionTypes.SAVE_PASSWORD_REQUEST:
		case onboardingActionTypes.SAVE_CHILD_NAME_REQUEST:
			return {
				...state,
				loading: true,
			};

		case onboardingActionTypes.GET_GRADES_SUCCESS:
			return {
				...state,
				grades: data,
				loading: false,
			};

		case onboardingActionTypes.GET_GRADES_ERROR:
		case onboardingActionTypes.SAVE_GRADE_SUCCESS:
		case onboardingActionTypes.SAVE_GRADE_ERROR:
		case onboardingActionTypes.SAVE_PASSWORD_SUCCESS:
		case onboardingActionTypes.SAVE_PASSWORD_ERROR:
		case onboardingActionTypes.SAVE_CHILD_NAME_SUCCESS:
		case onboardingActionTypes.SAVE_CHILD_NAME_ERROR:
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
}
