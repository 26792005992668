import { forgotPasswordActionTypes } from './Constants';

export function forgotPasswordRequest(email) {
	return {
		type: forgotPasswordActionTypes.FORGOT_PASSWORD_REQUEST,
		payload: {
			email,
		},
	};
}

export function forgotPasswordSuccess() {
	return {
		type: forgotPasswordActionTypes.FORGOT_PASSWORD_SUCCESS,
	};
}

export function forgotPasswordError() {
	return {
		type: forgotPasswordActionTypes.FORGOT_PASSWORD_ERROR,
	};
}

export function setInitialEmail(email) {
	return {
		type: forgotPasswordActionTypes.SET_INITIAL_EMAIL,
		payload: {
			email,
		},
	};
}
