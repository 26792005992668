import { processRequest } from '../../../services/Api';
import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import i18n from 'i18next';
import { subjectsActionTypes } from './Constants';
import * as subjectsActions from './Actions';
import * as errorHandlerActions from '../../../components/errorHandler/Actions';
import * as notificationActions from '../../../components/notification/Actions';

export default function* () {
	yield all([
		yield takeLatest(subjectsActionTypes.GET_ADMIN_SUBJECTS_REQUEST, handleGetSubjectsRequest),
		yield takeLatest(
			subjectsActionTypes.CHANGE_ADMIN_SUBJECT_POSITION_REQUEST,
			handleChangeSubjectPositionRequest,
		),
		yield takeLatest(
			subjectsActionTypes.DELETE_ADMIN_SUBJECT_REQUEST,
			handleDeleteSubjectRequest,
		),
	]);
}

export function* handleGetSubjectsRequest() {
	try {
		const { data } = yield call(processRequest, 'admin/subjects');

		yield put(subjectsActions.getSubjectsSuccess(data.results));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(subjectsActions.getSubjectsError());
	}
}

export function* handleChangeSubjectPositionRequest({ payload }) {
	try {
		const { id, position, callback } = payload || {};

		yield call(processRequest, `admin/subjects/${id}`, 'PATCH', {
			subject: {
				position,
			},
		});

		callback && callback();
		yield put(subjectsActions.changeSubjectPositionSuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(subjectsActions.changeSubjectPositionError());
	}
}

export function* handleDeleteSubjectRequest({ payload }) {
	try {
		const { subjectId, callback } = payload || {};

		yield call(processRequest, `admin/subjects/${subjectId}`, 'DELETE');

		yield put(
			notificationActions.createNotification(
				i18n.t('admin_subjects.delete_notification'),
				null,
				false,
				3000,
			),
		);

		callback && callback();
		yield put(subjectsActions.deleteSubjectSuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(subjectsActions.deleteSubjectError());
	}
}
