import keyMirror from 'keymirror';
import _ from 'lodash';
import moment from 'moment';

export const notificationsActionTypes = keyMirror({
	TOGGLE_NOTIFICATIONS_MENU: null,

	GET_NOTIFICATIONS_REQUEST: null,
	GET_NOTIFICATIONS_SUCCESS: null,
	GET_NOTIFICATIONS_ERROR: null,

	UPDATE_NOTIFICATION_STATUS_REQUEST: null,
	UPDATE_NOTIFICATION_STATUS_ERROR: null,
});

const MOMENT_FORMAT = 'DD MMMM';

export const getTime = (createdAt, t) => {
	const dayBeforeYesterday = moment().subtract(2, 'days').format();
	const time = moment(createdAt).fromNow();
	// TODO need solve this compare for localization
	if (time === 'één dag geleden') return t('general.yesterday');
	if (time === '2 dagen geleden') return t('general.day_before_yesterday');

	if (createdAt < dayBeforeYesterday) return moment(createdAt).format(MOMENT_FORMAT);

	return time;
};

export const orderNotifications = (arr, orderItem, order) =>
	_(arr)
		.orderBy((item) => item[orderItem], order)
		.value();
