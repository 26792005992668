/* eslint-disable no-useless-return */
export const componentLoader = (lazyComponent, attemptsLeft) => {
	return new Promise((resolve, reject) => {
		lazyComponent()
			.then(resolve)
			.catch((error) => {
				// let us retry after 2000 ms
				setTimeout(() => {
					if (attemptsLeft === 1) {
						reject(error);
						return;
					}
					componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
				}, 2000);
			});
	});
};
