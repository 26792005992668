import { shareVideoModalActionTypes } from './Constants';

export function toggleShareVideoModal(data) {
	return {
		type: shareVideoModalActionTypes.TOGGLE_SHARE_VIDEO_MODAL,
		payload: { data },
	};
}

export function getShareVideoLink(id) {
	return {
		type: shareVideoModalActionTypes.GET_SHARE_VIDEO_LINK,
		payload: { id },
	};
}

export function getShareVideoLinkSuccess(data) {
	return {
		type: shareVideoModalActionTypes.GET_SHARE_VIDEO_LINK_SUCCESS,
		payload: { data },
	};
}

export function getShareVideoLinkError(error) {
	return {
		type: shareVideoModalActionTypes.GET_SHARE_VIDEO_LINK_ERROR,
		payload: { error },
	};
}
