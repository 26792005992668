import keyMirror from 'keymirror';

export const signInActionTypes = keyMirror({
	LOGIN_REQUEST: null,
	LOGIN_SUCCESS: null,
	LOGIN_ERROR: null,

	FIRST_VERIFY_REQUEST: null,
	FIRST_VERIFY_SUCCESS: null,
	FIRST_VERIFY_ERROR: null,

	SAVE_ACCESS_TOKEN: null,
	SET_SOCIAL_LOGIN_STATE: null,
});

export const PROVIDERS = {
	FACEBOOK: 'facebook',
	GOOGLE: 'google',
	APPLE: 'apple',
};

export const CLIENT_ID = '987857138378-93tunb79da2us83pnkbvv3s59vt5kjaa.apps.googleusercontent.com';

export const SIGN_IN_FORM = 'signInForm';
