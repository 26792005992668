import { adminInstructionActionTypes } from './Constants';

export function getInstructionRequest(topicId) {
	return {
		type: adminInstructionActionTypes.GET_TOPIC_INSTRUCTION_REQUEST,
		payload: { topicId },
	};
}

export function getInstructionSuccess(data) {
	return {
		type: adminInstructionActionTypes.GET_TOPIC_INSTRUCTION_SUCCESS,
		payload: { data },
	};
}

export function getInstructionError() {
	return {
		type: adminInstructionActionTypes.GET_TOPIC_INSTRUCTION_ERROR,
	};
}

export function manageInstructionRequest(data) {
	return {
		type: adminInstructionActionTypes.MANAGE_TOPIC_INSTRUCTION_REQUEST,
		payload: { data },
	};
}

export function manageInstructionSuccess(data) {
	return {
		type: adminInstructionActionTypes.MANAGE_TOPIC_INSTRUCTION_SUCCESS,
		payload: { data },
	};
}

export function manageInstructionError() {
	return {
		type: adminInstructionActionTypes.MANAGE_TOPIC_INSTRUCTION_ERROR,
	};
}

export function previewInstructionRequest(topicId) {
	return {
		type: adminInstructionActionTypes.PREVIEW_TOPIC_INSTRUCTION_REQUEST,
		payload: { topicId },
	};
}

export function previewInstructionSuccess(data) {
	return {
		type: adminInstructionActionTypes.PREVIEW_TOPIC_INSTRUCTION_SUCCESS,
		payload: { data },
	};
}

export function previewInstructionError() {
	return {
		type: adminInstructionActionTypes.PREVIEW_TOPIC_INSTRUCTION_ERROR,
	};
}

export function publishInstructionRequest(topicId) {
	return {
		type: adminInstructionActionTypes.PUBLISH_TOPIC_INSTRUCTION_REQUEST,
		payload: { topicId },
	};
}

export function publishInstructionSuccess(data) {
	return {
		type: adminInstructionActionTypes.PUBLISH_TOPIC_INSTRUCTION_SUCCESS,
		payload: { data },
	};
}

export function publishInstructionError() {
	return {
		type: adminInstructionActionTypes.PUBLISH_TOPIC_INSTRUCTION_ERROR,
	};
}

export function resetInstructionRequest(topicId) {
	return {
		type: adminInstructionActionTypes.RESET_TOPIC_INSTRUCTION_REQUEST,
		payload: { topicId },
	};
}

export function resetInstructionSuccess() {
	return {
		type: adminInstructionActionTypes.RESET_TOPIC_INSTRUCTION_SUCCESS,
	};
}

export function resetInstructionError() {
	return {
		type: adminInstructionActionTypes.RESET_TOPIC_INSTRUCTION_ERROR,
	};
}

export function deleteInstructionRequest(data) {
	return {
		type: adminInstructionActionTypes.DELETE_TOPIC_INSTRUCTION_REQUEST,
		payload: { data },
	};
}

export function deleteInstructionSuccess(data) {
	return {
		type: adminInstructionActionTypes.DELETE_TOPIC_INSTRUCTION_SUCCESS,
		payload: { data },
	};
}

export function deleteInstructionError() {
	return {
		type: adminInstructionActionTypes.DELETE_TOPIC_INSTRUCTION_ERROR,
	};
}

export function setInstruction(data) {
	return {
		type: adminInstructionActionTypes.SET_TOPIC_INSTRUCTION,
		payload: { data },
	};
}

export function setFirstLoad(data) {
	return {
		type: adminInstructionActionTypes.SET_FIRST_LOAD,
		payload: { data },
	};
}

export function clearState() {
	return {
		type: adminInstructionActionTypes.CLEAR_INSTRUCTION_STATE,
	};
}
