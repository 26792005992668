import keyMirror from 'keymirror';

export const playButtonActionTypes = keyMirror({
	GET_SONG_REQUEST: null,
	GET_SONG_SUCCESS: null,

	REMOVE_SONG: null,
	PAUSE_SONG: null,

	IS_AUDIO_PLAYING: null,
	SET_SONG_ID: null,
});
