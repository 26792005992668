import keyMirror from 'keymirror';

export const pushNotificationActionTypes = keyMirror({
	REGISTER_PUSH_NOTIFICATION_REQUEST: null,
	REGISTER_PUSH_NOTIFICATION_SUCCESS: null,
	REGISTER_PUSH_NOTIFICATION_ERROR: null,

	DEREGISTER_PUSH_NOTIFICATION_REQUEST: null,
	DEREGISTER_PUSH_NOTIFICATION_SUCCESS: null,
	DEREGISTER_PUSH_NOTIFICATION_ERROR: null,
});
