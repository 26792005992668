import keyMirror from 'keymirror';
import i18n from 'i18next';

export const manageDifficultyActionTypes = keyMirror({
	TOGGLE_MANAGE_DIFFICULTY_MODAL: null,

	MANAGE_DIFFICULTY_REQUEST: null,
	MANAGE_DIFFICULTY_SUCCESS: null,
	MANAGE_DIFFICULTY_ERROR: null,

	CLEAN_MANAGE_DIFFICULTY_STATE: null,
});

export const difficultyLevelOptions = [
	{ key: 1, title: i18n.t('difficulty_level_options.level_1') },
	{ key: 2, title: i18n.t('difficulty_level_options.level_2') },
	{ key: 3, title: i18n.t('difficulty_level_options.level_3') },
	{ key: 4, title: i18n.t('difficulty_level_options.level_4') },
	{ key: 5, title: i18n.t('difficulty_level_options.level_5') },
	{ key: 6, title: i18n.t('difficulty_level_options.level_6') },
	{ key: 7, title: i18n.t('difficulty_level_options.level_7') },
	{ key: 8, title: i18n.t('difficulty_level_options.level_8') },
	{ key: 9, title: i18n.t('difficulty_level_options.level_9') },
	{ key: 10, title: i18n.t('difficulty_level_options.level_10') },
	{ key: 11, title: i18n.t('difficulty_level_options.level_11') },
	{ key: 12, title: i18n.t('difficulty_level_options.level_12') },
];
