import { switchPackageModalActionTypes } from './Constants';

const initialState = {
	open: false,
	selectedSubscription: null,
	loading: false,
	selectedPackage: null,
	upgradePackageLoading: false,
};

export default function switchPackageModalReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data, selectedPackage } = payload || {};

	switch (type) {
		case switchPackageModalActionTypes.TOGGLE_SWITCH_PACKAGE_MODAL:
			return {
				...state,
				...data,
			};

		case switchPackageModalActionTypes.GET_PACKAGE_DETAILS_REQUEST:
			return {
				...state,
				loading: true,
			};

		case switchPackageModalActionTypes.GET_PACKAGE_DETAILS_SUCCESS:
			return {
				...state,
				selectedPackage,
				loading: false,
			};

		case switchPackageModalActionTypes.GET_PACKAGE_DETAILS_ERROR:
			return initialState;

		case switchPackageModalActionTypes.UPGRADE_PACKAGE_REQUEST:
			return {
				...state,
				upgradePackageLoading: true,
			};

		case switchPackageModalActionTypes.UPGRADE_PACKAGE_SUCCESS:
			return {
				...state,
				upgradePackageLoading: false,
			};

		case switchPackageModalActionTypes.CLEAR_SWITCH_PACKAGE_MODAL_STATE:
			return initialState;

		default:
			return state;
	}
}
