import keyMirror from 'keymirror';
import React from 'react';

export const onboardingActionTypes = keyMirror({
	SAVE_GRADE_REQUEST: null,
	SAVE_GRADE_SUCCESS: null,
	SAVE_GRADE_ERROR: null,

	GET_GRADES_REQUEST: null,
	GET_GRADES_SUCCESS: null,
	GET_GRADES_ERROR: null,

	SAVE_PASSWORD_REQUEST: null,
	SAVE_PASSWORD_SUCCESS: null,
	SAVE_PASSWORD_ERROR: null,

	SAVE_CHILD_NAME_REQUEST: null,
	SAVE_CHILD_NAME_SUCCESS: null,
	SAVE_CHILD_NAME_ERROR: null,

	SET_REFERENCE_CHANEL: null,
	SET_REFERENCE_CHANEL_SUCCESS: null,
	SET_REFERENCE_CHANEL_ERROR: null,
});

export const SAVE_PASSWORD_FORM = 'savePasswordForm';
export const SAVE_CHILD_NAME_FORM = 'saveChildNameForm';

export const SET_CHILD_NAME_PATH = '/naam-aanmaken';

export const ANSWERS_LIST_NUMBER = 8;
