import keyMirror from 'keymirror';

export const addWysiwygAnswerModalActionTypes = keyMirror({
	HANDLE_ADD_WYSIWYG_ANSWER_MODAL: null,
});

export const createAnswerNode = (data, node) => {
	const nodeModule = node || document.createElement('span');

	if (data.hint) {
		const hint = document.createElement('span');
		hint.setAttribute('class', 'hint-text');
		hint.innerText = data.hint;
		nodeModule.appendChild(hint);
	}

	const answer = document.createElement('span');
	answer.setAttribute('class', 'answer-text');
	answer.innerText = data.answer;
	nodeModule.appendChild(answer);
	nodeModule.setAttribute('contenteditable', false);
	nodeModule.setAttribute('data-val', JSON.stringify(data));
	nodeModule.setAttribute('data-id', data.id);
	nodeModule.classList.add('inline-answer');

	return nodeModule;
};
