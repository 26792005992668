import keyMirror from 'keymirror';

export const adminActivationCodesActionTypes = keyMirror({
	GET_ACTIVATION_CODES_REQUEST: null,
	GET_ACTIVATION_CODES_SUCCESS: null,
	GET_ACTIVATION_CODES_ERROR: null,
	CHANGE_ACTIVATION_CODES_FILTERS: null,
	GET_ACTIVATION_CODE_INFO_REQUEST: null,
	GET_ACTIVATION_CODE_INFO_SUCCESS: null,
	GET_ACTIVATION_CODE_INFO_ERROR: null,
	CHANGE_ACTIVATION_CODE_STATUS_REQUEST: null,
	CHANGE_ACTIVATION_CODE_STATUS_SUCCESS: null,
	CHANGE_ACTIVATION_CODE_STATUS_ERROR: null,
	CLEAR_ACTIVATION_CODE_INFO: null,
});

export const getSortOptions = (t) => [
	{
		value: '1',
		title: t('admin_users.sort_options.by_date_desc'),
		options: {
			sort_field: 'created_at',
			sort_direction: 'desc',
		},
	},
	{
		value: '2',
		title: t('admin_users.sort_options.by_date_asc'),
		options: {
			sort_field: 'created_at',
			sort_direction: 'asc',
		},
	},
];
