import { manageCategoryActionTypes } from './Constants';

const initialState = {
	open: false,
	loading: false,
	subject: {},
	callback: null,
	categoryId: null,
	categoryData: null,
};

export default function manageCategoryReducer(state = initialState, action) {
	const { type, payload } = action;
	const { categoryId, name, open, subject } = payload || {};

	switch (type) {
		case manageCategoryActionTypes.TOGGLE_ADMIN_MANAGE_CATEGORY_MODAL:
			return {
				...state,
				open,
				categoryId,
				subject,
			};
		case manageCategoryActionTypes.CLEAN_ADMIN_MANAGE_CATEGORY_STATE:
			return initialState;
		case manageCategoryActionTypes.GET_ADMIN_CATEGORY_DATA_REQUEST:
		case manageCategoryActionTypes.MANAGE_ADMIN_CATEGORY_REQUEST:
			return {
				...state,
				loading: true,
			};
		case manageCategoryActionTypes.GET_ADMIN_CATEGORY_DATA_ERROR:
		case manageCategoryActionTypes.MANAGE_ADMIN_CATEGORY_ERROR:
			return {
				...state,
				loading: false,
			};
		case manageCategoryActionTypes.GET_ADMIN_CATEGORY_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				categoryData: { name },
				subject,
			};
		case manageCategoryActionTypes.MANAGE_ADMIN_CATEGORY_SUCCESS:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}
