import keyMirror from 'keymirror';
import i18n from 'i18next';

export const dailyGoalActionTypes = keyMirror({
	GET_DAILY_GOAL_REPORT_REQUEST: null,
	GET_DAILY_GOAL_REPORT_SUCCESS: null,
	GET_DAILY_GOAL_REPORT_ERROR: null,

	GET_DAILY_GOAL_REFLECTIONS_REQUEST: null,
	GET_DAILY_GOAL_REFLECTIONS_SUCCESS: null,
	GET_DAILY_GOAL_REFLECTIONS_ERROR: null,

	SEND_DAILY_GOAL_REFLECTION_REQUEST: null,
	SEND_DAILY_GOAL_REFLECTION_SUCCESS: null,
	SEND_DAILY_GOAL_REFLECTION_ERROR: null,

	GET_DAILY_GOAL_STICKERS_REQUEST: null,
	GET_DAILY_GOAL_STICKERS_SUCCESS: null,
	GET_DAILY_GOAL_STICKERS_ERROR: null,

	SEND_DAILY_GOAL_STICKER_REQUEST: null,
	SEND_DAILY_GOAL_STICKER_SUCCESS: null,
	SEND_DAILY_GOAL_STICKER_ERROR: null,

	GET_USER_STICKERS_REQUEST: null,
	GET_USER_STICKERS_SUCCESS: null,
	GET_USER_STICKERS_ERROR: null,

	GET_PROFILE_STICKERS_REQUEST: null,
	GET_PROFILE_STICKERS_SUCCESS: null,
	GET_PROFILE_STICKERS_ERROR: null,

	COLLECT_REWARD_REQUEST: null,
	COLLECT_REWARD_SUCCESS: null,
	COLLECT_REWARD_ERROR: null,

	UPDATE_REWARD_REQUEST: null,
	UPDATE_REWARD_SUCCESS: null,
	UPDATE_REWARD_ERROR: null,

	GET_DAILY_GOAL_SUMMARY_REQUEST: null,
	GET_DAILY_GOAL_SUMMARY_SUCCESS: null,
	GET_DAILY_GOAL_SUMMARY_ERROR: null,

	GET_WEEK_GOAL_TILES_REQUEST: null,
	GET_WEEK_GOAL_TILES_SUCCESS: null,
	GET_WEEK_GOAL_TILES_ERROR: null,
});

export const MOTIVATIONS_TITLES_COUNT = 30;
export const ENDSCREEN_TITLES_COUNT = 50;

export const getRandomTitle = (key, titleCount) => {
	const randomNumber = Math.floor(Math.random() * titleCount) + 1;
	const randomTitle = i18n.t(`${key}_${randomNumber}`);
	const [image, ...title] = randomTitle.split(' ');
	return [image, title.join(' ')];
};

export const getReflection = (value) => {
	const reflection = i18n.t(`daily_goal.feedback.values.${value}`);
	const [image, ...title] = reflection.split(' ');
	return [image, title.join(' ')];
};

export const DAILY_GOAL_STEPS = {
	STATISTICS: 'statistics',
	PROGRESS: 'progress',
	FEEDBACK: 'feedback',
	STICKERS: 'stickers',
	COLOR_PICKER: 'colorPicker',
	ENDSCREEN: 'endscreen',
};

export const STICKER_STEPS = {
	CHOOSE_STICKER: 'chooseSticker',
	PLACE_STICKER: 'placeSticker',
	NOT_FULL_STICKER_SHEET: 'notFullStickerSheet',
	FULL_STICKER_SHEET: 'fullStickerSheet',
	REWARD_SCREEN: 'rewardScreen',
};

export const STICKER_SPOT_CLASS = 'stickerSpot';
export const STICKER_COUNT_FOR_REWARD = 5;
export const MAX_NAME_GENERATION_ATTEMPTS = 5;
export const NAMES_TO_SHOW = 3;

export const COLORS_TO_PICK = {
	ORANGE: 'orange',
	YELLOW: 'yellow',
	GREEN: 'green',
	TURQUOISE: 'turquoise',
	AQUA: 'aqua',
	LAVENDER: 'lavender',
	PINK: 'pink',
	RED: 'red',
};

export const PATTERNS_TO_PICK = {
	NONE: 'none',
	ZIGZAG: 'zigzag',
	WIGGLES: 'wiggles',
	HASH: 'hash',
	LINES: 'lines',
	PLUSES: 'pluses',
	WAVES: 'waves',
};

export const PROGRESS_TYPES = {
	LEVEL_UP: 'level_up',
	DEVELOPMENT: 'development',
};

export const WEEK_GOAL_MOBILE_MAPPING = {
	2: { left: '-105vw', width: '225%' },
	3: { left: '-65vw', width: '140%' },
	4: { left: '-45vw', width: '98%' },
	5: { left: '-35vw', width: '75%' },
	6: { left: '-28vw', width: '60%' },
	7: { left: '-23vw', width: '50%' },
};

export const WEEK_GOAL_DESKTOP_MAPPING = {
	2: { left: '-40vw', width: '326%' },
	3: { left: '-40vw', width: '270%' },
	4: { left: '-40vw', width: '235%' },
	5: { left: '-36vw', width: '187%' },
	6: { left: '-30vw', width: '150%' },
	7: { left: '-23vw', width: '124%' },
};
