import { createActivationCodeActionTypes } from './Constants';

const initialState = {
	open: false,
	loading: true,
	loadingSubmit: false,
	packages: [],
};

export default function createActivationCodeReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data } = payload || {};

	switch (type) {
		case createActivationCodeActionTypes.TOGGLE_CREATE_ACTIVATION_CODE_MODAL:
			return {
				...state,
				...data,
			};
		case createActivationCodeActionTypes.GET_ADMIN_PACKAGES:
			return {
				...state,
				loading: true,
			};
		case createActivationCodeActionTypes.GET_ADMIN_PACKAGES_SUCCESS:
			return {
				...state,
				packages: data,
				loading: false,
			};
		case createActivationCodeActionTypes.GET_ADMIN_PACKAGES_ERROR:
			return {
				...state,
				loading: false,
			};
		case createActivationCodeActionTypes.CREATE_ACTIVATION_CODE:
			return {
				...state,
				loadingSubmit: true,
			};
		case createActivationCodeActionTypes.CREATE_ACTIVATION_CODE_SUCCESS:
			return {
				...state,
				loadingSubmit: false,
				open: false,
			};
		case createActivationCodeActionTypes.CREATE_ACTIVATION_CODE_ERROR:
			return {
				...state,
				loadingSubmit: false,
			};
		case createActivationCodeActionTypes.CLEAN_ACTIVATION_CODE_MODAL_STATE:
			return initialState;

		default:
			return state;
	}
}
