import keyMirror from 'keymirror';

export const searchActionTypes = keyMirror({
	SEARCH_REQUEST: null,
	SEARCH_SUCCESS: null,
	SEARCH_ERROR: null,

	SET_SEARCH_VALUE: null,

	SET_NO_RESULTS: null,

	CLEAR_RESULT_SECTIONS: null,

	CLEAR_FILTERS: null,

	SET_IS_DETAILS: null,

	CLEAN_SEARCH_STATE: null,

	UPDATE_GROUP_RESULTS: null,
});

export const DEFAULT_LIMIT_FOR_SEARCH = 5;
export const DEFAULT_LIMIT_FOR_SEARCH_BOOK = 3;
export const DEFAULT_LIMIT = 20;
export const DEFAULT_OFFSET = 0;

export const SEARCH_DISPLAY_TYPES = {
	LISTS: 'lists',
	BOOKS: 'books',
	TOPICS: 'topics',
	PUBLISHER_METHODS: 'publisher_methods',
};

export const SCHOOL_TRACK_ID = 'school_track_id';
