import { processRequest } from '../../services/Api';
import { put, call, takeLatest, all, delay } from 'redux-saga/effects';
import { languageSelectorActionTypes } from './Constants';
import * as languageSelectorActions from './Actions';
import * as errorHandlerActions from '../errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			languageSelectorActionTypes.GET_AVAILABLE_LANGUAGES_REQUEST,
			handleListOptionsRequest,
		),
	]);
}

export function* handleListOptionsRequest() {
	try {
		const { data } = yield call(processRequest, 'public/locales');

		yield put(languageSelectorActions.getAvailableLanguagesSuccess(data.locales));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(languageSelectorActions.getAvailableLanguagesError());
	}
}
