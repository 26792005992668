import { stickersOnboardingActionTypes } from './Constants';

export function sendStickersOnboardedRequest() {
	return {
		type: stickersOnboardingActionTypes.SEND_STICKERS_ONBOARDED_REQUEST,
	};
}

export function sendStickersOnboardedSuccess() {
	return {
		type: stickersOnboardingActionTypes.SEND_STICKERS_ONBOARDED_SUCCESS,
	};
}

export function sendStickersOnboardedError() {
	return {
		type: stickersOnboardingActionTypes.SEND_STICKERS_ONBOARDED_ERROR,
	};
}
