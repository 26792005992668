import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import { goalSettingsActionTypes, SUBJECT_TYPES } from './Constants';
import { generateGoalSettingsPayload } from './Helpers';
import { getFrequency, getUserGradeId } from './Selectors';
import * as goalSettingsActions from './Actions';
import * as learningPathActions from '../learningPath/Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';
import * as sharedActions from '../../shared/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			goalSettingsActionTypes.GET_GOAL_SETTINGS_REQUEST,
			handleGetGoalSettingsRequest,
		),
		yield takeLatest(
			goalSettingsActionTypes.GET_GOAL_DURATIONS_REQUEST,
			handleGetDurationsRequest,
		),
		yield takeLatest(
			goalSettingsActionTypes.SAVE_GOAL_SETTING_REQUEST,
			handleSaveGoalSettingsRequest,
		),
		yield takeLatest(goalSettingsActionTypes.GET_FREQUENCY_REQUEST, handleGetFrequencyRequest),
	]);
}

export function* handleGetGoalSettingsRequest() {
	try {
		const { data } = yield call(processRequest, 'goals/subjects');

		let subjects;
		const gradeId = yield select(getUserGradeId);

		if ([4, 5].includes(gradeId)) {
			subjects = data.filter((subject) => subject.type !== SUBJECT_TYPES.exam);
		} else {
			subjects = data;
		}

		yield put(goalSettingsActions.getGoalSettingsSuccess(subjects));
	} catch (e) {
		yield put(goalSettingsActions.getGoalSettingsError());
		yield put(errorHandlerActions.handleError(e, 'page-blocking'));
	}
}

export function* handleGetDurationsRequest() {
	try {
		const frequency = yield select(getFrequency);
		const query = frequency.length ? { play_times_per_week: frequency.length } : {};

		const { data } = yield call(processRequest, 'session_length_options', 'GET', query);

		yield put(goalSettingsActions.getGoalDurationsSuccess(data.session_length_options));
	} catch (e) {
		yield put(goalSettingsActions.getGoalDurationsError());
		yield put(errorHandlerActions.handleError(e, 'page-blocking'));
	}
}

export function* handleSaveGoalSettingsRequest({ payload }) {
	try {
		const { frequency, durationId, subjects } = payload;

		yield call(processRequest, 'users/user_week_goals', 'POST', {
			weekdays: frequency,
		});

		if (durationId) {
			const durationBody = { user_session_length: { session_length_option_id: durationId } };
			yield call(processRequest, 'users/user_session_lengths', 'POST', durationBody);
		}

		if (subjects?.length) {
			const body = generateGoalSettingsPayload(subjects);
			yield call(processRequest, 'user_goals', 'POST', body);
		}

		const { data: userData } = yield call(processRequest, 'get_user_data');
		yield put(sharedActions.getUserDataSuccess(userData));

		yield put(learningPathActions.clearLearningPath());
		yield put(goalSettingsActions.saveGoalSettingsSuccess());
	} catch (e) {
		yield put(goalSettingsActions.saveGoalSettingsError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleGetFrequencyRequest() {
	try {
		const { data } = yield call(processRequest, 'users/user_week_goals/current');

		yield put(goalSettingsActions.getFrequencySuccess(data?.weekdays || [], !data?.weekdays));
	} catch (e) {
		yield put(goalSettingsActions.getFrequencyError());
		yield put(errorHandlerActions.handleError(e, 'page-blocking'));
	}
}
