import { SEARCH_DISPLAY_TYPES, DEFAULT_LIMIT_FOR_SEARCH_BOOK, SCHOOL_TRACK_ID } from './Constants';
import find from 'lodash/find';

export const preparedData = (data) => {
	let isBooksPresent = false;
	let isPublisherMethods = false;
	const mappedData = [];
	for (const item of data.result_sections) {
		if (item.results.length) {
			if (item.display_type === SEARCH_DISPLAY_TYPES.BOOKS) {
				isBooksPresent = true;
				isPublisherMethods && mappedData.shift();
				item.results = [...item.results.slice(0, DEFAULT_LIMIT_FOR_SEARCH_BOOK)];
				mappedData.unshift(item);
				continue;
			}
			if (item.display_type === SEARCH_DISPLAY_TYPES.PUBLISHER_METHODS && !isBooksPresent) {
				item.results = [...item.results.slice(0, DEFAULT_LIMIT_FOR_SEARCH_BOOK)];
				isPublisherMethods = true;
				mappedData.unshift(item);
				continue;
			}
			if (item.display_type === SEARCH_DISPLAY_TYPES.TOPICS) {
				!isBooksPresent ? mappedData.unshift(item) : mappedData.push(item);
				continue;
			}

			if (item.display_type === SEARCH_DISPLAY_TYPES.PUBLISHER_METHODS && isBooksPresent)
				continue;

			mappedData.push(item);
		}
	}
	return mappedData;
};

export const getSchoolTrackItem = (available_filters, appliedFilter) => {
	return available_filters[SCHOOL_TRACK_ID].reduce((acc_, level) => {
		const filter = find(
			level.school_tracks,
			(item) => item.id.toString() === appliedFilter.toString(),
		);
		if (filter && filter.id) acc_ = filter;
		return acc_;
	}, {});
};

export const preparedQuery = (applied_filters, available_filters) => {
	return Object.entries(applied_filters).reduce((acc, [k, v]) => {
		if (k === SCHOOL_TRACK_ID) {
			const { name: schoolTrackName, id: schoolTrackId } = getSchoolTrackItem(
				available_filters,
				v,
			);

			acc = `${acc}&${k}=${schoolTrackId}&school_track_name=${schoolTrackName}`;
			return acc;
		}
		acc = `${acc}&${k}=${v}`;
		return acc;
	}, '');
};

export const prepareFilters = (applied_filters, available_filters) => {
	return Object.entries(applied_filters).reduce((acc, [k, v]) => {
		if (k === SCHOOL_TRACK_ID) {
			const { name: schoolTrackName, id: schoolTrackId } = getSchoolTrackItem(
				available_filters,
				v,
			);
			acc[k] = schoolTrackId;
			acc.school_track_name = schoolTrackName;
			return acc;
		}
		acc[k] = v;
		return acc;
	}, {});
};

export const handleSearchGroupResults = (groupResults, status, groupId) => {
	return groupResults.map((item) => {
		if (item.search_type === 'groups') {
			if (status === 'delete') {
				return {
					...item,
					results: item.results.filter((result) => result.id !== groupId),
				};
			}
			if (status === 'retract' || status === 'leave' || status === 'decline') {
				return {
					...item,
					results: item.results.map((result) => {
						if (result.id === groupId) {
							return { ...result, status: null };
						}
						return result;
					}),
				};
			}
			if (status === 'request_to_join') {
				return {
					...item,
					results: item.results.map((result) => {
						if (result.id === groupId) {
							return { ...result, status: 'requested_to_join' };
						}
						return result;
					}),
				};
			}
		}
		return item;
	});
};
