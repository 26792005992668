import { chooseProfileImageModalActionTypes } from './Constants';

export function toggleChooseProfileImageModal(data) {
	return {
		type: chooseProfileImageModalActionTypes.TOGGLE_CHOOSE_PROFILE_IMAGE_MODAL,
		payload: {
			data,
		},
	};
}

export function setAvatarColor(selectedColor) {
	return {
		type: chooseProfileImageModalActionTypes.SET_AVATAR_COLOR,
		payload: {
			selectedColor,
		},
	};
}

export function setAvatarColorSuccess() {
	return {
		type: chooseProfileImageModalActionTypes.SET_AVATAR_COLOR_SUCCESS,
	};
}

export function setAvatarColorError() {
	return {
		type: chooseProfileImageModalActionTypes.SET_AVATAR_COLOR_ERROR,
	};
}

export function getPreferredColors() {
	return {
		type: chooseProfileImageModalActionTypes.GET_PREFERRED_COLORS,
	};
}

export function getPreferredColorsSuccess(preferredColors) {
	return {
		type: chooseProfileImageModalActionTypes.GET_PREFERRED_COLORS_SUCCESS,
		payload: {
			preferredColors,
		},
	};
}

export function getPreferredColorsError() {
	return {
		type: chooseProfileImageModalActionTypes.GET_PREFERRED_COLORS_ERROR,
	};
}
