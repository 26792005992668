import keyMirror from 'keymirror';

export const subjectsActionTypes = keyMirror({
	GET_ADMIN_SUBJECTS_REQUEST: null,
	GET_ADMIN_SUBJECTS_SUCCESS: null,
	GET_ADMIN_SUBJECTS_ERROR: null,

	CHANGE_ADMIN_SUBJECT_POSITION_REQUEST: null,
	CHANGE_ADMIN_SUBJECT_POSITION_SUCCESS: null,
	CHANGE_ADMIN_SUBJECT_POSITION_ERROR: null,

	DELETE_ADMIN_SUBJECT_REQUEST: null,
	DELETE_ADMIN_SUBJECT_SUCCESS: null,
	DELETE_ADMIN_SUBJECT_ERROR: null,
});
