import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import { getUserId } from '../../../pages/profile/Selectors';
import { processRequest } from '../../../services/Api';
import { chooseProfileImageModalActionTypes } from './Constants';
import * as sharedActions from '../../../shared/Actions';
import * as chooseProfileImageModalActions from './Actions';
import * as errorHandlerActions from '../../errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			chooseProfileImageModalActionTypes.SET_AVATAR_COLOR,
			handleSetAvatarColorRequest,
		),
		yield takeLatest(
			chooseProfileImageModalActionTypes.GET_PREFERRED_COLORS,
			handleGetPreferredColorsRequest,
		),
	]);
}

export function* handleSetAvatarColorRequest(action) {
	try {
		const userId = yield select(getUserId);
		const { selectedColor } = action.payload;
		yield call(processRequest, `users/${userId}`, 'PUT', {
			user: {
				preferred_profile_color: selectedColor,
				remote_profile_image_url: null,
			},
		});

		yield put(sharedActions.getUserDataRequest());
		yield put(chooseProfileImageModalActions.setAvatarColorSuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(chooseProfileImageModalActions.setAvatarColorError());
	}
}

export function* handleGetPreferredColorsRequest() {
	try {
		const { data } = yield call(processRequest, 'users/profile_colors');

		yield put(chooseProfileImageModalActions.getPreferredColorsSuccess(data.profile_colors));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(chooseProfileImageModalActions.getPreferredColorsError());
	}
}
