/* eslint-disable array-callback-return */
import { processRequest } from '../../services/Api';
import { put, call, all, select, takeEvery } from 'redux-saga/effects';
import { historyReplace } from '../../services/History';
import { searchActionTypes, DEFAULT_LIMIT, DEFAULT_OFFSET } from './Constants';
import * as searchDetailsActions from './Actions';
import {
	getSearchTerms,
	getAppliedFilter,
	getResultSections,
	getIsChangeFilters,
} from './Selectors';
import { getUpdatedQuery } from './Services';
import { prepareFilters } from '../search/Services';
import * as errorHandlerActions from '../../components/errorHandler/Actions';

export default function* () {
	yield all([yield takeEvery(searchActionTypes.SEARCH_DETAILS_REQUEST, handleSearchRequest)]);
}

export function* handleSearchRequest({ payload }) {
	try {
		const {
			apply_default_filters = false,
			type,
			offset = DEFAULT_OFFSET,
			limit = DEFAULT_LIMIT,
		} = payload.data;
		const isChangeFilters = yield select(getIsChangeFilters);
		const search_terms = yield select(getSearchTerms);
		let appliedFilters = yield select(getAppliedFilter);
		const { country_code: countryCode } = appliedFilters || {};

		const resultSections = yield select(getResultSections);
		const query = {
			apply_default_filters: isChangeFilters ? false : apply_default_filters,
			search_terms,
			limit,
			offset,
		};

		if (type) query.type = type;
		if (countryCode) query.country_code = countryCode;
		// eslint-disable-next-line guard-for-in
		for (const prop in appliedFilters) {
			const isSchoolTrack = prop === 'school_track_id';
			query[prop] = isSchoolTrack ? appliedFilters.school_track_id : appliedFilters[prop];
		}

		const { data } = yield call(processRequest, `search`, 'GET', query);

		const dataForSave = data;
		let noNeedReplace = false;
		if (offset !== DEFAULT_OFFSET) {
			noNeedReplace = true;
			dataForSave.result_sections[0].results = [
				...resultSections[0].results,
				...data.result_sections[0].results,
			];
		}
		yield put(
			searchDetailsActions.searchSuccess({
				...dataForSave,
				noResults: !data.result_sections[0].results.length,
				allSearchData: data.result_sections,
			}),
		);

		if (
			!isChangeFilters &&
			data.result_sections[0] &&
			Object.keys(data.result_sections[0].applied_filters).length &&
			Object.keys(data.result_sections[0].available_filters).length
		) {
			appliedFilters = prepareFilters(
				data.result_sections[0].applied_filters,
				data.result_sections[0].available_filters,
			);
			yield put(searchDetailsActions.setAppliedFilters({ appliedFilters }));
		}

		const queryFilters = getUpdatedQuery(appliedFilters);
		const urlAddress = {
			pathname: `/search/${type}`,
			search: '',
		};

		const selectedCountry =
			countryCode && !appliedFilters.hasOwnProperty('country_code')
				? `&country_code=${countryCode}`
				: '';
		const queryString = `?search_terms=${search_terms.split(' ').join('+')}${selectedCountry}`;

		if (search_terms || (!noNeedReplace && search_terms)) {
			urlAddress.search = queryString;
		}

		if (!noNeedReplace && search_terms && queryFilters) {
			urlAddress.search = `${queryString}&${queryFilters}`;
		}

		if (isChangeFilters)
			urlAddress.search = `${urlAddress.search}&change_filters=${isChangeFilters}`;

		yield call(historyReplace, urlAddress.pathname + urlAddress.search);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(searchDetailsActions.searchError());
	}
}
