import { pushNotificationActionTypes } from './Constants';

const initialState = {
	loading: false,
};

export default function appPushNotifications(state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case pushNotificationActionTypes.REGISTER_PUSH_NOTIFICATION_REQUEST:
		case pushNotificationActionTypes.DEREGISTER_PUSH_NOTIFICATION_REQUEST:
			return {
				...state,
				loading: true,
			};

		case pushNotificationActionTypes.DEREGISTER_PUSH_NOTIFICATION_SUCCESS:
			localStorage.deviceTokenStatus = payload.status;
			return {
				...state,
				loading: false,
			};

		case pushNotificationActionTypes.REGISTER_PUSH_NOTIFICATION_SUCCESS:
			localStorage.deviceTokenStatus = 'registered';

			return {
				...state,
				loading: false,
			};

		case pushNotificationActionTypes.REGISTER_PUSH_NOTIFICATION_ERROR:
		case pushNotificationActionTypes.DEREGISTER_PUSH_NOTIFICATION_ERROR:
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
}
