import { dailyGoalActionTypes } from './Constants';

const initialState = {
	dailyGoalProgress: [],
	dailyGoalStickers: null,
	dailyReport: null,
	loadingReflections: false,
	loadingReport: false,
	profileStickers: null,
	reflections: [],
	userStickers: null,
	weekGoalTiles: [],
	dayGoalCount: null,
	weekGoalCount: null,
};

export default function dailyGoalReducer(state = initialState, action) {
	const { type, payload } = action;
	const {
		dailyGoalProgress,
		dailyGoalStickers,
		dailyReport,
		profileStickers,
		reflections,
		userStickers,
		weekGoalTiles,
		dayGoalCount,
		weekGoalCount,
	} = payload || {};

	switch (type) {
		case dailyGoalActionTypes.GET_DAILY_GOAL_REPORT_REQUEST:
			return {
				...state,
				loadingReport: true,
			};

		case dailyGoalActionTypes.GET_DAILY_GOAL_REPORT_SUCCESS:
			return {
				...state,
				dailyReport,
				loadingReport: false,
			};

		case dailyGoalActionTypes.GET_DAILY_GOAL_REPORT_ERROR:
			return {
				...state,
				loadingReport: false,
			};

		case dailyGoalActionTypes.GET_DAILY_GOAL_REFLECTIONS_REQUEST:
			return {
				...state,
				loadingReflections: true,
			};

		case dailyGoalActionTypes.GET_DAILY_GOAL_REFLECTIONS_SUCCESS:
			return {
				...state,
				reflections,
				loadingReflections: false,
			};

		case dailyGoalActionTypes.GET_DAILY_GOAL_REFLECTIONS_ERROR:
			return {
				...state,
				loadingReflections: false,
			};

		case dailyGoalActionTypes.GET_DAILY_GOAL_STICKERS_SUCCESS:
			return {
				...state,
				dailyGoalStickers,
			};

		case dailyGoalActionTypes.GET_USER_STICKERS_SUCCESS:
		case dailyGoalActionTypes.SEND_DAILY_GOAL_STICKER_SUCCESS:
			return {
				...state,
				userStickers,
			};

		case dailyGoalActionTypes.GET_PROFILE_STICKERS_SUCCESS:
			return {
				...state,
				profileStickers,
			};

		case dailyGoalActionTypes.GET_DAILY_GOAL_SUMMARY_SUCCESS:
			return {
				...state,
				dailyGoalProgress,
				dayGoalCount,
				weekGoalCount,
			};

		case dailyGoalActionTypes.GET_WEEK_GOAL_TILES_SUCCESS:
			return {
				...state,
				weekGoalTiles,
			};

		default:
			return state;
	}
}
