import { packagesActionTypes } from './Constants';

const initialState = {
	loading: false,
	choosePackageLoading: false,
	cancelMembershipModal: {
		open: false,
		loading: false,
	},
	reactivateSubscriptionModal: {
		open: false,
		loading: false,
		image: null,
	},
	surveyLink: null,
};

export default function packagesReducer(state = initialState, action) {
	const { type, payload } = action;
	const { choosePackagesData, status, surveyLink, data } = payload || {};

	switch (type) {
		case packagesActionTypes.GET_CHOOSE_PACKAGE:
			return {
				...state,
				loading: true,
			};

		case packagesActionTypes.GET_CHOOSE_PACKAGE_SUCCESS:
			const { title, description, packages, discount_code } = choosePackagesData || {};
			return {
				...state,
				choosePackages: packages,
				title,
				description,
				loading: false,
				discountCode: discount_code,
			};

		case packagesActionTypes.TOGGLE_CANCEL_MEMBERSHIP_MODAL:
			return {
				...state,
				cancelMembershipModal: {
					open: status,
					loading: false,
				},
			};

		case packagesActionTypes.TOGGLE_REACTIVATE_SUBSCRIPTION_MODAL:
			return {
				...state,
				reactivateSubscriptionModal: {
					...state.reactivateSubscriptionModal,
					...data,
				},
			};

		case packagesActionTypes.CANCEL_SUBSCRIPTION_REQUEST:
			return {
				...state,
				cancelMembershipModal: {
					open: true,
					loading: true,
				},
			};

		case packagesActionTypes.CANCEL_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				cancelMembershipModal: {
					open: false,
					loading: false,
				},
				surveyLink,
			};

		case packagesActionTypes.CANCEL_SUBSCRIPTION_ERROR:
			return {
				...state,
				cancelMembershipModal: {
					open: true,
					loading: false,
				},
			};

		case packagesActionTypes.REACTIVATE_SUBSCRIPTION_REQUEST:
			return {
				...state,
				reactivateSubscriptionModal: {
					...state.reactivateSubscriptionModal,
					open: true,
					loading: true,
				},
			};

		case packagesActionTypes.REACTIVATE_SUBSCRIPTION_SUCCESS:
		case packagesActionTypes.REACTIVATE_SUBSCRIPTION_ERROR:
			return {
				...state,
				reactivateSubscriptionModal: {
					open: false,
					loading: false,
					image: null,
				},
			};

		case packagesActionTypes.CHOOSE_FREE_PACKAGE_REQUEST:
			return {
				...state,
				choosePackageLoading: true,
			};

		case packagesActionTypes.CHOOSE_FREE_PACKAGE_ERROR:
			return {
				...state,
				choosePackageLoading: false,
			};

		case packagesActionTypes.CLEAN_PACKAGES_STATE:
			return initialState;

		default:
			return state;
	}
}
