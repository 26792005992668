import keyMirror from 'keymirror';
import answersEmpty from '../../images/illustrations/supporting/qnaSingle.svg';
import extraOptions from '../../images/illustrations/icons/extraOptions.svg';
import videoTutoring from '../../images/illustrations/supporting/videoTutoring.svg';
import { isAuthenticated } from '../../services/AccessToken';
import { BRAND_NAME } from '../../services/Constants';

export const paymentDetailsActionTypes = keyMirror({
	GET_PAYMENT_DETAILS_REQUEST: null,
	GET_PAYMENT_DETAILS_SUCCESS: null,
	GET_PAYMENT_DETAILS_ERROR: null,
});

export const getPageType = (path) => {
	switch (path) {
		case '/subscription-payment-success/:paymentIntentId':
			return 'isSuccessPayment';
		case '/subscription-payment-success-parent/:paymentIntentId':
			return 'isSuccessParentPay';
		case '/success-package-activation':
			return 'isSuccessActivation';
		case '/already-fulfilled':
			return 'isSuccessFulfilledBefore';
		case '/successfully-restored-payment':
			return 'isSuccessFailedInvoice';
		case '/subscription-payment-success-public-shop/:paymentIntentId':
			return 'isSuccessPublicShop';
		case '/success-email-verification':
			return 'isSuccessEmailValidation';
		default:
			return 'isSuccessPaymentMain';
	}
};

export const getDescription = (pageType, t) => {
	switch (pageType) {
		case 'isSuccessParentPay':
			return t('success_payment.description_public', { brandName: BRAND_NAME });
		case 'isSuccessPublicShop':
			return t('success_payment.description_public_shop', { brandName: BRAND_NAME });
		default:
			return t('success_payment.summary.desc');
	}
};

export const getTitleEmptyState = (pageType, t) => {
	switch (pageType) {
		case 'isSuccessActivation':
			return t('success_payment.title_activation');
		case 'isSuccessFulfilledBefore':
			return t('success_payment.title_fulfilled_before');
		case 'isSuccessFailedInvoice':
			return t('success_payment.title_failed_invoice');
		case 'isSuccessEmailValidation':
			return t('lists.teacher_validation.success.title');
		case 'isSuccessPayment':
		case 'isSuccessParentPay':
		case 'isSuccessPublicShop':
			return t('success_payment.title_question');
		default:
			return t('success_payment.title');
	}
};

export const getDescriptionEmptyState = (pageType, t) => {
	switch (pageType) {
		case 'isSuccessActivation':
			return t('success_payment.description_activation', { brandName: BRAND_NAME });
		case 'isSuccessFulfilledBefore':
			return t('success_payment.description_fulfilled_before', {
				brandName: BRAND_NAME,
			});
		case 'isSuccessFailedInvoice':
			return t('success_payment.description_failed_invoice', { brandName: BRAND_NAME });
		case 'isSuccessEmailValidation':
			return t('lists.teacher_validation.success.description');
		case 'isSuccessPayment':
		case 'isSuccessParentPay':
		case 'isSuccessPublicShop':
			return t('success_payment.description_question', { brandName: BRAND_NAME });
		default:
			return t('success_payment.description', { brandName: BRAND_NAME });
	}
};

export const getImageEmptyState = (pageType) => {
	switch (pageType) {
		case 'isSuccessFulfilledBefore':
			return videoTutoring;
		case 'isSuccessPayment':
		case 'isSuccessParentPay':
		case 'isSuccessPublicShop':
			return answersEmpty;
		default:
			return extraOptions;
	}
};

export const btnTitle = (pageType, t) => {
	switch (pageType) {
		case 'isSuccessFulfilledBefore' && !isAuthenticated():
			return t('success_payment.btn_to_login');
		case 'isSuccessFailedInvoice' && !isAuthenticated():
			return t('success_payment.btn_to_login');
		case 'isSuccessPayment':
		case 'isSuccessParentPay':
		case 'isSuccessPublicShop':
			return t('success_payment.btn_question');

		default:
			return t('success_payment.btn');
	}
};
