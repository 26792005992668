import { dailyReportActionTypes } from './Constants';

const initialState = {
	dailyReport: null,
};

export default function dailyReportReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data } = payload || {};

	switch (type) {
		case dailyReportActionTypes.GET_DAILY_REPORT_SUCCESS:
			return {
				...state,
				dailyReport: data,
			};

		default:
			return state;
	}
}
