import { put, call, takeLatest, all } from 'redux-saga/effects';
import { manageSubcategoryActionTypes } from './Constants';
import { processRequest } from '../../../../services/Api';
import * as notificationActions from '../../../../components/notification/Actions';
import * as errorHandlerActions from '../../../../components/errorHandler/Actions';
import * as manageSubcategoryActions from './Actions';
import i18n from 'i18next';

export default function* () {
	yield all([
		yield takeLatest(
			manageSubcategoryActionTypes.MANAGE_ADMIN_SUBCATEGORY_REQUEST,
			handleManageSubcategoryRequest,
		),
		yield takeLatest(
			manageSubcategoryActionTypes.GET_ADMIN_SUBCATEGORY_DATA_REQUEST,
			handleGetSubcategoryDataRequest,
		),
	]);
}

export function* handleManageSubcategoryRequest(action) {
	try {
		const { formData, subcategoryId, categoryId, callback } = action.payload;

		if (subcategoryId) {
			yield call(
				processRequest,
				`admin/topic_subcategories/${subcategoryId}`,
				'PUT',
				formData,
			);
		} else {
			yield call(
				processRequest,
				`admin/topic_categories/${categoryId}/topic_subcategories`,
				'POST',
				{
					topic_subcategory: formData,
				},
			);
		}

		yield put(manageSubcategoryActions.manageSubcategorySuccess());
		callback && callback();

		yield put(
			notificationActions.createNotification(
				subcategoryId
					? i18n.t('general.saved_changes')
					: i18n.t('modals.manage_subcategory_modal.add_notification'),
				null,
				false,
				3000,
			),
		);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(manageSubcategoryActions.manageSubcategoryError());
	}
}

export function* handleGetSubcategoryDataRequest({ payload }) {
	try {
		const { subcategoryId } = payload || {};
		const { data } = yield call(processRequest, `admin/topic_subcategories/${subcategoryId}`);
		const { name = '', topic_category, subject } = data.topic_subcategory;

		yield put(
			manageSubcategoryActions.getSubcategoryDataSuccess({
				id: subcategoryId,
				name,
				subject,
				category: topic_category,
			}),
		);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(manageSubcategoryActions.getSubcategoryDataError());
	}
}
