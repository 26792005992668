import { errorHandlerActionTypes } from './Constants';

export function handleError(error, typeError = 'page-blocking', customNotification = null) {
	return {
		type: errorHandlerActionTypes.HANDLE_ERROR,
		payload: {
			error,
			typeError,
			customNotification,
		},
	};
}

export function clearError() {
	return {
		type: errorHandlerActionTypes.CLEAR_ERROR,
	};
}
