import { processRequest } from '../../../services/Api';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';
import { historyReplace } from '../../../services/History';
import { getCountryCode } from '../../services/Constants';
import { usersOverviewActionTypes } from './Constants';
import * as usersOverviewActions from './Actions';
import * as errorHandlerActions from '../../../components/errorHandler/Actions';
import * as notificationActions from '../../../components/notification/Actions';
import { usersData } from './Selectors';
import * as profileActions from '../../../pages/profile/Actions';

export default function* () {
	yield all([
		yield takeLatest(usersOverviewActionTypes.GET_USERS_REQUEST, handleGetUsersRequest),
		yield takeLatest(usersOverviewActionTypes.GET_USER_INFO_REQUEST, handleGetUserInfoRequest),
		yield takeLatest(
			usersOverviewActionTypes.GET_USER_CHARGEBEE_INFO_REQUEST,
			handleGetUserChargebeeInfoRequest,
		),
		yield takeLatest(
			usersOverviewActionTypes.USER_PASSWORD_RESET_REQUEST,
			handleUserPasswordResetRequest,
		),
		yield takeLatest(
			usersOverviewActionTypes.CHANGE_USER_DETAILS_REQUEST,
			handleChangeUserDetailsRequest,
		),
		yield takeLatest(
			usersOverviewActionTypes.CHANGE_USER_SCHOOL_DETAILS_REQUEST,
			handleChangeUserSchoolDetails,
		),
		yield takeLatest(
			usersOverviewActionTypes.ADMIN_DELETE_USER_REQUEST,
			handleDeleteUserRequest,
		),
		yield takeLatest(
			usersOverviewActionTypes.GET_USER_HISTORY_REQUEST,
			handleGetUserHistoryRequest,
		),
		yield takeLatest(
			usersOverviewActionTypes.UPDATE_PERSONAL_DATA_REQUEST,
			handleUpdatePersonalDataRequest,
		),
	]);
}

export function* handleGetUsersRequest({ payload }) {
	try {
		const { data: queries } = payload || {};
		const requestPayload = { ...queries, ...getCountryCode() };

		const { data } = yield call(processRequest, 'admin/users', 'GET', requestPayload);

		const formattedData = { ...data };

		if (queries && queries.offset) {
			const users = yield select(usersData);
			formattedData.results = [...users.results, ...formattedData.results];
		}

		yield put(usersOverviewActions.getUsersSuccess(formattedData));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(usersOverviewActions.getUsersError());
	}
}

export function* handleGetUserInfoRequest({ payload }) {
	try {
		const { userId } = payload;

		const { data } = yield call(
			processRequest,
			`admin/users/${userId}`,
			'GET',
			getCountryCode(),
		);

		yield put(usersOverviewActions.getUserInfoSuccess(data.user));
		yield put(usersOverviewActions.changeUserDetailsSuccess());
		yield put(usersOverviewActions.changeUserSchoolDetailsSuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(usersOverviewActions.getUserInfoError());
	}
}

export function* handleGetUserChargebeeInfoRequest({ payload }) {
	try {
		const { userId } = payload;
		const { data } = yield call(processRequest, `admin/users/${userId}/chargebee_details`);

		yield put(usersOverviewActions.getUserChargebeeInfoSuccess(data.chargebee_details));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(usersOverviewActions.getUserChargebeeInfoError());
	}
}

export function* handleUserPasswordResetRequest({ payload }) {
	try {
		const { email } = payload;
		const { data } = yield call(processRequest, `password/request_reset`, 'POST', { email });

		yield put(notificationActions.createNotification(data.info, null, false, 3000));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleDeleteUserRequest({ payload }) {
	try {
		const { userId, callback } = payload;
		yield call(processRequest, `admin/users/${userId}`, 'DELETE');

		yield put(
			notificationActions.createNotification(
				i18n.t('admin_user_details.delete_user_notification'),
				null,
				false,
				3000,
			),
		);

		if (callback) {
			callback(userId, { deleted: true });
			return;
		}
		yield call(historyReplace, '/users');
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleChangeUserDetailsRequest({ payload }) {
	try {
		const { userId, data: submitData } = payload;
		yield call(processRequest, `admin/users/${userId}`, 'PATCH', {
			user: submitData,
		});
		yield put(usersOverviewActions.getUserInfo(userId, true));
		yield put(
			notificationActions.createNotification(
				i18n.t('general.saved_changes'),
				null,
				false,
				3000,
			),
		);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(usersOverviewActions.changeUserDetailsError());
	}
}

export function* handleChangeUserSchoolDetails({ payload }) {
	try {
		const { userId, data } = payload;
		yield call(processRequest, `admin/users/${userId}`, 'PATCH', {
			user: data,
		});
		yield put(usersOverviewActions.getUserInfo(userId, true));
		yield put(
			notificationActions.createNotification(
				i18n.t('general.saved_changes'),
				null,
				false,
				3000,
			),
		);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'content-blocking'));
		yield put(usersOverviewActions.changeUserSchoolDetailsError());
	}
}

export function* handleGetUserHistoryRequest({ payload }) {
	try {
		const { userId } = payload;
		const { data } = yield call(processRequest, `admin/users/${userId}/qna_banned_users`);

		yield put(usersOverviewActions.getUserHistorySuccess(data.qna_bans));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'content-blocking'));
		yield put(usersOverviewActions.getUserHistoryError());
	}
}

export function* handleUpdatePersonalDataRequest(action) {
	try {
		const { user_id, child_name, grade_id } = action.payload;
		yield call(processRequest, `admin/users/${user_id}`, 'PUT', {
			user: {
				child_name,
				grade_id,
			},
		});

		yield put(usersOverviewActions.updatePersonalDataSuccess());

		yield put(
			notificationActions.createNotification(
				i18n.t('general.saved_changes'),
				null,
				false,
				3000,
			),
		);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(profileActions.changePersonalDataError());
	}
}
