import { adminPlaylistActionTypes } from './Constants';

const initialState = {
	playlist: {},
	loadingPlaylist: true,
	loadingQuestion: false,
	loadingUpdate: true,
	selectedQuestion: {},
	loadingPublish: false,
	updatedAt: null,
	blockUpdate: false,
	instructionAudioModal: {
		open: false,
		messageId: null,
	},
	loadingGenerateAudio: false,
};

export default function adminPlaylistReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data, blockUpdate, showLoading } = payload || {};

	switch (type) {
		case adminPlaylistActionTypes.GET_PLAYLIST_REQUEST:
			return {
				...state,
				loadingPlaylist: showLoading,
			};
		case adminPlaylistActionTypes.GET_PLAYLIST_SUCCESS:
			return {
				...state,
				loadingPlaylist: false,
				playlist: data,
				loadingQuestion: !data.questions.length ? false : state.loadingQuestion,
				loadingUpdate: false,
			};
		case adminPlaylistActionTypes.CREATE_QUESTION_SUCCESS:
			return {
				...state,
				playlist: data,
				loadingUpdate: false,
			};
		case adminPlaylistActionTypes.GET_PLAYLIST_ERROR:
			return {
				...state,
				loadingPlaylist: false,
				loadingUpdate: false,
			};
		case adminPlaylistActionTypes.SET_QUESTIONS_LIST:
			return {
				...state,
				playlist: { ...state.playlist, questions: data },
			};
		case adminPlaylistActionTypes.MANAGE_PLAYLIST_REQUEST:
		case adminPlaylistActionTypes.CREATE_QUESTION_REQUEST:
		case adminPlaylistActionTypes.UPDATE_QUESTION_REQUEST:
		case adminPlaylistActionTypes.MANAGE_QUESTION_REQUEST:
		case adminPlaylistActionTypes.CREATE_QUESTION_INSTRUCTION_REQUEST:
		case adminPlaylistActionTypes.UPDATE_QUESTION_INSTRUCTION_REQUEST:
		case adminPlaylistActionTypes.DELETE_QUESTION_INSTRUCTION_REQUEST:
		case adminPlaylistActionTypes.DELETE_INSTRUCTION_AUDIO_REQUEST:
		case adminPlaylistActionTypes.REVERT_TEXT_REQUEST:
			return { ...state, loadingUpdate: true };
		case adminPlaylistActionTypes.MANAGE_PLAYLIST_SUCCESS:
		case adminPlaylistActionTypes.MANAGE_PLAYLIST_ERROR:
		case adminPlaylistActionTypes.CREATE_QUESTION_ERROR:
		case adminPlaylistActionTypes.UPDATE_QUESTION_ERROR:
		case adminPlaylistActionTypes.MANAGE_QUESTION_SUCCESS:
		case adminPlaylistActionTypes.MANAGE_QUESTION_ERROR:
		case adminPlaylistActionTypes.CREATE_QUESTION_INSTRUCTION_SUCCESS:
		case adminPlaylistActionTypes.CREATE_QUESTION_INSTRUCTION_ERROR:
		case adminPlaylistActionTypes.UPDATE_QUESTION_INSTRUCTION_SUCCESS:
		case adminPlaylistActionTypes.UPDATE_QUESTION_INSTRUCTION_ERROR:
		case adminPlaylistActionTypes.DELETE_QUESTION_INSTRUCTION_SUCCESS:
		case adminPlaylistActionTypes.DELETE_QUESTION_INSTRUCTION_ERROR:
		case adminPlaylistActionTypes.DELETE_INSTRUCTION_AUDIO_SUCCESS:
		case adminPlaylistActionTypes.DELETE_INSTRUCTION_AUDIO_ERROR:
		case adminPlaylistActionTypes.REVERT_TEXT_SUCCESS:
		case adminPlaylistActionTypes.REVERT_TEXT_ERROR:
			return { ...state, loadingUpdate: false };
		case adminPlaylistActionTypes.GET_QUESTION_REQUEST:
			return { ...state, loadingQuestion: true, blockUpdate: !!blockUpdate };
		case adminPlaylistActionTypes.GET_QUESTION_SUCCESS:
		case adminPlaylistActionTypes.GET_QUESTION_ERROR:
			return { ...state, loadingQuestion: false, blockUpdate: false };
		case adminPlaylistActionTypes.UPDATE_QUESTION_SUCCESS:
			return { ...state, loadingUpdate: false, updatedAt: data };
		case adminPlaylistActionTypes.PUBLISH_PLAYLIST_REQUEST:
			return { ...state, loadingPublish: true };
		case adminPlaylistActionTypes.PUBLISH_PLAYLIST_SUCCESS:
		case adminPlaylistActionTypes.PUBLISH_PLAYLIST_ERROR:
			return { ...state, loadingPublish: false };
		case adminPlaylistActionTypes.SELECT_PLAYLIST_QUESTION:
			return {
				...state,
				selectedQuestion: data,
			};
		case adminPlaylistActionTypes.SET_UPDATE_TIME:
			return {
				...state,
				updatedAt: data,
			};
		case adminPlaylistActionTypes.TOGGLE_INSTRUCTION_AUDIO_MODAL:
			return {
				...state,
				instructionAudioModal: data,
			};
		case adminPlaylistActionTypes.GENERATE_INSTRUCTION_AUDIO_REQUEST:
			return {
				...state,
				loadingGenerateAudio: true,
			};
		case adminPlaylistActionTypes.GENERATE_INSTRUCTION_AUDIO_SUCCESS:
		case adminPlaylistActionTypes.GENERATE_INSTRUCTION_AUDIO_ERROR:
			return {
				...state,
				loadingGenerateAudio: false,
			};
		case adminPlaylistActionTypes.CLEAR_PLAYLIST_STATE:
			return initialState;
		default:
			return state;
	}
}
