import { searchActionTypes } from './Constants';

export function searchRequest(data) {
	return {
		type: searchActionTypes.SEARCH_REQUEST,
		payload: {
			data,
		},
	};
}

export function searchSuccess(data) {
	return {
		type: searchActionTypes.SEARCH_SUCCESS,
		payload: {
			data,
		},
	};
}

export function searchError() {
	return {
		type: searchActionTypes.SEARCH_ERROR,
	};
}

export function setSearchValue(data) {
	return {
		type: searchActionTypes.SET_SEARCH_VALUE,
		payload: { data },
	};
}

export function clearResultSection() {
	return {
		type: searchActionTypes.CLEAR_RESULT_SECTIONS,
		payload: {},
	};
}

export function setNoResults() {
	return {
		type: searchActionTypes.SET_NO_RESULTS,
		payload: {},
	};
}

export function cleanSearchState() {
	return {
		type: searchActionTypes.CLEAN_SEARCH_STATE,
		payload: {},
	};
}

export function updateGroupResults(groupId, status) {
	return {
		type: searchActionTypes.UPDATE_GROUP_RESULTS,
		payload: { groupId, status },
	};
}
