import keyMirror from 'keymirror';

export const adminInstructionActionTypes = keyMirror({
	GET_TOPIC_INSTRUCTION_REQUEST: null,
	GET_TOPIC_INSTRUCTION_SUCCESS: null,
	GET_TOPIC_INSTRUCTION_ERROR: null,

	MANAGE_TOPIC_INSTRUCTION_REQUEST: null,
	MANAGE_TOPIC_INSTRUCTION_SUCCESS: null,
	MANAGE_TOPIC_INSTRUCTION_ERROR: null,

	PREVIEW_TOPIC_INSTRUCTION_REQUEST: null,
	PREVIEW_TOPIC_INSTRUCTION_SUCCESS: null,
	PREVIEW_TOPIC_INSTRUCTION_ERROR: null,

	PUBLISH_TOPIC_INSTRUCTION_REQUEST: null,
	PUBLISH_TOPIC_INSTRUCTION_SUCCESS: null,
	PUBLISH_TOPIC_INSTRUCTION_ERROR: null,

	RESET_TOPIC_INSTRUCTION_REQUEST: null,
	RESET_TOPIC_INSTRUCTION_SUCCESS: null,
	RESET_TOPIC_INSTRUCTION_ERROR: null,

	DELETE_TOPIC_INSTRUCTION_REQUEST: null,
	DELETE_TOPIC_INSTRUCTION_SUCCESS: null,
	DELETE_TOPIC_INSTRUCTION_ERROR: null,

	SET_ADMIN_UPDATING_PROCESS: null,
	SET_TOPIC_INSTRUCTION: null,
	SET_FIRST_LOAD: null,
	CLEAR_INSTRUCTION_STATE: null,
});

export const UPDATE_DELAY = 1000;
