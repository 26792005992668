import { manageSubjectActionTypes } from './Constants';

export function toggleManageSubjectModal(data) {
	return {
		type: manageSubjectActionTypes.TOGGLE_MANAGE_SUBJECT_MODAL,
		payload: {
			data,
		},
	};
}

export function getSubjectDataRequest(subjectId) {
	return {
		type: manageSubjectActionTypes.GET_SUBJECT_DATA_REQUEST,
		payload: { subjectId },
	};
}

export function getSubjectDataSuccess(data) {
	return {
		type: manageSubjectActionTypes.GET_SUBJECT_DATA_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getSubjectDataError(error) {
	return {
		type: manageSubjectActionTypes.GET_SUBJECT_DATA_ERROR,
		payload: {
			error,
		},
	};
}

export function manageSubjectRequest({ formData, subjectId, callback }) {
	return {
		type: manageSubjectActionTypes.MANAGE_SUBJECT_REQUEST,
		payload: {
			formData,
			subjectId,
			callback,
		},
	};
}

export function manageSubjectSuccess() {
	return {
		type: manageSubjectActionTypes.MANAGE_SUBJECT_SUCCESS,
	};
}

export function manageSubjectError(error) {
	return {
		type: manageSubjectActionTypes.MANAGE_SUBJECT_ERROR,
		payload: {
			error,
		},
	};
}

export function cleanManageSubjectState() {
	return {
		type: manageSubjectActionTypes.CLEAN_MANAGE_SUBJECT_STATE,
	};
}
