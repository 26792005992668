import { playButtonActionTypes } from './Constants';

export function getSong(id, word, lang, shouldHighlight = true) {
	return {
		type: playButtonActionTypes.GET_SONG_REQUEST,
		payload: {
			id,
			word,
			lang,
			shouldHighlight,
		},
	};
}

export function getSongSuccess(id, song) {
	return {
		type: playButtonActionTypes.GET_SONG_SUCCESS,
		payload: {
			id,
			song,
		},
	};
}

export function removeSong() {
	return {
		type: playButtonActionTypes.REMOVE_SONG,
		payload: {},
	};
}

export function pauseSong(paused) {
	return {
		type: playButtonActionTypes.PAUSE_SONG,
		payload: { paused },
	};
}

export function setIsAudioPlaying(isAudioPlaying, isProcessing = false) {
	return {
		type: playButtonActionTypes.IS_AUDIO_PLAYING,
		payload: { isAudioPlaying, isProcessing },
	};
}

export function setSongId(id) {
	return {
		type: playButtonActionTypes.SET_SONG_ID,
		payload: { id },
	};
}
