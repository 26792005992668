import keyMirror from 'keymirror';

export const subjectDetailsActionTypes = keyMirror({
	GET_ADMIN_SUBJECT_DETAILS_REQUEST: null,
	GET_ADMIN_SUBJECT_DETAILS_SUCCESS: null,
	GET_ADMIN_SUBJECT_DETAILS_ERROR: null,
	CLEAN_ADMIN_SUBJECT_DETAILS_STATE: null,

	GET_ADMIN_SUBJECT_CATEGORIES_REQUEST: null,
	GET_ADMIN_SUBJECT_CATEGORIES_SUCCESS: null,
	GET_ADMIN_SUBJECT_CATEGORIES_ERROR: null,
	CHANGE_ADMIN_SUBJECT_CATEGORIES_FILTER: null,

	CHANGE_ADMIN_CATEGORY_POSITION_REQUEST: null,
	CHANGE_ADMIN_CATEGORY_POSITION_SUCCESS: null,
	CHANGE_ADMIN_CATEGORY_POSITION_ERROR: null,
	DELETE_ADMIN_CATEGORY_REQUEST: null,
	DELETE_ADMIN_CATEGORY_ERROR: null,
	DELETE_ADMIN_CATEGORY_SUCCESS: null,

	SET_ADMIN_CATEGORY_OPEN_STATE: null,
	SET_ADMIN_ALL_CATEGORIES_OPEN_STATE: null,

	GET_ADMIN_SUBCATEGORIES_REQUEST: null,
	GET_ADMIN_SUBCATEGORIES_SUCCESS: null,
	GET_ADMIN_SUBCATEGORIES_ERROR: null,

	CHANGE_ADMIN_SUBCATEGORY_POSITION_REQUEST: null,
	CHANGE_ADMIN_SUBCATEGORY_POSITION_SUCCESS: null,
	CHANGE_ADMIN_SUBCATEGORY_POSITION_ERROR: null,

	DELETE_ADMIN_SUBCATEGORY_REQUEST: null,
	DELETE_ADMIN_SUBCATEGORY_SUCCESS: null,
	DELETE_ADMIN_SUBCATEGORY_ERROR: null,
});

export const mergeSubjectCategories = (newCategories, oldCategories) => {
	return newCategories.map((category) => {
		const oldCategory = oldCategories.find((item) => item.id === category.id);
		return {
			...category,
			is_open: oldCategory?.is_open ?? false,
			subcategories: oldCategory?.subcategories,
		};
	});
};
