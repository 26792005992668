import { userRoleModalActionTypes } from './Constants';

export function toggleUserRoleModal(userId, callback) {
	return {
		type: userRoleModalActionTypes.TOGGLE_USER_ROLE_MODAL,
		payload: {
			userId,
			callback,
		},
	};
}

export function getUserRoles(userId) {
	return {
		type: userRoleModalActionTypes.GET_USER_ROLES_REQUEST,
		payload: {
			userId,
		},
	};
}

export function getUserRolesSuccess(data) {
	return {
		type: userRoleModalActionTypes.GET_USER_ROLES_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getUserRolesError() {
	return {
		type: userRoleModalActionTypes.GET_USER_ROLES_ERROR,
	};
}

export function setUserRoles(userId, data) {
	return {
		type: userRoleModalActionTypes.SET_USER_ROLES_REQUEST,
		payload: {
			userId,
			data,
		},
	};
}

export function setUserRolesSuccess() {
	return {
		type: userRoleModalActionTypes.SET_USER_ROLES_SUCCESS,
	};
}

export function setUserRolesError() {
	return {
		type: userRoleModalActionTypes.SET_USER_ROLES_ERROR,
	};
}
