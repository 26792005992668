/* eslint-disable import/no-cycle */
import { combineReducers } from 'redux';
import rootReducer from './rootReducer';
import rootAdminReducer from './wrts-admin/rootReducer';

const createRootReducer = (history) =>
	combineReducers({
		...rootReducer(history),
		...rootAdminReducer(history),
	});

export default createRootReducer;
