export const getCountryId = ({ signUpReducer: { educationDetails } }) =>
	educationDetails.country.id;
export const getEducationDetail = ({ signUpReducer: { educationDetails } }) => educationDetails;
export const getUserId = ({
	sharedReducer: { userData },
	signUpReducer: { user_account },
	signInReducer: { userId },
}) => userData.id || (user_account && user_account.user_id) || userId;

export const getUserIdAfterSocialLogin = ({ signInReducer: { userId } }) => userId;
export const getIsTeacher = ({
	signUpReducer: { isTeacher, user_account },
	sharedReducer: { userData },
}) => userData.is_teacher || isTeacher || (user_account && user_account.is_teacher);
export const getToken = ({ signInReducer: { token } }) => token;
export const getLastPageSlug = ({ signUpReducer: { lastPageSlug } }) => lastPageSlug;
export const getCountries = ({ signUpReducer: { countries } }) => countries;
export const getUserData = ({ sharedReducer: { userData } }) => userData;
