/* jshint esversion: 9 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { IS_INTERNATIONAL_TYPE } from '../../services/Constants';
import nlLocale from './nlLocale.json';

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			nl: {
				translation: {
					...nlLocale,
				},
			},
		},
		whiteList: ['nl'],
		lng: IS_INTERNATIONAL_TYPE ? localStorage.getItem('selectedLanguage') : 'nl',
		fallbackLng: 'nl',
		interpolation: {
			escapeValue: false,
		},
		returnEmptyString: false,
	});

export default i18n;
