import { progressActionTypes } from './Constants';

export function getProgressOverviewRequest() {
	return {
		type: progressActionTypes.GET_PROGRESS_OVERVIEW_REQUEST,
	};
}

export function getProgressOverviewSuccess(activity, subjects) {
	return {
		type: progressActionTypes.GET_PROGRESS_OVERVIEW_SUCCESS,
		payload: {
			activity,
			subjects,
		},
	};
}

export function getProgressOverviewError(error) {
	return {
		type: progressActionTypes.GET_PROGRESS_OVERVIEW_ERROR,
		error: true,
		payload: {
			error,
		},
	};
}

export function getProgressDetailsRequest(subcategoryId) {
	return {
		type: progressActionTypes.GET_PROGRESS_DETAILS_REQUEST,
		payload: {
			subcategoryId,
		},
	};
}

export function getProgressDetailsSuccess(progressDetails) {
	return {
		type: progressActionTypes.GET_PROGRESS_DETAILS_SUCCESS,
		payload: {
			progressDetails,
		},
	};
}

export function getProgressDetailsError(error) {
	return {
		type: progressActionTypes.GET_PROGRESS_DETAILS_ERROR,
		error: true,
		payload: {
			error,
		},
	};
}

export function toggleInfoModal(openInfoModal) {
	return {
		type: progressActionTypes.TOGGLE_INFO_MODAL,
		payload: {
			openInfoModal,
		},
	};
}

export function clearProgressDetails() {
	return {
		type: progressActionTypes.CLEAR_PROGRESS_DETAILS,
	};
}
