import { errorBoundaryActionTypes } from './Constants';

const initialState = {
	error: '',
};

export default function errorBoundaryReducer(state = initialState, action) {
	const { type, payload } = action;
	const { error } = payload || {};

	switch (type) {
		case errorBoundaryActionTypes.SET_ERROR:
			return {
				...state,
				error,
			};

		default:
			return state;
	}
}
