import keyMirror from 'keymirror';
import React from 'react';

export const couponReaderActionTypes = keyMirror({
	SET_COUPON_REQUEST: null,
	SET_COUPON_SUCCESS: null,
	SET_COUPON_ERROR: null,

	VALIDATE_PUBLIC_DISCOUNT_CODE_REQUEST: null,
	VALIDATE_PUBLIC_DISCOUNT_CODE_SUCCESS: null,
	VALIDATE_PUBLIC_DISCOUNT_CODE_ERROR: null,
});

export const couponMessageSuccess = (couponName, t) => (
	<div>
		{t('coupon_render.success_notification_prefix')}
		<span className="green">{` ${couponName} `}</span>
		{t('coupon_render.success_notification_suffix')}
	</div>
);
