import keyMirror from 'keymirror';

export const manageTopicActionTypes = keyMirror({
	TOGGLE_MANAGE_TOPIC_MODAL: null,

	GET_ADMIN_TOPIC_DATA_REQUEST: null,
	GET_ADMIN_TOPIC_DATA_SUCCESS: null,
	GET_ADMIN_TOPIC_DATA_ERROR: null,

	MANAGE_ADMIN_TOPIC_REQUEST: null,
	MANAGE_ADMIN_TOPIC_SUCCESS: null,
	MANAGE_ADMIN_TOPIC_ERROR: null,

	CLEAN_MANAGE_TOPIC_STATE: null,
});
