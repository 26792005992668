import { manageSubcategoryActionTypes } from './Constants';

const initialState = {
	open: false,
	loading: false,
	subject: {},
	category: {},
	callback: null,
	subcategoryId: null,
	subcategoryData: null,
};

export default function manageSubcategoryReducer(state = initialState, action) {
	const { type, payload } = action;
	const { subcategoryId, subcategoryData, open, category, subject } = payload || {};

	switch (type) {
		case manageSubcategoryActionTypes.TOGGLE_ADMIN_MANAGE_SUBCATEGORY_MODAL:
			return {
				...state,
				open,
				category,
				subject,
				subcategoryId,
			};
		case manageSubcategoryActionTypes.MANAGE_ADMIN_SUBCATEGORY_REQUEST:
		case manageSubcategoryActionTypes.GET_ADMIN_SUBCATEGORY_DATA_REQUEST:
			return {
				...state,
				loading: true,
			};
		case manageSubcategoryActionTypes.MANAGE_ADMIN_SUBCATEGORY_ERROR:
		case manageSubcategoryActionTypes.GET_ADMIN_SUBCATEGORY_DATA_ERROR:
			return {
				...state,
				loading: false,
			};
		case manageSubcategoryActionTypes.GET_ADMIN_SUBCATEGORY_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				subcategoryData,
			};
		case manageSubcategoryActionTypes.MANAGE_ADMIN_SUBCATEGORY_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case manageSubcategoryActionTypes.CLEAN_ADMIN_MANAGE_SUBCATEGORY_STATE:
			return initialState;
		default:
			return state;
	}
}
