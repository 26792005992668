import { manageSubjectActionTypes } from './Constants';

const initialState = {
	open: false,
	subjectId: null,
	subjectData: null,
	callback: null,
	loading: false,
	submitLoading: false,
};

export default function manageSubjectReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data } = payload || {};

	switch (type) {
		case manageSubjectActionTypes.TOGGLE_MANAGE_SUBJECT_MODAL:
			return {
				...state,
				...data,
			};
		case manageSubjectActionTypes.GET_SUBJECT_DATA_SUCCESS:
			return {
				...state,
				subjectData: data,
				loading: false,
			};
		case manageSubjectActionTypes.GET_SUBJECT_DATA_ERROR:
			return {
				...state,
				loading: false,
			};
		case manageSubjectActionTypes.MANAGE_SUBJECT_REQUEST:
			return {
				...state,
				submitLoading: true,
			};
		case manageSubjectActionTypes.MANAGE_SUBJECT_SUCCESS:
			return {
				...state,
				submitLoading: false,
				open: false,
			};
		case manageSubjectActionTypes.MANAGE_SUBJECT_ERROR:
			return {
				...state,
				submitLoading: false,
			};
		case manageSubjectActionTypes.CLEAN_MANAGE_SUBJECT_STATE:
			return initialState;
		default:
			return state;
	}
}
