import keyMirror from 'keymirror';
import { EVENTS_TYPES } from '../../shared/Constants';

export const goalSettingsActionTypes = keyMirror({
	GET_GOAL_SETTINGS_REQUEST: null,
	GET_GOAL_SETTINGS_SUCCESS: null,
	GET_GOAL_SETTINGS_ERROR: null,

	GET_GOAL_DURATIONS_REQUEST: null,
	GET_GOAL_DURATIONS_SUCCESS: null,
	GET_GOAL_DURATIONS_ERROR: null,

	GET_FREQUENCY_REQUEST: null,
	GET_FREQUENCY_SUCCESS: null,
	GET_FREQUENCY_ERROR: null,

	SET_SELECTED_SUBJECT: null,
	SET_SELECTED_CATEGORY: null,
	SET_SELECTED_SUBCATEGORY: null,
	SET_SELECTED_DURATION: null,
	SET_CURRENT_PROGRESS: null,

	SAVE_GOAL_SETTING_REQUEST: null,
	SAVE_GOAL_SETTING_SUCCESS: null,
	SAVE_GOAL_SETTING_ERROR: null,

	SET_FORCE_FIRST_STEP: null,
	TOGGLE_DISCARD_CHANGES_MODAL: null,
	SET_FREQUENCY: null,
});

export const SUBJECT_TYPES = {
	practice: 'practice',
	exam: 'exam',
};

export const STEP_FREQUENCY = 'frequency';
export const STEP_DURATIONS = 'durations';
export const STEP_SUBJECTS = 'subjects';
export const STEP_SUBCATEGORIES = 'subcategories';
export const STEP_OVERVIEW = 'overview';
export const STEP_LOADER = 'loader';

export const STEPS = [
	STEP_FREQUENCY,
	STEP_DURATIONS,
	STEP_SUBJECTS,
	STEP_SUBCATEGORIES,
	STEP_OVERVIEW,
	STEP_LOADER,
];

export const LOADER_STEPS_COUNT = 6;

export const DURATION_COLOR_LABELS = ['lavender', 'turquoise', 'yellow', 'orange'];

export const SUBJECT_NAMES_TO_EVENTS_MAP = {
	Rekenen: EVENTS_TYPES.GOAL_SETTINGS_MATH_VISITED,
	Taal: EVENTS_TYPES.GOAL_SETTINGS_LANG_VISITED,
	Toetsen: EVENTS_TYPES.GOAL_SETTINGS_EXAMS_VISITED,
};

export const START_SCREEN_QUERY_KEY = 'account';
export const START_SCREEN_QUERY_VALUE = 'bestaand';

export const STATUSES = {
	ACTIVE: 'active',
	DISABLED: 'disabled',
	HIDDEN: 'hidden',
	CHALLENGE: 'challenge',
};

export const POPULAR_TYPES = {
	PRACTICE: 'practice',
	REPEAT: 'repeat',
};

export const FREQUENCY_LABEL_MAP_1 = {
	1: { color: 'lavender', text: 'goal_settings.choose_frequency.labels_1.days_1' },
	2: { color: 'turquoise', text: 'goal_settings.choose_frequency.labels_1.days_2' },
	3: { color: 'orange', text: 'goal_settings.choose_frequency.labels_1.days_3' },
	4: { color: 'turquoise', text: 'goal_settings.choose_frequency.labels_1.days_4' },
	5: { color: 'lavender', text: 'goal_settings.choose_frequency.labels_1.days_5' },
	6: { color: 'yellow', text: 'goal_settings.choose_frequency.labels_1.days_6' },
	7: { color: 'yellow', text: 'goal_settings.choose_frequency.labels_1.days_7' },
};

export const FREQUENCY_LABEL_MAP_2 = {
	1: { color: 'lavender', text: 'goal_settings.choose_frequency.labels_2.days_1' },
	2: { color: 'turquoise', text: 'goal_settings.choose_frequency.labels_2.days_2' },
	3: { color: 'orange', text: 'goal_settings.choose_frequency.labels_2.days_3' },
	4: { color: 'lavender', text: 'goal_settings.choose_frequency.labels_2.days_4' },
	5: { color: 'turquoise', text: 'goal_settings.choose_frequency.labels_2.days_5' },
	6: { color: 'yellow', text: 'goal_settings.choose_frequency.labels_2.days_6' },
	7: { color: 'yellow', text: 'goal_settings.choose_frequency.labels_2.days_7' },
};

export const WEEKDAYS = ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'];

export const ONBOARDING_FREQUENCY_TIP_KEY = 'onboardingFrequencyTip';
export const ONBOARDING_FREQUENCY_TIP_COUNTER = 5;
