import { shareVideoModalActionTypes } from './Constants';
import * as shareVideoActions from './Actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { processRequest } from '../../../services/Api';
import * as errorHandlerActions from '../../errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			shareVideoModalActionTypes.GET_SHARE_VIDEO_LINK,
			handleGetShareVideoLinkRequest,
		),
	]);
}

export function* handleGetShareVideoLinkRequest({ payload }) {
	try {
		const { id } = payload;

		const { data } = yield call(processRequest, `summaries/${id}/video_sharing_options`);

		yield put(shareVideoActions.getShareVideoLinkSuccess(data));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(shareVideoActions.getShareVideoLinkError());
	}
}
