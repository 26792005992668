import keyMirror from 'keymirror';
import moment from 'moment';

export const activityActionTypes = keyMirror({
	GET_REPORT_ACTIVITY_REQUEST: null,
	GET_REPORT_ACTIVITY_SUCCESS: null,
	GET_REPORT_ACTIVITY_ERROR: null,

	GET_PREVIOUS_REPORT_ACTIVITY_REQUEST: null,
	GET_NEXT_REPORT_ACTIVITY_REQUEST: null,

	GET_PLAYED_DAYS_REQUEST: null,
	GET_PLAYED_DAYS_SUCCESS: null,
	GET_PLAYED_DAYS_ERROR: null,

	SET_ACTIVE_DATE: null,
	SET_PREVIOUS_DATE: null,
	SET_NEXT_DATE: null,
});

export const PARSE_FORMAT_DATE = 'YYYY-MM-DD';

export const MONTH_FORMAT_DATE = 'MMM YYYY';

export const VIEW_FORMAT_DATE = 'dd D MMM YYYY';

export const MIN_SCROLL_OFFSET_HEIGHT = 300;

export const isDateToday = (date) => {
	return moment(new Date()).format(PARSE_FORMAT_DATE) === moment(date).format(PARSE_FORMAT_DATE);
};
