import { notificationsActionTypes, orderNotifications } from './Constants';

const initialState = {
	loading: true,
	open: false,
	recentNotifications: [],
	oldNotifications: [],
	notifications: [],
	page: 1,
	perPage: 10,
	total: 0,
};

export default function notificationsReducer(state = initialState, action) {
	const { type, payload } = action;
	const { status, notificationsData } = payload || {};

	switch (type) {
		case notificationsActionTypes.TOGGLE_NOTIFICATIONS_MENU:
			return {
				...initialState,
				open: status,
			};

		case notificationsActionTypes.GET_NOTIFICATIONS_REQUEST:
			return {
				...state,
				loading: true,
			};

		case notificationsActionTypes.GET_NOTIFICATIONS_SUCCESS:
			const notifications = orderNotifications(
				notificationsData.notifications,
				'created_at',
				'desc',
			);

			return {
				...state,
				loading: false,
				recentNotifications: [
					...state.recentNotifications,
					...notifications.filter((notification) => notification.is_recent),
				],
				oldNotifications: [
					...state.oldNotifications,
					...notifications.filter((notification) => !notification.is_recent),
				],
				notifications: [...state.notifications, ...notifications],
				page: state.page + 1,
				total: notificationsData.total_count,
			};

		case notificationsActionTypes.GET_NOTIFICATIONS_ERROR:
			return {
				...state,
				loading: false,
				open: false,
			};

		case notificationsActionTypes.UPDATE_NOTIFICATION_STATUS_ERROR:
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
}
