import { searchActionTypes } from './Constants';

export function searchRequest(data) {
	return {
		type: searchActionTypes.SEARCH_DETAILS_REQUEST,
		payload: {
			data,
		},
	};
}

export function searchSuccess(data) {
	return {
		type: searchActionTypes.SEARCH_DETAILS_SUCCESS,
		payload: {
			data,
		},
	};
}

export function searchError(error) {
	return {
		type: searchActionTypes.SEARCH_DETAILS_ERROR,
		payload: {
			error,
		},
	};
}

export function setSearchValue(data) {
	return {
		type: searchActionTypes.SET_SEARCH_DETAILS_VALUE,
		payload: { data },
	};
}

export function setAppliedFilters(data) {
	return {
		type: searchActionTypes.SET_APPLIED_FILTERS,
		payload: { data },
	};
}

export function clearResultSection() {
	return {
		type: searchActionTypes.CLEAR_RESULT_SECTIONS,
		payload: {},
	};
}

export function clearFilters(data) {
	return {
		type: searchActionTypes.CLEAR_FILTERS,
		payload: { data },
	};
}

export function changeFilters(data) {
	return {
		type: searchActionTypes.CHANGE_FILTERS,
		payload: { data },
	};
}

export function updateSearchDetailsGroupResults(groupId, status) {
	return {
		type: searchActionTypes.UPDATA_SEARCH_GROUP_RESULTS,
		payload: { groupId, status },
	};
}
