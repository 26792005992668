import { adminActivationCodesActionTypes } from './Constants';

const initialState = {
	loadingCodes: true,
	codes: {
		total_count: 0,
		results: [],
	},
	appliedFilters: {},
	loadingCodeInfo: true,
	codeInfo: null,
};

export default function adminActivationCodesReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data, withoutReloading, source } = payload || {};
	const { offset } = data || {};

	switch (type) {
		case adminActivationCodesActionTypes.GET_ACTIVATION_CODES_REQUEST:
			return {
				...state,
				loadingCodes: !offset,
			};
		case adminActivationCodesActionTypes.GET_ACTIVATION_CODES_SUCCESS:
			return {
				...state,
				loadingCodes: false,
				codes: data,
			};
		case adminActivationCodesActionTypes.GET_ACTIVATION_CODES_ERROR:
			return {
				...state,
				loadingCodes: false,
			};
		case adminActivationCodesActionTypes.CHANGE_ACTIVATION_CODES_FILTERS:
			return {
				...state,
				appliedFilters: data.appliedFilters,
			};
		case adminActivationCodesActionTypes.GET_ACTIVATION_CODE_INFO_REQUEST:
			return {
				...state,
				loadingCodeInfo: !withoutReloading,
			};
		case adminActivationCodesActionTypes.GET_ACTIVATION_CODE_INFO_SUCCESS:
			return {
				...state,
				loadingCodeInfo: false,
				codeInfo: data,
			};
		case adminActivationCodesActionTypes.GET_ACTIVATION_CODE_INFO_ERROR:
			return {
				...state,
				loadingCodeInfo: false,
			};
		case adminActivationCodesActionTypes.CHANGE_ACTIVATION_CODE_STATUS_SUCCESS:
			return {
				...state,
				[source === 'list' ? 'codes' : 'codeInfo']: data,
			};
		case adminActivationCodesActionTypes.CLEAR_ACTIVATION_CODE_INFO:
			return {
				...state,
				loadingCodeInfo: true,
				codeInfo: null,
			};
		default:
			return state;
	}
}
