import { manageTopicActionTypes } from './Constants';

const initialState = {
	open: false,
	loading: false,
	topicId: null,
	topicData: null,
	subcategory: null,
	callback: null,
	submitLoading: false,
};

export default function manageTopicReducer(state = initialState, action) {
	const { type, payload } = action;
	const { open, topicId, topicData, subcategory } = payload || {};

	switch (type) {
		case manageTopicActionTypes.GET_ADMIN_TOPIC_DATA_REQUEST:
			return {
				...state,
				loading: true,
			};
		case manageTopicActionTypes.GET_ADMIN_TOPIC_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				topicData,
			};
		case manageTopicActionTypes.GET_ADMIN_TOPIC_DATA_ERROR:
			return {
				...state,
				loading: false,
			};
		case manageTopicActionTypes.MANAGE_ADMIN_TOPIC_REQUEST:
			return {
				...state,
				submitLoading: true,
			};
		case manageTopicActionTypes.MANAGE_ADMIN_TOPIC_SUCCESS:
			return {
				...state,
				submitLoading: false,
			};
		case manageTopicActionTypes.MANAGE_ADMIN_TOPIC_ERROR:
			return {
				...state,
				submitLoading: false,
			};
		case manageTopicActionTypes.TOGGLE_MANAGE_TOPIC_MODAL:
			return {
				...state,
				open,
				topicId,
				subcategory,
			};
		case manageTopicActionTypes.CLEAN_MANAGE_TOPIC_STATE:
			return initialState;
		default:
			return state;
	}
}
