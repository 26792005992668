import { put, call, takeLatest, all } from 'redux-saga/effects';
import { manageCategoryActionTypes } from './Constants';
import { processRequest } from '../../../../services/Api';
import * as notificationActions from '../../../../components/notification/Actions';
import * as errorHandlerActions from '../../../../components/errorHandler/Actions';
import * as manageCategoryActions from './Actions';
import i18n from 'i18next';

export default function* () {
	yield all([
		yield takeLatest(
			manageCategoryActionTypes.MANAGE_ADMIN_CATEGORY_REQUEST,
			handleManageCategoryRequest,
		),
		yield takeLatest(
			manageCategoryActionTypes.GET_ADMIN_CATEGORY_DATA_REQUEST,
			handleGetCategoryDataRequest,
		),
	]);
}

export function* handleManageCategoryRequest(action) {
	try {
		const { formData, categoryId, subjectId, callback } = action.payload;

		if (categoryId) {
			yield call(processRequest, `admin/topic_categories/${categoryId}`, 'PUT', {
				topic_category: formData,
			});
		} else {
			yield call(processRequest, `admin/subjects/${subjectId}/topic_categories`, 'POST', {
				topic_category: formData,
			});
		}

		yield put(manageCategoryActions.manageCategorySuccess());
		callback && callback();

		yield put(
			notificationActions.createNotification(
				categoryId
					? i18n.t('general.saved_changes')
					: i18n.t('modals.manage_category_modal.add_notification'),
				null,
				false,
				3000,
			),
		);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(manageCategoryActions.manageCategoryError());
	}
}

export function* handleGetCategoryDataRequest(action) {
	try {
		const { id } = action.payload;
		const { data } = yield call(processRequest, `admin/topic_categories/${id}`);
		const { name = '', subject } = data.topic_category;

		yield put(manageCategoryActions.getCategoryDataSuccess({ name, subject }));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(manageCategoryActions.getCategoryDataError());
	}
}
