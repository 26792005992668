import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';
import { historyReplace } from '../../../services/History';
import { switchPackageModalActionTypes } from './Constants';
import * as switchPackageModalActions from './Actions';
import { processRequest } from '../../../services/Api';
import * as notificationActions from '../../notification/Actions';
import * as sharedActions from '../../../shared/Actions';
import * as errorHandlerActions from '../../errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			switchPackageModalActionTypes.GET_PACKAGE_DETAILS_REQUEST,
			handleGetPackageDetailsRequest,
		),
		yield takeLatest(
			switchPackageModalActionTypes.UPGRADE_PACKAGE_REQUEST,
			handleUpgradePackageRequest,
		),
	]);
}

export function* handleGetPackageDetailsRequest({ payload }) {
	try {
		const { data } = yield call(processRequest, `public/packages/${payload.id}`);
		yield put(switchPackageModalActions.getPackageDetailsSuccess(data.package));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(switchPackageModalActions.getPackageDetailsError());
	}
}

export function* handleUpgradePackageRequest({ payload }) {
	try {
		const requestPayload = {
			role: {
				plan_id: payload.planId,
			},
		};
		yield call(processRequest, 'subscriptions/upgrade', 'POST', requestPayload);

		let isPaid = false;

		while (!isPaid) {
			yield delay(1000);

			const { data } = yield call(processRequest, 'subscriptions/invoice');
			if (data.status === 'not_paid') throw new Error('Payment fails');
			isPaid = data.status === 'paid';
		}
		yield call(historyReplace, '/subscription-payment-success');
		yield put(sharedActions.getUserDataRequest());
		yield put(switchPackageModalActions.upgradePackageSuccess());
	} catch (e) {
		yield call(historyReplace, '/my-packages');
		yield put(switchPackageModalActions.upgradePackageError());
		yield put(
			notificationActions.createNotification(
				i18n.t('modals.switch_package_modal.error_notification'),
				null,
				true,
				5000,
			),
		);
	}
}
