import { adminInstructionActionTypes } from './Constants';

const initialState = {
	instruction: {},
	loadingInstruction: true,
	loadingUpdate: false,
	loadingPreview: true,
	loadingPublish: false,
	firstLoad: true,
};

export default function adminInstructionReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data } = payload || {};

	switch (type) {
		case adminInstructionActionTypes.GET_TOPIC_INSTRUCTION_REQUEST:
			return {
				...state,
				loadingInstruction: true,
			};
		case adminInstructionActionTypes.GET_TOPIC_INSTRUCTION_SUCCESS:
			return {
				...state,
				loadingInstruction: false,
				instruction: { ...data },
			};
		case adminInstructionActionTypes.GET_TOPIC_INSTRUCTION_ERROR:
			return {
				...state,
				loadingInstruction: true,
			};
		case adminInstructionActionTypes.MANAGE_TOPIC_INSTRUCTION_REQUEST:
			return {
				...state,
				loadingUpdate: true,
			};
		case adminInstructionActionTypes.MANAGE_TOPIC_INSTRUCTION_SUCCESS:
			return {
				...state,
				loadingUpdate: false,
				instruction: { ...data },
			};
		case adminInstructionActionTypes.MANAGE_TOPIC_INSTRUCTION_ERROR:
			return {
				...state,
				loadingUpdate: false,
			};
		case adminInstructionActionTypes.PREVIEW_TOPIC_INSTRUCTION_REQUEST:
			return {
				...state,
				loadingPreview: true,
			};
		case adminInstructionActionTypes.PREVIEW_TOPIC_INSTRUCTION_SUCCESS:
			return {
				...state,
				loadingPreview: false,
			};
		case adminInstructionActionTypes.PREVIEW_TOPIC_INSTRUCTION_ERROR:
			return {
				...state,
				loadingPreview: false,
			};
		case adminInstructionActionTypes.PUBLISH_TOPIC_INSTRUCTION_REQUEST:
			return {
				...state,
				loadingPublish: true,
			};
		case adminInstructionActionTypes.PUBLISH_TOPIC_INSTRUCTION_SUCCESS:
			return {
				...state,
				loadingPublish: false,
				instruction: { ...state.instruction, status: data.status },
			};
		case adminInstructionActionTypes.PUBLISH_TOPIC_INSTRUCTION_ERROR:
			return {
				...state,
				loadingPublish: false,
			};
		case adminInstructionActionTypes.DELETE_TOPIC_INSTRUCTION_REQUEST:
		case adminInstructionActionTypes.RESET_TOPIC_INSTRUCTION_REQUEST:
			return {
				...state,
				loadingUpdate: true,
			};
		case adminInstructionActionTypes.DELETE_TOPIC_INSTRUCTION_SUCCESS:
		case adminInstructionActionTypes.DELETE_TOPIC_INSTRUCTION_ERROR:
		case adminInstructionActionTypes.RESET_TOPIC_INSTRUCTION_SUCCESS:
		case adminInstructionActionTypes.RESET_TOPIC_INSTRUCTION_ERROR:
			return {
				...state,
				loadingUpdate: false,
			};
		case adminInstructionActionTypes.SET_TOPIC_INSTRUCTION:
			return {
				...state,
				instruction: { ...state.instruction, ...data },
			};
		case adminInstructionActionTypes.SET_FIRST_LOAD:
			return {
				...state,
				firstLoad: data,
			};
		case adminInstructionActionTypes.CLEAR_INSTRUCTION_STATE:
			return initialState;
		default:
			return state;
	}
}
