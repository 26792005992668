import { all, call, put, takeLatest, delay, take } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import i18n from 'i18next';
import { processRequest } from '../../services/Api';
import { withLanguageLocale } from '../../services/Helper';
import { historyReplace } from '../../services/History';
import { SOURCE_TYPES } from '../../services/Constants';
import { packagesActionTypes } from './Constants';
import { parentShopTypes } from '../publicShop/Constants';
import * as packagesActions from './Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';
import * as sharedActions from '../../shared/Actions';
import * as notificationActions from '../../components/notification/Actions';
import * as parentShopActions from '../publicShop/Actions';

export default function* () {
	yield all([
		yield takeLatest(packagesActionTypes.GET_CHOOSE_PACKAGE, handleChoosePackagesRequest),
		yield takeLatest(
			packagesActionTypes.CHOOSE_FREE_PACKAGE_REQUEST,
			handleChooseFreePackageRequest,
		),
		yield takeLatest(
			packagesActionTypes.REACTIVATE_SUBSCRIPTION_REQUEST,
			handleReactivateSubscriptionRequest,
		),
	]);
}

export function* handleChoosePackagesRequest({ payload }) {
	try {
		const { identifier } = payload;
		if (identifier) {
			// later way how we handle experiment can be changed
			yield put(parentShopActions.handleUserExperiment('2022-Q1-two-next-packages'));
			yield take(parentShopTypes.HANDLE_USER_EXPERIMENT_SUCCESS);
		}
		const { data } = yield call(
			processRequest,
			identifier ? `payment_requests/${identifier}/packages` : 'public/packages',
		);

		yield put(packagesActions.getChoosePackageSuccess(data));
	} catch (e) {
		const { status, data } = e.response || {};
		if (status === 400 && data && data.hasOwnProperty('email')) {
			yield put(replace(withLanguageLocale('/already-paid', data)));
		}
		yield put(errorHandlerActions.handleError(e));
	}
}

export function* handleChooseFreePackageRequest({ payload }) {
	try {
		const { packageId } = payload;
		yield call(processRequest, `public/packages/${packageId}/choose`, 'POST');
		yield put(sharedActions.getUserDataRequest());
		yield call(historyReplace, '/');
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(packagesActions.handleChooseFreePackageError());
	}
}

export function* handleReactivateSubscriptionRequest() {
	try {
		yield call(processRequest, 'subscriptions/reactivate', 'POST');

		let isActive = false;
		let subscriptionData = {};
		let counter = 0;
		const timeouts = [500, 1000, 2000, 4000];

		while (!isActive && counter < 4) {
			yield delay(timeouts[counter]);
			const { data } = yield call(processRequest, 'subscriptions', 'GET', {
				source: SOURCE_TYPES.MY_PACKAGES,
			});
			subscriptionData = data;
			counter += 1;
			isActive = data.subscriptions[0].stop_package;
		}

		if (counter > 4) {
			yield put(
				notificationActions.createNotification(
					i18n.t('modals.reactivate_package_modal.notification_error'),
					null,
					true,
					5000,
				),
			);
		}

		yield put(
			packagesActions.toggleReactivateSubscriptionModal({ open: false, loading: false }),
		);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(packagesActions.reactivateSubscriptionError(e));
		yield put(
			packagesActions.toggleReactivateSubscriptionModal({ open: false, loading: false }),
		);
	}
}
