import { signInActionTypes } from './Constants';

export function loginRequest(email_or_username, password, path) {
	return {
		type: signInActionTypes.LOGIN_REQUEST,
		payload: {
			email_or_username,
			password,
			path,
		},
	};
}

export function loginSuccess(token) {
	return {
		type: signInActionTypes.LOGIN_SUCCESS,
		payload: {
			token,
		},
	};
}

export function loginError() {
	return {
		type: signInActionTypes.LOGIN_ERROR,
	};
}

export function firstSocialVerifyRequest(data) {
	return {
		type: signInActionTypes.FIRST_VERIFY_REQUEST,
		payload: data,
	};
}

export function firstSocialVerifySuccess(data) {
	return {
		type: signInActionTypes.FIRST_VERIFY_SUCCESS,
		payload: {
			data,
		},
	};
}

export function firstSocialVerifyError() {
	return {
		type: signInActionTypes.FIRST_VERIFY_ERROR,
	};
}

export function setSocialLoginState(data) {
	return {
		type: signInActionTypes.SET_SOCIAL_LOGIN_STATE,
		payload: { data },
	};
}
