import { sharedTypes } from './Constants';
import i18n from 'i18next';

const initialState = {
	loading: false,
	userData: {
		books: [],
		groups: [],
		folders: [],
		subjects: [],
		canManageBooks: false,
		canAccessNextPackages: false,
	},
	pageSource: '',
	triggerMessagePresent: false,
	translationsInstance: i18n.t,
	backgroundColor: null,
	backgroundPattern: null,
	openMobileFooterMenu: false,
};

export default function sharedReducer(state = initialState, action) {
	const { type, payload } = action;
	const {
		backgroundColor,
		backgroundPattern,
		data,
		translationsInstance,
		userData,
		openMobileFooterMenu,
	} = payload || {};

	switch (type) {
		case sharedTypes.GET_USER_DATA_REQUEST:
			return {
				...state,
				loading: true,
			};

		case sharedTypes.GET_USER_DATA_SUCCESS:
			const reward = userData?.reward || {};
			return {
				...state,
				userData: {
					...userData,
				},
				backgroundColor: reward?.visible ? reward?.background_color : null,
				backgroundPattern: reward?.visible ? reward?.background_pattern : null,
				loading: false,
			};

		case sharedTypes.GET_USER_DATA_ERROR:
			return {
				...state,
				loading: false,
			};

		case sharedTypes.CLEAR_USER_DATA:
			return {
				...state,
				userData: initialState.userData,
			};

		case sharedTypes.SEND_EVENT_SUCCESS:
			return {
				...state,
				...data,
			};

		case sharedTypes.SEND_EVENT_ERROR:
			return {
				...state,
			};

		case sharedTypes.SET_TRANSLATIONS_INSTANCE:
			return {
				...state,
				translationsInstance,
			};

		case sharedTypes.SET_BACKGROUND_COLOR:
			return {
				...state,
				backgroundColor,
			};

		case sharedTypes.SET_BACKGROUND_PATTERN:
			return {
				...state,
				backgroundPattern,
			};

		case sharedTypes.TOGGLE_MOBILE_FOOTER_MENU:
			return {
				...state,
				openMobileFooterMenu,
			};

		default:
			return state;
	}
}
