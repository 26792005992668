import { managePlaylistActionTypes } from './Constants';

const initialState = {
	openManageModal: false,
	openMoveToModal: false,
	loading: false,
	topic: {},
	callback: null,
	playlist: null,
	playlistId: null,
	error: null,
};

export default function manageSubcategoryReducer(state = initialState, action) {
	const { type, payload } = action;
	const { playlistId, playlist, open, topic, callback, error } = payload || {};

	switch (type) {
		case managePlaylistActionTypes.TOGGLE_MANAGE_PLAYLIST_MODAL:
			return {
				...state,
				openManageModal: open,
				topic,
				playlistId,
				callback,
			};
		case managePlaylistActionTypes.TOGGLE_MOVE_PLAYLIST_TO_TOPIC_MODAL:
			return {
				...state,
				openMoveToModal: open,
				playlistId,
				callback,
			};
		case managePlaylistActionTypes.MANAGE_PLAYLIST_MODAL_REQUEST:
		case managePlaylistActionTypes.GET_PLAYLIST_MODAL_REQUEST:
			return {
				...state,
				error: null,
				loading: true,
			};
		case managePlaylistActionTypes.MANAGE_PLAYLIST_MODAL_ERROR:
		case managePlaylistActionTypes.GET_PLAYLIST_MODAL_ERROR:
			return {
				...state,
				error,
				loading: false,
			};
		case managePlaylistActionTypes.GET_PLAYLIST_MODAL_SUCCESS:
			return {
				...state,
				loading: false,
				playlist,
			};
		case managePlaylistActionTypes.MANAGE_PLAYLIST_MODAL_SUCCESS:
			return {
				...state,
				open: false,
				loading: false,
			};
		case managePlaylistActionTypes.CLEAN_MANAGE_PLAYLIST_MODAL_STATE:
			return initialState;
		default:
			return state;
	}
}
