import { searchActionTypes } from './Constants';
import { handleSearchGroupResults } from '../search/Services';

const initialState = {
	loading: false,
	error: '',
	result_sections: [],
	search_terms: '',
	appliedFilters: {},
	noResults: false,
	isChangeFilters: false,
	allSearchData: [],
};

export default function searchDetailReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data, error, status, groupId } = payload || {};

	switch (type) {
		case searchActionTypes.SEARCH_DETAILS_REQUEST:
			return {
				...state,
				loading: data.noNeedLoader ? !data.noNeedLoader : true,
			};

		case searchActionTypes.SET_SEARCH_DETAILS_VALUE:
		case searchActionTypes.CHANGE_FILTERS:
			return {
				...state,
				...data,
			};

		case searchActionTypes.SEARCH_DETAILS_SUCCESS:
			return {
				...state,
				...data,
				loading: false,
			};

		case searchActionTypes.SET_APPLIED_FILTERS:
			return {
				...state,
				...data,
			};

		case searchActionTypes.CLEAR_FILTERS:
			return {
				...state,
				appliedFilters: {},
			};

		case searchActionTypes.CLEAR_RESULT_SECTIONS:
			return {
				...state,
				search_terms: '',
				result_sections: [],
			};

		case searchActionTypes.SEARCH_DETAILS_ERROR:
			return {
				...state,
				error,
				loading: false,
			};

		case searchActionTypes.UPDATA_SEARCH_GROUP_RESULTS:
			return {
				...state,
				result_sections: handleSearchGroupResults(state.result_sections, status, groupId),
			};

		default:
			return state;
	}
}
