import { chooseProfileImageModalActionTypes } from './Constants';

const initialState = {
	open: false,
	loading: true,
	loadingAvatarColor: false,
	loadingPreferredColors: false,
	selectedColor: null,
	preferredColors: [],
};

export default function chooseProfileImageModalReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data, selectedColor, preferredColors } = payload || {};

	switch (type) {
		case chooseProfileImageModalActionTypes.TOGGLE_CHOOSE_PROFILE_IMAGE_MODAL:
			return {
				...state,
				...data,
			};

		case chooseProfileImageModalActionTypes.SET_AVATAR_COLOR:
			return {
				...state,
				loadingAvatarColor: true,
				selectedColor,
			};

		case chooseProfileImageModalActionTypes.GET_PREFERRED_COLORS:
			return {
				...state,
				loadingPreferredColors: true,
				selectedColor,
			};

		case chooseProfileImageModalActionTypes.SET_AVATAR_COLOR_SUCCESS:
		case chooseProfileImageModalActionTypes.SET_AVATAR_COLOR_ERROR:
			return {
				...state,
				loadingAvatarColor: false,
			};

		case chooseProfileImageModalActionTypes.GET_PREFERRED_COLORS_SUCCESS:
			return {
				...state,
				loadingPreferredColors: false,
				preferredColors,
			};

		case chooseProfileImageModalActionTypes.GET_PREFERRED_COLORS_ERROR:
			return {
				...state,
				loadingPreferredColors: false,
			};

		default:
			return state;
	}
}
