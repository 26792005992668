import keyMirror from 'keymirror';

export const userRoleModalActionTypes = keyMirror({
	TOGGLE_USER_ROLE_MODAL: null,
	GET_USER_ROLES_REQUEST: null,
	GET_USER_ROLES_SUCCESS: null,
	GET_USER_ROLES_ERROR: null,
	SET_USER_ROLES_REQUEST: null,
	SET_USER_ROLES_SUCCESS: null,
	SET_USER_ROLES_ERROR: null,
});

export const getRolesOptions = (t) => [
	{
		value: 'admin',
		title: t('modals.user_roles_modal.roles.admin'),
	},
	{
		value: 'editor',
		title: t('modals.user_roles_modal.roles.editor'),
	},
	{
		value: 'tutor',
		title: t('modals.user_roles_modal.roles.tutor'),
	},
	{
		value: 'publisher',
		title: t('modals.user_roles_modal.roles.publisher'),
	},
];
