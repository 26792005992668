import { signUpActionTypes } from './Constants';
import i18n from 'i18next';
import { removeTemporaryAccessToken, setAccessToken } from '../../services/AccessToken';

const initialState = {
	loading: false,
	educationLoading: false,
	saveEducationLoading: false,
	schoolLoading: false,
	bannerLoading: false,
	initialValues: {
		marketing_email_preference: true,
	},
	user_account: null,
	schools: [],
	countries: [],
	education_types: [],
	required_fields: [],
	age: null,
	isSocialLogin: false,
	isTeacher: false,
	levels: [],
	classes: [],
	educationDetails: {
		country: { id: 1 },
		education_type: null,
		level: null,
		class: null,
		school: null,
	},
	educationInitialDetails: {
		country: null,
		education_type: null,
		level: null,
		class: null,
		school: null,
	},
	lastPageSlug: null,
	needToExecuteRecaptcha: false,
	teacherWarningMessage: null,
	motivationData: null,
	motivationDataLoading: false,
	downgradeLoading: false,
};

export default function signUpReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data, lastPageSlug, displayTeacherWarning, motivationData } = payload || {};

	switch (type) {
		case signUpActionTypes.SIGN_UP_REQUEST:
			return {
				...state,
				loading: true,
				educationLoading: true,
				needToExecuteRecaptcha: false,
			};

		case signUpActionTypes.GET_SCHOOLS_REQUEST:
			return {
				...state,
				schoolLoading: true,
			};

		case signUpActionTypes.CHECK_EMAIL_IF_EXIST_REQUEST:
			return {
				...state,
				teacherWarningMessage: null,
			};

		case signUpActionTypes.GET_EDUCATION_TYPE_REQUEST:
			return {
				...state,
				educationLoading: true,
			};

		case signUpActionTypes.SET_AGE:
		case signUpActionTypes.SET_INITIAL_VALUES:
		case signUpActionTypes.SET_IS_SOCIAL_LOGIN:
		case signUpActionTypes.SET_EDUCATION_INFO:
		case signUpActionTypes.SET_EDUCATION_INITIAL_INFO:
			return {
				...state,
				loading: false,
				...data,
			};

		case signUpActionTypes.GET_SCHOOLS_SUCCESS:
			return {
				...state,
				schoolLoading: false,
				...data,
			};

		case signUpActionTypes.GET_EDUCATION_TYPE_SUCCESS:
			return {
				...state,
				educationLoading: false,
				...data,
			};

		case signUpActionTypes.SET_IS_TEACHER:
			return {
				...state,
				...data,
			};

		case signUpActionTypes.CLEAR_EDUCATION_FORM_FIELD:
			return {
				...state,
				classes: [],
				levels: [],
				schools: [],
				education_types: [],
				educationDetails: {
					country: { id: 1, name: '' },
					educationType: null,
					level: null,
					class: null,
					school: null,
				},
			};

		case signUpActionTypes.SIGN_UP_SUCCESS:
			if (data.saveToken) {
				setAccessToken(data.token);
			}

			removeTemporaryAccessToken();

			return {
				...state,
				...data,
				lastPageSlug: null,
				loading: false,
			};

		case signUpActionTypes.SIGN_UP_RERUN:
			return {
				...state,
				...data,
			};

		case signUpActionTypes.GET_EDUCATION_TYPE_ERROR:
		case signUpActionTypes.GET_SCHOOLS_ERROR:
			return {
				...state,
				loading: false,
			};

		case signUpActionTypes.CHECK_EMAIL_IF_EXIST_ERROR:
			return {
				...state,
				teacherWarningMessage: displayTeacherWarning
					? i18n.t('sign_up.teacher_validation')
					: null,
			};

		case signUpActionTypes.SIGN_UP_ERROR:
			return {
				...state,
				loading: false,
				needToExecuteRecaptcha: false,
			};

		case signUpActionTypes.SET_LAST_PAGE_SLUG:
			return {
				...state,
				lastPageSlug,
			};

		case signUpActionTypes.GET_MOTIVATION_DATA_REQUEST:
			return {
				...state,
				motivationDataLoading: true,
			};

		case signUpActionTypes.GET_MOTIVATION_DATA_SUCCESS:
			return {
				...state,
				motivationDataLoading: false,
				motivationData,
			};

		case signUpActionTypes.GET_MOTIVATION_DATA_ERROR:
			return {
				...state,
				motivationDataLoading: false,
			};

		case signUpActionTypes.DOWNGRADE_TEACHER:
			return {
				...state,
				downgradeLoading: true,
			};

		case signUpActionTypes.RESEND_EMAIL_VERIFICATION_REQUEST:
			return {
				...state,
				bannerLoading: true,
			};

		case signUpActionTypes.RESEND_EMAIL_VERIFICATION_SUCCESS:
		case signUpActionTypes.RESEND_EMAIL_VERIFICATION_ERROR:
			return {
				...state,
				bannerLoading: false,
			};

		case signUpActionTypes.CLEAN_SIGN_UP_STATE:
			return initialState;

		default:
			return state;
	}
}
