import { couponReaderActionTypes } from './Constants';

export function setCouponRequest(couponId, url) {
	return {
		type: couponReaderActionTypes.SET_COUPON_REQUEST,
		payload: {
			couponId,
			url,
		},
	};
}

export function setCouponSuccess(listOptions) {
	return {
		type: couponReaderActionTypes.SET_COUPON_SUCCESS,
		payload: {
			listOptions,
		},
	};
}

export function setCouponError() {
	return {
		type: couponReaderActionTypes.SET_COUPON_ERROR,
	};
}

export function validatePublicDiscountCode(couponId, planId, url) {
	return {
		type: couponReaderActionTypes.VALIDATE_PUBLIC_DISCOUNT_CODE_REQUEST,
		payload: {
			couponId,
			planId,
			url,
		},
	};
}

export function validatePublicDiscountCodeSuccess(discountCode) {
	return {
		type: couponReaderActionTypes.VALIDATE_PUBLIC_DISCOUNT_CODE_SUCCESS,
		payload: {
			discountCode,
		},
	};
}

export function validatePublicDiscountCodeError() {
	return {
		type: couponReaderActionTypes.VALIDATE_PUBLIC_DISCOUNT_CODE_ERROR,
	};
}
