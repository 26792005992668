import { activationCodeActionTypes } from './Constants';

export function validateActivationCodeRequest(code, navigateFromValidationPage = false, navigateFromLogin = false) {
	return {
		type: activationCodeActionTypes.VALIDATE_ACTIVATION_CODE_REQUEST,
		payload: {
			code,
			navigateFromValidationPage,
			navigateFromLogin,
		},
	};
}

export function validateActivationCodeSuccess(data) {
	return {
		type: activationCodeActionTypes.VALIDATE_ACTIVATION_CODE_SUCCESS,
		payload: {
			data,
		},
	};
}

export function validateActivationCodeError(error, data, code, navigateFromValidationPage) {
	return {
		type: activationCodeActionTypes.VALIDATE_ACTIVATION_CODE_ERROR,
		payload: {
			error,
			data,
			code,
			navigateFromValidationPage,
		},
	};
}
export function activatePackageRequest(codeId) {
	return {
		type: activationCodeActionTypes.ACTIVATE_PACKAGE_REQUEST,
		payload: {
			codeId,
		},
	};
}

export function activatePackageSuccess() {
	return {
		type: activationCodeActionTypes.ACTIVATE_PACKAGE_SUCCESS,
	};
}

export function activatePackageError() {
	return {
		type: activationCodeActionTypes.ACTIVATE_PACKAGE_ERROR,
	};
}

export function applyActivationCodeRequest() {
	return {
		type: activationCodeActionTypes.APPLY_ACTIVATION_CODE_REQUEST,
	};
}
