import { usersOverviewActionTypes } from './Constants';

export function getUsers(data, withoutReloading) {
	return {
		type: usersOverviewActionTypes.GET_USERS_REQUEST,
		payload: { data, withoutReloading },
	};
}

export function getUsersSuccess(data) {
	return {
		type: usersOverviewActionTypes.GET_USERS_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getUsersError() {
	return {
		type: usersOverviewActionTypes.GET_USERS_ERROR,
	};
}

export function changeUsersFilter(data) {
	return {
		type: usersOverviewActionTypes.CHANGE_USERS_LIST_FILTERS,
		payload: { data },
	};
}

export function getUserInfo(userId, withoutReloading) {
	return {
		type: usersOverviewActionTypes.GET_USER_INFO_REQUEST,
		payload: { userId, withoutReloading },
	};
}

export function getUserInfoSuccess(data) {
	return {
		type: usersOverviewActionTypes.GET_USER_INFO_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getUserInfoError() {
	return {
		type: usersOverviewActionTypes.GET_USER_INFO_ERROR,
	};
}

export function changeUserDetails(userId, data) {
	return {
		type: usersOverviewActionTypes.CHANGE_USER_DETAILS_REQUEST,
		payload: { userId, data },
	};
}

export function changeUserDetailsSuccess() {
	return {
		type: usersOverviewActionTypes.CHANGE_USER_DETAILS_SUCCESS,
	};
}

export function changeUserDetailsError() {
	return {
		type: usersOverviewActionTypes.CHANGE_USER_DETAILS_ERROR,
	};
}

export function changeUserSchoolDetails(userId, data) {
	return {
		type: usersOverviewActionTypes.CHANGE_USER_SCHOOL_DETAILS_REQUEST,
		payload: { userId, data },
	};
}

export function changeUserSchoolDetailsSuccess() {
	return {
		type: usersOverviewActionTypes.CHANGE_USER_SCHOOL_DETAILS_SUCCESS,
	};
}

export function changeUserSchoolDetailsError() {
	return {
		type: usersOverviewActionTypes.CHANGE_USER_SCHOOL_DETAILS_ERROR,
	};
}

export function getUserChargebeeInfo(userId) {
	return {
		type: usersOverviewActionTypes.GET_USER_CHARGEBEE_INFO_REQUEST,
		payload: { userId },
	};
}

export function getUserChargebeeInfoSuccess(data) {
	return {
		type: usersOverviewActionTypes.GET_USER_CHARGEBEE_INFO_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getUserChargebeeInfoError() {
	return {
		type: usersOverviewActionTypes.GET_USER_CHARGEBEE_INFO_ERROR,
	};
}

export function userPasswordReset(email) {
	return {
		type: usersOverviewActionTypes.USER_PASSWORD_RESET_REQUEST,
		payload: { email },
	};
}

export function userPasswordResetSuccess(data) {
	return {
		type: usersOverviewActionTypes.USER_PASSWORD_RESET_SUCCESS,
		payload: {
			data,
		},
	};
}

export function userPasswordResetError() {
	return {
		type: usersOverviewActionTypes.USER_PASSWORD_RESET_ERROR,
	};
}

export function deleteUser(userId, callback) {
	return {
		type: usersOverviewActionTypes.ADMIN_DELETE_USER_REQUEST,
		payload: { userId, callback },
	};
}

export function getUserHistory(userId) {
	return {
		type: usersOverviewActionTypes.GET_USER_HISTORY_REQUEST,
		payload: { userId },
	};
}

export function getUserHistorySuccess(data) {
	return {
		type: usersOverviewActionTypes.GET_USER_HISTORY_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getUserHistoryError() {
	return {
		type: usersOverviewActionTypes.GET_USER_HISTORY_ERROR,
	};
}

export function clearUserInfo() {
	return {
		type: usersOverviewActionTypes.CLEAR_USER_INFO,
	};
}

export function updatePersonalDataRequest(user_id, child_name, grade_id) {
	return {
		type: usersOverviewActionTypes.UPDATE_PERSONAL_DATA_REQUEST,
		payload: {
			user_id,
			child_name,
			grade_id,
		},
	};
}

export function updatePersonalDataSuccess() {
	return {
		type: usersOverviewActionTypes.UPDATE_PERSONAL_DATA_SUCCESS,
		payload: {},
	};
}

export function updatePersonalDataError() {
	return {
		type: usersOverviewActionTypes.UPDATE_PERSONAL_DATA_ERROR,
		payload: {},
	};
}
