import { COOKIES, ACCESS_TOKEN_EXPIRATION_DAYS } from './Constants';
import { isSecure, getCookie, setCookie, removeCookie } from './Helper';
import { processRequest } from './Api';
import { historyReplace } from './History';

export const getAccessToken = () => {
	const token = getCookie(COOKIES.accessToken);
	const temporaryToken = getCookie(COOKIES.temporaryToken);

	return token || temporaryToken;
};

export const refreshAccessToken = async () => {
	try {
		const { data } = await processRequest('auth/refresh_token', 'POST');
		const { auth_token } = data || {};

		await setAccessToken(auth_token);
	} catch (e) {
		removeAccessToken();
		historyReplace('/inloggen');
	}
};

export const setAccessToken = (token) => {
	setCookie(COOKIES.accessToken, token, {
		expires: ACCESS_TOKEN_EXPIRATION_DAYS,
		secure: isSecure(),
	});
};

export const setTemporaryAccessToken = (token) => {
	setCookie(COOKIES.temporaryToken, token, {
		expires: ACCESS_TOKEN_EXPIRATION_DAYS,
		secure: isSecure(),
	});
};

export const removeAccessToken = () => {
	removeCookie(COOKIES.accessToken, { path: '/' });
};

export const removeTemporaryAccessToken = () => {
	removeCookie(COOKIES.temporaryToken);
};

export const isAuthenticated = () => {
	return !!getCookie(COOKIES.accessToken);
};

export const getTokenData = () => {
	try {
		const token = getCookie(COOKIES.accessToken);
		const tokenPayload = token.split('.')[1];
		const decodedTokenPayload = atob(tokenPayload);
		return JSON.parse(decodedTokenPayload);
	} catch (e) {
		if (e instanceof TypeError || e instanceof SyntaxError) {
			return null;
		}
		throw e;
	}
};
