import { parentShopTypes } from './Constants';

export function getLevelsRequest(experiment) {
	return {
		type: parentShopTypes.GET_LEVELS_REQUEST,
		payload: {
			experiment,
		},
	};
}

export function getLevelsSuccess(data) {
	return {
		type: parentShopTypes.GET_LEVELS_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getLevelsError(error) {
	return {
		type: parentShopTypes.GET_LEVELS_ERROR,
		payload: {
			error,
		},
	};
}

export function getPackagesRequest(id, experiment) {
	return {
		type: parentShopTypes.GET_PACKAGES_REQUEST,
		payload: {
			id,
			experiment,
		},
	};
}

export function getPackagesSuccess(data) {
	return {
		type: parentShopTypes.GET_PACKAGES_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getPackagesError(error) {
	return {
		type: parentShopTypes.GET_PACKAGES_ERROR,
		payload: {
			error,
		},
	};
}

export function handleUserExperiment(experimentName) {
	return {
		type: parentShopTypes.HANDLE_USER_EXPERIMENT,
		payload: {
			experimentName,
		},
	};
}

export function handleUserExperimentSuccess(experimentName) {
	return {
		type: parentShopTypes.HANDLE_USER_EXPERIMENT_SUCCESS,
		payload: {
			experimentName,
		},
	};
}

export function cleanPackages() {
	return {
		type: parentShopTypes.CLEAN_PACKAGES,
	};
}
