import { addWysiwygFormulaModalActionTypes } from './Constants';

const initialState = {
	open: false,
	addEmbedBlock: () => {},
	formulaData: null,
};

export default function addWysiwygFormulaModalReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data } = payload || {};

	switch (type) {
		case addWysiwygFormulaModalActionTypes.HANDLE_ADD_WYSIWYG_FORMULA_MODAL:
			return {
				...state,
				...data,
			};
		default:
			return state;
	}
}
