import { shopActionTypes } from './Constants';
import { change } from 'redux-form';

export function getPaymentRequestsShopRequest(shopId, packageId, paymentType) {
	return {
		type: shopActionTypes.GET_PAYMENT_REQUESTS_SHOP_DATA,
		payload: {
			shopId,
			packageId,
			paymentType,
		},
	};
}

export function getPaymentRequestsShopSuccess(shopData, discountCode) {
	return {
		type: shopActionTypes.GET_PAYMENT_REQUESTS_SHOP_DATA_SUCCESS,
		payload: {
			shopData,
			discountCode,
		},
	};
}

export function getPaymentMethodsRequest() {
	return {
		type: shopActionTypes.GET_PAYMENT_METHODS_REQUEST,
	};
}

export function getPaymentMethodsSuccess(paymentMethods) {
	return {
		type: shopActionTypes.GET_PAYMENT_METHODS_SUCCESS,
		payload: {
			paymentMethods,
		},
	};
}

export function handleSubscriptionRequest(paymentIntentId, pageType, shopData) {
	return {
		type: shopActionTypes.HANDLE_SUBSCRIPTION_REQUEST,
		payload: {
			paymentIntentId,
			pageType,
			shopData,
		},
	};
}

export function handleSubscriptionSuccess() {
	return {
		type: shopActionTypes.HANDLE_SUBSCRIPTION_SUCCESS,
	};
}

export function cleanShopData() {
	return {
		type: shopActionTypes.CLEAN_SHOP_DATA,
	};
}

export function getFailedPaymentsRequest(invoiceId, secret, paymentType) {
	return {
		type: shopActionTypes.GET_FAILED_PAYMENTS_REQUEST,
		payload: {
			invoiceId,
			secret,
			paymentType,
		},
	};
}

export function getFailedPaymentsSuccess(shopData) {
	return {
		type: shopActionTypes.GET_FAILED_PAYMENTS_SUCCESS,
		payload: {
			shopData,
		},
	};
}

export function getReceiptDataRequest(planId) {
	return {
		type: shopActionTypes.GET_RECEIPT_DATA,
		payload: {
			planId,
		},
	};
}

export function getReceiptDataSuccess(shopData) {
	return {
		type: shopActionTypes.GET_RECEIPT_DATA_SUCCESS,
		payload: {
			shopData,
		},
	};
}
export function createUserShopForm(planId, submitData, callback, setEmailExists, utmTags) {
	return {
		type: shopActionTypes.CREATE_USER_SHOP_FORM,
		payload: {
			planId,
			submitData,
			callback,
			setEmailExists,
			utmTags,
		},
	};
}

export function createUserShopFormSuccess(userData) {
	return {
		type: shopActionTypes.CREATE_USER_SHOP_FORM_SUCCESS,
		payload: {
			userData,
		},
	};
}

export function createUserShopFormError() {
	return {
		type: shopActionTypes.CREATE_USER_SHOP_FORM_ERROR,
	};
}

export function getShopUserRequest() {
	return {
		type: shopActionTypes.GET_SHOP_USER_DATA_REQUEST,
	};
}

export function getShopUserSuccess(userData) {
	return {
		type: shopActionTypes.GET_SHOP_USER_DATA_SUCCESS,
		payload: {
			userData,
		},
	};
}

export function getShopUserError() {
	return {
		type: shopActionTypes.GET_SHOP_USER_DATA_ERROR,
	};
}

export function validateCouponRequest(couponCode, planId, isFreeShop) {
	return {
		type: shopActionTypes.VALIDATE_COUPON_REQUEST,
		payload: {
			couponCode,
			planId,
			isFreeShop,
		},
	};
}

export function validateCouponSuccess(data) {
	return {
		type: shopActionTypes.VALIDATE_COUPON_SUCCESS,
		payload: {
			data,
		},
	};
}

export function validateCouponError() {
	return {
		type: shopActionTypes.VALIDATE_COUPON_ERROR,
	};
}

export function clearValidateCoupon() {
	return {
		type: shopActionTypes.CLEAR_VALIDATE_COUPON,
	};
}

export function changeFormField(formName, fieldName, value) {
	return change(formName, fieldName, value);
}
