import { payInvoiceModalActionTypes } from './Constants';

export function togglePayInvoiceModal(data) {
	return {
		type: payInvoiceModalActionTypes.TOGGLE_PAY_INVOICE_MODAL,
		payload: { data },
	};
}

export function sendInvoiceToParent(email, invoiceId, secret) {
	return {
		type: payInvoiceModalActionTypes.SEND_INVOICE_TO_PARENT_REQUEST,
		payload: { email, invoiceId, secret },
	};
}

export function sendInvoiceToParentSuccess() {
	return {
		type: payInvoiceModalActionTypes.SEND_INVOICE_TO_PARENT_SUCCESS,
	};
}

export function sendInvoiceToParentError(error) {
	return {
		type: payInvoiceModalActionTypes.SEND_INVOICE_TO_PARENT_ERROR,
		payload: { error },
	};
}
