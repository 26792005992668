import { packagesActionTypes } from './Constants';

export function getChoosePackageRequest(identifier) {
	return {
		type: packagesActionTypes.GET_CHOOSE_PACKAGE,
		payload: { identifier },
	};
}

export function getChoosePackageSuccess(choosePackagesData) {
	return {
		type: packagesActionTypes.GET_CHOOSE_PACKAGE_SUCCESS,
		payload: {
			choosePackagesData,
		},
	};
}

export function cancelSubscriptionRequest(date) {
	return {
		type: packagesActionTypes.CANCEL_SUBSCRIPTION_REQUEST,
		payload: {
			date,
		},
	};
}

export function cancelSubscriptionSuccess(surveyLink) {
	return {
		type: packagesActionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
		payload: {
			surveyLink,
		},
	};
}

export function cancelSubscriptionError() {
	return {
		type: packagesActionTypes.CANCEL_SUBSCRIPTION_ERROR,
	};
}

export function toggleCancelMembershipModal(status) {
	return {
		type: packagesActionTypes.TOGGLE_CANCEL_MEMBERSHIP_MODAL,
		payload: {
			status,
		},
	};
}

export function toggleReactivateSubscriptionModal(data) {
	return {
		type: packagesActionTypes.TOGGLE_REACTIVATE_SUBSCRIPTION_MODAL,
		payload: {
			data,
		},
	};
}

export function chooseFreePackageRequest(packageId) {
	return {
		type: packagesActionTypes.CHOOSE_FREE_PACKAGE_REQUEST,
		payload: {
			packageId,
		},
	};
}

export function handleChooseFreePackageError() {
	return {
		type: packagesActionTypes.CHOOSE_FREE_PACKAGE_ERROR,
	};
}

export function cleanPackagesState() {
	return {
		type: packagesActionTypes.CLEAN_PACKAGES_STATE,
	};
}

export function reactivateSubscriptionRequest(date) {
	return {
		type: packagesActionTypes.REACTIVATE_SUBSCRIPTION_REQUEST,
		payload: {
			date,
		},
	};
}

export function reactivateSubscriptionSuccess(surveyLink) {
	return {
		type: packagesActionTypes.REACTIVATE_SUBSCRIPTION_SUCCESS,
		payload: {
			surveyLink,
		},
	};
}

export function reactivateSubscriptionError() {
	return {
		type: packagesActionTypes.REACTIVATE_SUBSCRIPTION_ERROR,
	};
}
