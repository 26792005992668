import { paymentDetailsActionTypes } from './Constants';

const initialState = {
	loading: false,
	paymentData: null,
};

export default function paymentDetailsReducer(state = initialState, action) {
	const { type, payload } = action;
	const { paymentData } = payload || {};

	switch (type) {
		case paymentDetailsActionTypes.GET_PAYMENT_DETAILS_REQUEST:
			return {
				...state,
				loading: true,
			};

		case paymentDetailsActionTypes.GET_PAYMENT_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				paymentData,
			};

		case paymentDetailsActionTypes.GET_PAYMENT_DETAILS_ERROR:
			return {
				...state,
				loading: false,
				paymentData: null,
			};

		default:
			return state;
	}
}
