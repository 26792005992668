import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import { getTranslationsInstance } from '../../shared/Selectors';
import { processRequest } from '../../services/Api';
import { historyReplace } from '../../services/History';
import { couponMessageSuccess, couponReaderActionTypes } from './Constants';
import { MARKETING_URL } from '../../services/Constants';
import * as couponReaderActions from './Actions';
import * as notificationActions from '../../components/notification/Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(couponReaderActionTypes.SET_COUPON_REQUEST, handleSetCouponRequest),
		yield takeLatest(
			couponReaderActionTypes.VALIDATE_PUBLIC_DISCOUNT_CODE_REQUEST,
			handleValidatePublicDiscountCodeRequest,
		),
	]);
}

export function* handleSetCouponRequest(action) {
	try {
		const { couponId, url } = action.payload;
		const requestPayload = {
			user: {
				pending_discount_code: couponId,
			},
		};

		const translationsInstance = yield select(getTranslationsInstance);
		const { data: userInfo } = yield call(processRequest, 'get_user_data');
		const { data } = yield call(processRequest, `users/${userInfo.id}`, 'PUT', requestPayload);
		const {
			stored_discount_code: { success, name, message },
		} = data || {};

		if (userInfo.needs_to_choose_package) {
			if (url) {
				yield call(historyReplace, url);
			} else {
				window.location.href = `${MARKETING_URL}bestellen/prijzen`;
			}
		}

		if (success) {
			yield put(
				notificationActions.createNotification(
					couponMessageSuccess(name, translationsInstance),
					null,
					false,
					5000,
				),
			);
		} else {
			if (!url) yield call(historyReplace, '/recent');
			yield put(notificationActions.createNotification(message, null, true, 5000));
		}
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(couponReaderActions.setCouponError());
	}
}

export function* handleValidatePublicDiscountCodeRequest(action) {
	try {
		const { couponId, planId, url } = action.payload;
		const requestPayload = {
			coupon_code: couponId,
			plan_id: planId,
		};
		const translationsInstance = yield select(getTranslationsInstance);

		const { data } = yield call(
			processRequest,
			'public/coupons/validate',
			'GET',
			requestPayload,
		);

		const { success, name, message } = data || {};

		yield put(couponReaderActions.validatePublicDiscountCodeSuccess(couponId));

		if (url) {
			yield call(historyReplace, url);
		}

		if (success) {
			yield put(
				notificationActions.createNotification(
					couponMessageSuccess(name, translationsInstance),
					null,
					false,
					5000,
				),
			);
		} else {
			yield put(notificationActions.createNotification(message, null, true, 5000));
		}
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(couponReaderActions.validatePublicDiscountCodeError());
	}
}
