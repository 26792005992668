import { switchPackageModalActionTypes } from './Constants';

export function toggleSwitchPackageModal(data) {
	return {
		type: switchPackageModalActionTypes.TOGGLE_SWITCH_PACKAGE_MODAL,
		payload: {
			data,
		},
	};
}
export function getPackageDetailsRequest(id) {
	return {
		type: switchPackageModalActionTypes.GET_PACKAGE_DETAILS_REQUEST,
		payload: {
			id,
		},
	};
}
export function getPackageDetailsSuccess(selectedPackage) {
	return {
		type: switchPackageModalActionTypes.GET_PACKAGE_DETAILS_SUCCESS,
		payload: {
			selectedPackage,
		},
	};
}
export function getPackageDetailsError(error) {
	return {
		type: switchPackageModalActionTypes.GET_PACKAGE_DETAILS_ERROR,
		payload: {
			error,
		},
	};
}
export function upgradePackageRequest(planId) {
	return {
		type: switchPackageModalActionTypes.UPGRADE_PACKAGE_REQUEST,
		payload: {
			planId,
		},
	};
}
export function upgradePackageSuccess() {
	return {
		type: switchPackageModalActionTypes.UPGRADE_PACKAGE_SUCCESS,
	};
}
export function upgradePackageError(error) {
	return {
		type: switchPackageModalActionTypes.UPGRADE_PACKAGE_ERROR,
		payload: {
			error,
		},
	};
}
export function clearSwitchPackageModalState() {
	return {
		type: switchPackageModalActionTypes.CLEAR_SWITCH_PACKAGE_MODAL_STATE,
	};
}
