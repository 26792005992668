import { subjectDetailsActionTypes } from './Constants';

export function getSubjectDetailsRequest(subjectId) {
	return {
		type: subjectDetailsActionTypes.GET_ADMIN_SUBJECT_DETAILS_REQUEST,
		payload: { subjectId },
	};
}

export function getSubjectDetailsSuccess(subjectData) {
	return {
		type: subjectDetailsActionTypes.GET_ADMIN_SUBJECT_DETAILS_SUCCESS,
		payload: { subjectData },
	};
}

export function getSubjectDetailsError() {
	return {
		type: subjectDetailsActionTypes.GET_ADMIN_SUBJECT_DETAILS_ERROR,
	};
}

export function cleanSubjectDetailsState() {
	return {
		type: subjectDetailsActionTypes.CLEAN_ADMIN_SUBJECT_DETAILS_STATE,
	};
}

export function getSubjectCategories({ subjectId, query }) {
	return {
		type: subjectDetailsActionTypes.GET_ADMIN_SUBJECT_CATEGORIES_REQUEST,
		payload: { subjectId, query },
	};
}

export function getSubjectCategoriesSuccess(subjectCategories) {
	return {
		type: subjectDetailsActionTypes.GET_ADMIN_SUBJECT_CATEGORIES_SUCCESS,
		payload: { subjectCategories },
	};
}

export function getSubjectCategoriesError() {
	return {
		type: subjectDetailsActionTypes.GET_ADMIN_SUBJECT_CATEGORIES_ERROR,
	};
}

export function changeSubjectCategoriesFilter(data) {
	return {
		type: subjectDetailsActionTypes.CHANGE_ADMIN_SUBJECT_CATEGORIES_FILTER,
		payload: { data },
	};
}

export function setSubjectCategoryOpenState({ categoryId, isOpen }) {
	return {
		type: subjectDetailsActionTypes.SET_ADMIN_CATEGORY_OPEN_STATE,
		payload: { categoryId, isOpen },
	};
}

export function setAllSubjectCategoryOpenState(isOpen) {
	return {
		type: subjectDetailsActionTypes.SET_ADMIN_ALL_CATEGORIES_OPEN_STATE,
		payload: { isOpen },
	};
}

export function changeCategoryPositionRequest(data) {
	return {
		type: subjectDetailsActionTypes.CHANGE_ADMIN_CATEGORY_POSITION_REQUEST,
		payload: { data },
	};
}

export function changeCategoryPositionSuccess() {
	return {
		type: subjectDetailsActionTypes.CHANGE_ADMIN_CATEGORY_POSITION_SUCCESS,
	};
}

export function changeCategoryPositionError() {
	return {
		type: subjectDetailsActionTypes.CHANGE_ADMIN_CATEGORY_POSITION_ERROR,
	};
}

export function deleteCategoryRequest({ categoryId, callback }) {
	return {
		type: subjectDetailsActionTypes.DELETE_ADMIN_CATEGORY_REQUEST,
		payload: { categoryId, callback },
	};
}

export function deleteCategorySuccess() {
	return {
		type: subjectDetailsActionTypes.DELETE_ADMIN_CATEGORY_SUCCESS,
	};
}

export function deleteCategoryError() {
	return {
		type: subjectDetailsActionTypes.DELETE_ADMIN_CATEGORY_ERROR,
	};
}

export function getSubcategoriesRequest({ categoryId }) {
	return {
		type: subjectDetailsActionTypes.GET_ADMIN_SUBCATEGORIES_REQUEST,
		payload: { categoryId },
	};
}

export function getSubcategoriesSuccess({ categoryId, subcategories }) {
	return {
		type: subjectDetailsActionTypes.GET_ADMIN_SUBCATEGORIES_SUCCESS,
		payload: { categoryId, subcategories },
	};
}

export function getSubcategoriesError() {
	return {
		type: subjectDetailsActionTypes.GET_ADMIN_SUBCATEGORIES_ERROR,
	};
}

export function deleteSubcategoryRequest({ subcategoryId, callback }) {
	return {
		type: subjectDetailsActionTypes.DELETE_ADMIN_SUBCATEGORY_REQUEST,
		payload: { subcategoryId, callback },
	};
}

export function deleteSubcategorySuccess() {
	return {
		type: subjectDetailsActionTypes.DELETE_ADMIN_SUBCATEGORY_SUCCESS,
	};
}

export function deleteSubcategoryError() {
	return {
		type: subjectDetailsActionTypes.DELETE_ADMIN_SUBCATEGORY_ERROR,
	};
}

export function changeSubcategoryPositionRequest(data) {
	return {
		type: subjectDetailsActionTypes.CHANGE_ADMIN_SUBCATEGORY_POSITION_REQUEST,
		payload: { data },
	};
}

export function changeSubcategoryPositionSuccess() {
	return {
		type: subjectDetailsActionTypes.CHANGE_ADMIN_SUBCATEGORY_POSITION_SUCCESS,
	};
}

export function changeSubcategoryPositionError() {
	return {
		type: subjectDetailsActionTypes.CHANGE_ADMIN_SUBCATEGORY_POSITION_ERROR,
	};
}
