import keyMirror from 'keymirror';
import {
	IS_ANDROID_APP,
	IS_IOS_APP,
	APPLE_STORE_LINK,
	GOOGLE_PLAY_STORE_LINK,
} from '../../services/Constants';

export const errorHandlerActionTypes = keyMirror({
	HANDLE_ERROR: null,
	CLEAR_ERROR: null,
});

export const getErrorInfoDependsOfStatusCode = (statusCode, params, t) => {
	if (statusCode === 404) return t(`404.${params}`);
	if (statusCode === 403) return t(`403.${params}`);
	return t(`500.${params}`);
};

export const navigateToAppStore = () => {
	if (IS_IOS_APP) {
		window.open(APPLE_STORE_LINK, '_system');
	} else if (IS_ANDROID_APP) {
		window.open(GOOGLE_PLAY_STORE_LINK, '_system');
	}
};
