import { all, call, put, takeLatest } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import { getPaymentDetailsError, getPaymentDetailsSuccess } from './Actions';
import { paymentDetailsActionTypes } from './Constants';
import * as paymentDetailsActions from './Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			paymentDetailsActionTypes.GET_PAYMENT_DETAILS_REQUEST,
			handleGetPaymentData,
		),
	]);
}

export function* handleGetPaymentData(action) {
	try {
		const { paymentIntentId } = action.payload;
		const { data } = yield call(processRequest, `payment_intents/${paymentIntentId}`);

		yield put(paymentDetailsActions.getPaymentDetailsSuccess(data.payment_intent));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(paymentDetailsActions.getPaymentDetailsError());
	}
}
