import { addImageModalActionTypes } from './Constants';

const initialState = {
	loading: false,
	open: false,
	itemId: null,
	imageSrc: null,
	images: [],
	hideMenuItems: false,
	type: null,
};

export default function addImageModalReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data, itemId, images, publicLink } = payload || {};

	switch (type) {
		case addImageModalActionTypes.HANDLE_ADD_IMAGE_MODAL:
			return {
				...state,
				loading: false,
				...data,
			};

		case addImageModalActionTypes.GET_IMAGES_REQUEST:
		case addImageModalActionTypes.UPLOAD_IMAGE_REQUEST:
			return {
				...state,
				loading: true,
			};

		case addImageModalActionTypes.GET_IMAGES_SUCCESS:
			return {
				...state,
				loading: false,
				images: images.image_urls,
			};

		case addImageModalActionTypes.UPLOAD_IMAGE_SUCCESS:
			return {
				...state,
				loading: false,
				open: false,
				itemId,
				imageSrc: publicLink,
			};

		case addImageModalActionTypes.GET_IMAGES_ERROR:
			return {
				...state,
				loading: false,
				images: [],
			};

		case addImageModalActionTypes.UPLOAD_IMAGE_ERROR:
			return {
				...state,
				loading: false,
			};

		case addImageModalActionTypes.CLEAR_ADD_IMAGE_MODAL_STATE:
			return initialState;

		default:
			return state;
	}
}
