import { processRequest } from '../../services/Api';
import { put, call, takeLatest, all } from 'redux-saga/effects';
import { historyReplace } from '../../services/History';
import { resetPasswordActionTypes } from './Constants';
import * as resetPasswordActions from './Actions';
import * as notificationActions from '../../components/notification/Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			resetPasswordActionTypes.RESET_PASSWORD_REQUEST,
			handleResetPasswordRequest,
		),
	]);
}

export function* handleResetPasswordRequest(action) {
	try {
		const { resetToken, password } = action.payload;
		const requestPayload = {
			reset_token: resetToken,
			new_password: password,
		};
		const { data } = yield call(processRequest, 'password/do_reset', 'POST', requestPayload);
		yield put(resetPasswordActions.resetPasswordSuccess());
		yield call(historyReplace, '/inloggen');
		yield put(notificationActions.createNotification(data.info, null, false, 5000));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(resetPasswordActions.resetPasswordError(e));
	}
}
