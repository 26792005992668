import { paymentDetailsActionTypes } from './Constants';

export function getPaymentDetailsRequest(paymentIntentId) {
	return {
		type: paymentDetailsActionTypes.GET_PAYMENT_DETAILS_REQUEST,
		payload: {
			paymentIntentId,
		},
	};
}

export function getPaymentDetailsSuccess(paymentData) {
	return {
		type: paymentDetailsActionTypes.GET_PAYMENT_DETAILS_SUCCESS,
		payload: {
			paymentData,
		},
	};
}

export function getPaymentDetailsError() {
	return {
		type: paymentDetailsActionTypes.GET_PAYMENT_DETAILS_ERROR,
	};
}
