import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import i18n from 'i18next';
import { processRequest } from '../../../../services/Api';
import { codesAppliedFilters } from '../../../pages/activetionCodes/Selectors';
import { createActivationCodeActionTypes } from './Constants';
import * as notificationActions from '../../../../components/notification/Actions';
import * as errorHandlerActions from '../../../../components/errorHandler/Actions';
import * as createActivationCodeActions from './Actions';
import * as activationCodesActions from '../../../pages/activetionCodes/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			createActivationCodeActionTypes.GET_ADMIN_PACKAGES,
			handleGetPackagesRequest,
		),
		yield takeLatest(
			createActivationCodeActionTypes.CREATE_ACTIVATION_CODE,
			handleCreateCodeRequest,
		),
	]);
}

export function* handleGetPackagesRequest() {
	try {
		const { data } = yield call(processRequest, 'admin/plans');

		yield put(createActivationCodeActions.getPackagesSuccess(data.plans));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(createActivationCodeActions.getPackagesError());
	}
}

export function* handleCreateCodeRequest(action) {
	try {
		const { data } = action.payload;
		yield call(processRequest, 'admin/activation_codes', 'POST', data);
		yield put(
			notificationActions.createNotification(
				i18n.t('admin_activation_codes.create_notification', { count: data.count }),
				null,
				false,
				3000,
			),
		);
		const overviewAppliedFilters = yield select(codesAppliedFilters);
		yield put(createActivationCodeActions.createCodeSuccess());
		yield put(activationCodesActions.getActivationCodes(overviewAppliedFilters));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(createActivationCodeActions.createCodeError());
	}
}
