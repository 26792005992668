import { subjectsActionTypes } from './Constants';

export function getSubjectsRequest(data) {
	return {
		type: subjectsActionTypes.GET_ADMIN_SUBJECTS_REQUEST,
		payload: { data },
	};
}

export function getSubjectsSuccess(data) {
	return {
		type: subjectsActionTypes.GET_ADMIN_SUBJECTS_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getSubjectsError() {
	return {
		type: subjectsActionTypes.GET_ADMIN_SUBJECTS_ERROR,
	};
}

export function changeSubjectPositionRequest({ id, position, callback }) {
	return {
		type: subjectsActionTypes.CHANGE_ADMIN_SUBJECT_POSITION_REQUEST,
		payload: { id, position, callback },
	};
}

export function changeSubjectPositionSuccess() {
	return {
		type: subjectsActionTypes.CHANGE_ADMIN_SUBJECT_POSITION_SUCCESS,
	};
}

export function changeSubjectPositionError() {
	return {
		type: subjectsActionTypes.CHANGE_ADMIN_SUBJECT_POSITION_ERROR,
	};
}

export function deleteSubjectRequest({ subjectId, callback }) {
	return {
		type: subjectsActionTypes.DELETE_ADMIN_SUBJECT_REQUEST,
		payload: { subjectId, callback },
	};
}

export function deleteSubjectSuccess() {
	return {
		type: subjectsActionTypes.DELETE_ADMIN_SUBJECT_SUCCESS,
	};
}

export function deleteSubjectError() {
	return {
		type: subjectsActionTypes.DELETE_ADMIN_SUBJECT_ERROR,
	};
}
