import { setGTMData } from '../../services/Helper';
import { signInActionTypes } from './Constants';
import {
	removeTemporaryAccessToken,
	setAccessToken,
	setTemporaryAccessToken,
} from '../../services/AccessToken';

const initialState = {
	loading: false,
	socialLoading: false,
	token: '',
	userId: null,
};

export default function signInReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data, token } = payload || {};

	switch (type) {
		case signInActionTypes.LOGIN_REQUEST:
			return {
				...state,
				loading: true,
			};

		case signInActionTypes.LOGIN_SUCCESS:
			setAccessToken(token);
			removeTemporaryAccessToken();

			setGTMData({
				event: 'successfulLogin',
			});

			return {
				...state,
				token: '',
				userId: null,
				loading: false,
				socialLoading: false,
			};

		case signInActionTypes.FIRST_VERIFY_SUCCESS:
			setTemporaryAccessToken(data.token);

			return {
				...state,
				...data,
				loading: false,
				socialLoading: false,
			};

		case signInActionTypes.LOGIN_ERROR:
			return {
				...state,
				loading: false,
			};

		case signInActionTypes.FIRST_VERIFY_REQUEST:
			return {
				...state,
				socialLoading: true,
			};

		case signInActionTypes.FIRST_VERIFY_ERROR:
			return {
				...state,
				socialLoading: false,
			};

		case signInActionTypes.SET_SOCIAL_LOGIN_STATE:
			return {
				...state,
				...data,
			};

		default:
			return state;
	}
}
