import { addWysiwygImageModalActionTypes } from './Constants';

export function handleAddImageModal(data) {
	return {
		type: addWysiwygImageModalActionTypes.HANDLE_ADD_WYSIWYG_IMAGE_MODAL,
		payload: {
			data,
		},
	};
}

export function uploadImageRequest(data, url, quill) {
	return {
		type: addWysiwygImageModalActionTypes.UPLOAD_WYSIWYG_IMAGE_REQUEST,
		error: true,
		payload: {
			data,
			url,
			quill,
		},
	};
}

export function uploadImageSuccess() {
	return {
		type: addWysiwygImageModalActionTypes.UPLOAD_WYSIWYG_IMAGE_SUCCESS,
	};
}

export function uploadImageError() {
	return {
		type: addWysiwygImageModalActionTypes.UPLOAD_WYSIWYG_IMAGE_ERROR,
	};
}
