import { addImageModalActionTypes } from './Constants';

export function handleAddImageModal(data) {
	return {
		type: addImageModalActionTypes.HANDLE_ADD_IMAGE_MODAL,
		payload: {
			data,
		},
	};
}

export function getImagesRequest(query) {
	return {
		type: addImageModalActionTypes.GET_IMAGES_REQUEST,
		error: true,
		payload: {
			query,
		},
	};
}

export function getImagesSuccess(images) {
	return {
		type: addImageModalActionTypes.GET_IMAGES_SUCCESS,
		payload: {
			images,
		},
	};
}

export function getImagesError() {
	return {
		type: addImageModalActionTypes.GET_IMAGES_ERROR,
	};
}
export function uploadImageRequest(file, itemId) {
	return {
		type: addImageModalActionTypes.UPLOAD_IMAGE_REQUEST,
		payload: {
			file,
			itemId,
		},
	};
}

export function uploadImageSuccess(publicLink, itemId) {
	return {
		type: addImageModalActionTypes.UPLOAD_IMAGE_SUCCESS,
		payload: {
			publicLink,
			itemId,
		},
	};
}

export function uploadImageError() {
	return {
		type: addImageModalActionTypes.UPLOAD_IMAGE_ERROR,
	};
}

export function clearAddImageModalState() {
	return {
		type: addImageModalActionTypes.CLEAR_ADD_IMAGE_MODAL_STATE,
	};
}
