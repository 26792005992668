import keyMirror from 'keymirror';

export const searchActionTypes = keyMirror({
	SEARCH_DETAILS_REQUEST: null,
	SEARCH_DETAILS_SUCCESS: null,
	SEARCH_DETAILS_ERROR: null,

	SET_SEARCH_DETAILS_VALUE: null,

	SET_APPLIED_FILTERS: null,

	CHANGE_FILTERS: null,

	CLEAR_RESULT_SECTIONS: null,

	CLEAR_FILTERS: null,

	UPDATA_SEARCH_GROUP_RESULTS: null,
});

export const DEFAULT_LIMIT_FOR_SEARCH = 5;
export const DEFAULT_LIMIT_FOR_SEARCH_BOOK = 3;
export const DEFAULT_LIMIT = 20;
export const DEFAULT_OFFSET = 0;

export const SEARCH_DISPLAY_TYPES = {
	LISTS: 'lists',
	BOOKS: 'books',
	PUBLISHER_METHODS: 'publisher_methods',
};

export const SCHOOL_TRACK_ID = 'school_track_id';
