import { reportActionTypes } from './Constants';

export function getPlaylistSectionReportRequest(id) {
	return {
		type: reportActionTypes.GET_PLAYLIST_SECTION_REPORT_REQUEST,
		payload: {
			id,
		},
	};
}

export function getPlaylistSectionReportSuccess(data) {
	return {
		type: reportActionTypes.GET_PLAYLIST_SECTION_REPORT_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getPlaylistSectionReportError(error) {
	return {
		type: reportActionTypes.GET_PLAYLIST_SECTION_REPORT_ERROR,
		error: true,
		payload: {
			error,
		},
	};
}

export function clearState() {
	return {
		type: reportActionTypes.CLEAR_STATE,
	};
}
