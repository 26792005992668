import { put, call, takeLatest, all } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import { dailyReportActionTypes } from './Constants';
import { identifyHotjar } from '../../services/Helper';
import * as dailyReportActions from './Actions';
import * as errorHandlerActions from '../errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(dailyReportActionTypes.GET_DAILY_REPORT_REQUEST, handleDailyReportRequest),
	]);
}

export function* handleDailyReportRequest({ payload }) {
	const { date } = payload || {};

	try {
		const { data: dailyReport } = yield call(
			processRequest,
			`users/daily_report`,
			'GET',
			date && { date },
		);
		yield put(dailyReportActions.getDailyReportSuccess(dailyReport));

		if (!date) {
			const { data: userData } = yield call(processRequest, 'get_user_data');
			identifyHotjar(userData, dailyReport);
		}
	} catch (e) {
		yield put(dailyReportActions.getDailyReportError());
		yield put(errorHandlerActions.handleError(e));
	}
}
