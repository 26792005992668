import { confirmationActionTypes } from './Constants';
import i18n from 'i18next';

const initialState = {
	loading: false,
	error: '',
	isOpenConfirmationModal: false,
	description: '',
	withPassword: false,
	submitModal: null,
	closeModal: null,
	title: '',
	submitBtnIcon: 'icon-trash-can',
	submitBtnTitle: i18n.t('general.remove'),
	classnames: '',
};

export default function confirmationReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data } = payload || {};

	switch (type) {
		case confirmationActionTypes.TOGGLE_CONFIRMATION_MODAL:
			return {
				...state,
				loading: false,
				...data,
			};
		case confirmationActionTypes.CLEAR_CONFIRMATION_MODAL_STATE:
			return initialState;

		default:
			return state;
	}
}
