import { manageTopicActionTypes } from './Constants';

export function toggleTopicManageModal({ open, topicId, subcategory }) {
	return {
		type: manageTopicActionTypes.TOGGLE_MANAGE_TOPIC_MODAL,
		payload: { open, topicId, subcategory },
	};
}

export function getTopicDataRequest(topicId) {
	return {
		type: manageTopicActionTypes.GET_ADMIN_TOPIC_DATA_REQUEST,
		payload: { topicId },
	};
}

export function getTopicDataSuccess(topicData) {
	return {
		type: manageTopicActionTypes.GET_ADMIN_TOPIC_DATA_SUCCESS,
		payload: { topicData },
	};
}

export function getTopicDataError() {
	return {
		type: manageTopicActionTypes.GET_ADMIN_TOPIC_DATA_ERROR,
	};
}

export function manageTopicRequest({ subcategoryId, topicId, name, callback }) {
	return {
		type: manageTopicActionTypes.MANAGE_ADMIN_TOPIC_REQUEST,
		payload: { subcategoryId, topicId, name, callback },
	};
}

export function manageTopicSuccess() {
	return {
		type: manageTopicActionTypes.MANAGE_ADMIN_TOPIC_SUCCESS,
	};
}

export function manageTopicError() {
	return {
		type: manageTopicActionTypes.MANAGE_ADMIN_TOPIC_ERROR,
	};
}

export function cleanManageTopicState() {
	return {
		type: manageTopicActionTypes.CLEAN_MANAGE_TOPIC_STATE,
	};
}
