import { parentShopTypes } from './Constants';

const initialState = {
	loading: false,
	levelsData: null,
	packagesData: null,
};

export default function parentShopReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data, error } = payload || {};

	switch (type) {
		case parentShopTypes.GET_LEVELS_REQUEST:
		case parentShopTypes.GET_PACKAGES_REQUEST:
			return {
				...state,
				loading: true,
			};

		case parentShopTypes.GET_LEVELS_SUCCESS:
			return {
				...state,
				levelsData: data,
				loading: false,
			};

		case parentShopTypes.GET_PACKAGES_SUCCESS:
			return {
				...state,
				packagesData: data,
				loading: false,
			};

		case parentShopTypes.GET_LEVELS_ERROR:
		case parentShopTypes.GET_PACKAGES_ERROR:
			return {
				...state,
				error,
				loading: false,
			};

		case parentShopTypes.CLEAN_PACKAGES:
			return {
				...state,
				loading: false,
				packagesData: null,
			};

		default:
			return state;
	}
}
