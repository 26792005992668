import i18n from 'i18next';
import { put, call, takeLatest, all } from 'redux-saga/effects';
import { managePlaylistActionTypes } from './Constants';
import { processRequest } from '../../../../services/Api';
import * as notificationActions from '../../../../components/notification/Actions';
import * as errorHandlerActions from '../../../../components/errorHandler/Actions';
import * as managePlaylistActions from './Actions';

export default function* () {
	yield all([
		yield takeLatest(
			managePlaylistActionTypes.GET_PLAYLIST_MODAL_REQUEST,
			handleGetPlaylistRequest,
		),
		yield takeLatest(
			managePlaylistActionTypes.MANAGE_PLAYLIST_MODAL_REQUEST,
			handleManagePlaylistRequest,
		),
	]);
}

export function* handleGetPlaylistRequest(action) {
	try {
		const { id } = action.payload;
		const { data } = yield call(processRequest, `admin/playlist_sections/${id}`);

		yield put(managePlaylistActions.getPlaylistSuccess(data.playlist_section));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(managePlaylistActions.getPlaylistError());
	}
}

export function* handleManagePlaylistRequest(action) {
	try {
		const { formData, playlistId, topicId, callback } = action.payload;

		if (playlistId) {
			yield call(processRequest, `admin/playlist_sections/${playlistId}`, 'PUT', {
				playlist_section: formData,
			});
		} else if (topicId) {
			formData.difficulty = 'easy';
			formData.topic_id = topicId;
			yield call(processRequest, 'admin/playlist_sections', 'POST', formData);
		}

		yield put(managePlaylistActions.managePlaylistSuccess());
		callback && callback();

		yield put(
			notificationActions.createNotification(
				playlistId
					? i18n.t('general.saved_changes')
					: i18n.t('admin_topics.add_question_set_notification'),
				null,
				false,
				3000,
			),
		);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(managePlaylistActions.managePlaylistError(e.response?.data?.error));
	}
}
