import { manageDifficultyActionTypes } from './Constants';

export function toggleManageDifficultyModal({ open, playlist }) {
	return {
		type: manageDifficultyActionTypes.TOGGLE_MANAGE_DIFFICULTY_MODAL,
		payload: {
			open,
			playlist,
		},
	};
}

export function manageDifficultyRequest({ playlistId, formData, callback }) {
	return {
		type: manageDifficultyActionTypes.MANAGE_DIFFICULTY_REQUEST,
		payload: {
			playlistId,
			formData,
			callback,
		},
	};
}

export function manageDifficultySuccess() {
	return {
		type: manageDifficultyActionTypes.MANAGE_DIFFICULTY_SUCCESS,
	};
}

export function manageDifficultyError(error) {
	return {
		type: manageDifficultyActionTypes.MANAGE_DIFFICULTY_ERROR,
		payload: {
			error,
		},
	};
}

export function cleanManageDifficultyState() {
	return {
		type: manageDifficultyActionTypes.CLEAN_MANAGE_DIFFICULTY_STATE,
	};
}
