import fromPairs from 'lodash/fromPairs';

export const getQueryString = (history) => {
	return history.location.search;
};

export const getQueryObject = (query) => {
	return fromPairs(
		query
			.substr(1)
			.split('&')
			.map((s) => s.split('=')),
	);
};

export const getUpdatedQuery = (queryObject) => {
	return Object.entries(queryObject).reduce((acc, [k, v]) => {
		acc = acc ? `${acc}&${k}=${v}` : `${k}=${v}`;
		return acc;
	}, '');
};
