import { playButtonActionTypes } from './Constants';

const initialState = {
	loading: false,
	paused: false,
	autoPlay: false,
	error: '',
	isAudioPlaying: false,
};

export default function playButtonReducer(state = initialState, action) {
	const { type, payload } = action;
	const { id, song, word, lang, shouldHighlight, paused, isAudioPlaying, isProcessing } =
		payload || {};

	switch (type) {
		case playButtonActionTypes.GET_SONG_REQUEST:
			return {
				...state,
				requestData: {
					word,
					lang,
				},
				shouldHighlight,
				loading: true,
			};
		case playButtonActionTypes.GET_SONG_SUCCESS:
			return {
				...state,
				songId: id,
				song: { ...song },
				loading: false,
			};
		case playButtonActionTypes.REMOVE_SONG:
			return {
				...state,
				songId: null,
				song: null,
				requestData: null,
				paused: false,
				loading: false,
			};
		case playButtonActionTypes.PAUSE_SONG:
			return {
				...state,
				paused,
			};

		case playButtonActionTypes.IS_AUDIO_PLAYING:
			return {
				...state,
				isAudioPlaying,
				loading: isProcessing,
			};

		case playButtonActionTypes.SET_SONG_ID:
			return {
				...state,
				songId: id,
			};

		default:
			return state;
	}
}
