import { put, call, takeLatest, all } from 'redux-saga/effects';
import { processRequest } from '../../../../services/Api';
import { manageTopicActionTypes } from './Constants';
import * as manageTopicActions from './Actions';
import * as errorHandlerActions from '../../../../components/errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			manageTopicActionTypes.GET_ADMIN_TOPIC_DATA_REQUEST,
			handleGetTopicDataRequest,
		),
		yield takeLatest(
			manageTopicActionTypes.MANAGE_ADMIN_TOPIC_REQUEST,
			handleManageTopicRequest,
		),
	]);
}

export function* handleGetTopicDataRequest({ payload }) {
	try {
		const { topicId } = payload || {};

		const { data } = yield call(processRequest, `admin/topics/${topicId}`);

		yield put(manageTopicActions.getTopicDataSuccess(data.topic));
	} catch (e) {
		yield put(manageTopicActions.getTopicDataError());
	}
}

export function* handleManageTopicRequest({ payload }) {
	try {
		const { subcategoryId, topicId, name: title, callback } = payload || {};

		if (topicId) {
			yield call(processRequest, `admin/topics/${topicId}`, 'PUT', {
				topic: { title },
			});
		} else {
			yield call(processRequest, 'admin/topics', 'POST', {
				topic: {
					title,
					topic_subcategory_id: subcategoryId,
				},
			});
		}

		callback && callback();

		yield put(manageTopicActions.manageTopicSuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(manageTopicActions.manageTopicError());
	}
}
