import keyMirror from 'keymirror';

export const switchPackageModalActionTypes = keyMirror({
	TOGGLE_SWITCH_PACKAGE_MODAL: null,

	GET_PACKAGE_DETAILS_REQUEST: null,
	GET_PACKAGE_DETAILS_SUCCESS: null,
	GET_PACKAGE_DETAILS_ERROR: null,

	UPGRADE_PACKAGE_REQUEST: null,
	UPGRADE_PACKAGE_SUCCESS: null,
	UPGRADE_PACKAGE_ERROR: null,

	CLEAR_SWITCH_PACKAGE_MODAL_STATE: null,
});
