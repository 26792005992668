import { signUpActionTypes } from './Constants';

export function signUpRequest(data, recaptchaToken) {
	return {
		type: signUpActionTypes.SIGN_UP_REQUEST,
		payload: {
			data,
			recaptchaToken,
		},
	};
}

export function signUpSuccess(data) {
	return {
		type: signUpActionTypes.SIGN_UP_SUCCESS,
		payload: {
			data,
		},
	};
}

export function signUpRerun(data) {
	return {
		type: signUpActionTypes.SIGN_UP_RERUN,
		payload: {
			data,
		},
	};
}

export function signUpError() {
	return {
		type: signUpActionTypes.SIGN_UP_ERROR,
	};
}

export function saveEducationDetailsRequest(data, page = '') {
	return {
		type: signUpActionTypes.SAVE_EDUCATION_DETAILS_REQUEST,
		payload: {
			data,
			page,
		},
	};
}

export function saveEducationDetailsSuccess(data) {
	return {
		type: signUpActionTypes.SAVE_EDUCATION_DETAILS_SUCCESS,
		payload: {
			data,
		},
	};
}

export function saveEducationDetailsError() {
	return {
		type: signUpActionTypes.SAVE_EDUCATION_DETAILS_ERROR,
	};
}

export function checkEmailIfExistRequest(email) {
	return {
		type: signUpActionTypes.CHECK_EMAIL_IF_EXIST_REQUEST,
		payload: { email },
	};
}

export function checkEmailIfExistSuccess(data) {
	return {
		type: signUpActionTypes.CHECK_EMAIL_IF_EXIST_SUCCESS,
		payload: {
			data,
		},
	};
}

export function checkEmailIfExistError(displayTeacherWarning) {
	return {
		type: signUpActionTypes.CHECK_EMAIL_IF_EXIST_ERROR,
		payload: {
			displayTeacherWarning,
		},
	};
}

export function setAge(data) {
	return {
		type: signUpActionTypes.SET_AGE,
		payload: {
			data,
		},
	};
}

export function setInitialValues(data) {
	return {
		type: signUpActionTypes.SET_INITIAL_VALUES,
		payload: {
			data,
		},
	};
}

export function setSocialLogin(data) {
	return {
		type: signUpActionTypes.SET_IS_SOCIAL_LOGIN,
		payload: {
			data,
		},
	};
}

export function getEducationTypeRequest(data) {
	return {
		type: signUpActionTypes.GET_EDUCATION_TYPE_REQUEST,
		payload: data,
	};
}

export function getEducationTypeSuccess(data) {
	return {
		type: signUpActionTypes.GET_EDUCATION_TYPE_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getEducationTypeError() {
	return {
		type: signUpActionTypes.GET_EDUCATION_TYPE_ERROR,
	};
}

export function getSchoolsRequest(data) {
	return {
		type: signUpActionTypes.GET_SCHOOLS_REQUEST,
		payload: data,
	};
}

export function getSchoolsSuccess(data) {
	return {
		type: signUpActionTypes.GET_SCHOOLS_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getSchoolsError() {
	return {
		type: signUpActionTypes.GET_SCHOOLS_ERROR,
	};
}

export function setInitialEducationInfo(data) {
	return {
		type: signUpActionTypes.SET_EDUCATION_INITIAL_INFO,
		payload: { data },
	};
}

export function setIsTeacher(data) {
	return {
		type: signUpActionTypes.SET_IS_TEACHER,
		payload: { data },
	};
}

export function clearEducationFormField() {
	return {
		type: signUpActionTypes.CLEAR_EDUCATION_FORM_FIELD,
		payload: {},
	};
}

export function cleanSignUpState() {
	return {
		type: signUpActionTypes.CLEAN_SIGN_UP_STATE,
	};
}

export function setLastPageSlug(lastPageSlug) {
	return {
		type: signUpActionTypes.SET_LAST_PAGE_SLUG,
		payload: {
			lastPageSlug,
		},
	};
}

export function getMotivationsDataRequest() {
	return {
		type: signUpActionTypes.GET_MOTIVATION_DATA_REQUEST,
	};
}

export function getMotivationsDataSuccess(motivationData) {
	return {
		type: signUpActionTypes.GET_MOTIVATION_DATA_SUCCESS,
		payload: {
			motivationData,
		},
	};
}

export function getMotivationsDataError() {
	return {
		type: signUpActionTypes.GET_MOTIVATION_DATA_ERROR,
	};
}

export function selectMotivation(motivationId) {
	return {
		type: signUpActionTypes.SELECT_MOTIVATION,
		payload: {
			motivationId,
		},
	};
}

export function downgradeTeacher() {
	return {
		type: signUpActionTypes.DOWNGRADE_TEACHER,
	};
}

export function resendEmailVerification(userId, email) {
	return {
		type: signUpActionTypes.RESEND_EMAIL_VERIFICATION_REQUEST,
		payload: {
			userId,
			email,
		},
	};
}

export function resendEmailVerificationSuccess() {
	return {
		type: signUpActionTypes.RESEND_EMAIL_VERIFICATION_SUCCESS,
	};
}

export function resendEmailVerificationError() {
	return {
		type: signUpActionTypes.RESEND_EMAIL_VERIFICATION_ERROR,
	};
}

export function confirmEmailRequest(accountId, confirmationToken) {
	return {
		type: signUpActionTypes.CONFIRM_EMAIL_REQUEST,
		payload: {
			accountId,
			confirmationToken,
		},
	};
}

export function confirmEmailSuccess() {
	return {
		type: signUpActionTypes.CONFIRM_EMAIL_SUCCESS,
	};
}

export function confirmEmailError() {
	return {
		type: signUpActionTypes.CONFIRM_EMAIL_ERROR,
	};
}
