import keyMirror from 'keymirror';

export const manageSubjectActionTypes = keyMirror({
	TOGGLE_MANAGE_SUBJECT_MODAL: null,
	GET_SUBJECT_DATA_REQUEST: null,
	GET_SUBJECT_DATA_SUCCESS: null,
	GET_SUBJECT_DATA_ERROR: null,
	MANAGE_SUBJECT_REQUEST: null,
	MANAGE_SUBJECT_SUCCESS: null,
	MANAGE_SUBJECT_ERROR: null,
	CLEAN_MANAGE_SUBJECT_STATE: null,
});
