import { imagePreviewActionTypes } from './Constants';

const initialState = {
	open: false,
	imageSrc: null,
};

export default function imagePreviewReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data } = payload || {};

	switch (type) {
		case imagePreviewActionTypes.SET_IMAGE_PREVIEW:
			return {
				...state,
				...data,
			};
		default:
			return state;
	}
}
