import { processRequest } from '../../../services/Api';
import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import { adminActivationCodesActionTypes } from './Constants';
import * as activationCodesActions from './Actions';
import * as errorHandlerActions from '../../../components/errorHandler/Actions';
import { activationCodesData } from './Selectors';

export default function* () {
	yield all([
		yield takeLatest(
			adminActivationCodesActionTypes.GET_ACTIVATION_CODES_REQUEST,
			handleGetActivationCodesRequest,
		),
		yield takeLatest(
			adminActivationCodesActionTypes.GET_ACTIVATION_CODE_INFO_REQUEST,
			handleGetActivationCodeDetailsRequest,
		),
		yield takeLatest(
			adminActivationCodesActionTypes.CHANGE_ACTIVATION_CODE_STATUS_REQUEST,
			handleChangeActivationCodeStateRequest,
		),
	]);
}

export function* handleGetActivationCodesRequest({ payload }) {
	try {
		const { data: queries } = payload || {};

		const { data } = yield call(processRequest, 'admin/activation_codes', 'GET', queries);

		const formattedData = { ...data };

		if (queries && queries.offset) {
			const codes = yield select(activationCodesData);
			formattedData.results = [...codes.results, ...formattedData.results];
		}

		yield put(activationCodesActions.getActivationCodesSuccess(formattedData));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(activationCodesActions.getActivationCodesError());
	}
}

export function* handleGetActivationCodeDetailsRequest({ payload }) {
	try {
		const { id } = payload;
		const { data } = yield call(processRequest, `admin/activation_codes/${id}`);

		yield put(activationCodesActions.getActivationCodeInfoSuccess(data.activation_code));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(activationCodesActions.getActivationCodeInfoError());
	}
}

export function* handleChangeActivationCodeStateRequest({ payload }) {
	try {
		const { id, state, reserve, source, history } = payload;

		const toRemove = state === 'remove';
		const requestData = !toRemove ? { activation_code: { reserved: reserve } } : null;

		const { data } = yield call(
			processRequest,
			`admin/activation_codes/${id}`,
			toRemove ? 'DELETE' : 'PATCH',
			requestData,
		);

		let newData;

		if (source === 'list') {
			const codesData = yield select(activationCodesData);
			newData = {
				...codesData,
				results: toRemove
					? codesData.results.filter((item) => item.id !== id)
					: codesData.results.map((item) =>
						item.id === id
							? { ...item, status: data.activation_code.status }
							: item,
					  ),
			};
		} else if (!toRemove) {
			newData = data.activation_code;
		}

		if (toRemove && source === 'details') {
			history.goBack();
		} else {
			yield put(activationCodesActions.changeActivationCodeStateSuccess(newData, source));
		}
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(activationCodesActions.changeActivationCodeStateError());
	}
}
