import { addWysiwygVideoModalActionTypes } from './Constants';

const initialState = {
	open: false,
	addEmbedBlock: () => {},
};

export default function addWysiwygVideoModalReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data } = payload || {};

	switch (type) {
		case addWysiwygVideoModalActionTypes.HANDLE_ADD_WYSIWYG_VIDEO_MODAL:
			return {
				...state,
				...data,
			};
		default:
			return state;
	}
}
