import keyMirror from 'keymirror';

export const reportActionTypes = keyMirror({
	GET_PLAYLIST_SECTION_REPORT_REQUEST: null,
	GET_PLAYLIST_SECTION_REPORT_SUCCESS: null,
	GET_PLAYLIST_SECTION_REPORT_ERROR: null,

	CLEAR_STATE: null,
});

export const questionTypeIconCodes = {
	'Question::OpenEnded': 'icon-writing',
	'Question::Dictation': 'icon-hearing',
	'Question::InYourMind': 'icon-cloud',
	'Question::MultipleChoiceSingleAnswer': 'icon-list-bulleted',
	'Question::FillInTheBlanks': 'icon-fill',
	'Question::OpenQuestionMultipleAnswers': 'icon-writing',
	'Question::DictationMultipleChoice': 'icon-hearing-multiple',
	'Question::MultipleChoiceMultipleAnswers': 'icon-list-bulleted',
};

export const questionTypeNameCodes = {
	'Question::OpenEnded': 'open',
	'Question::Dictation': 'dictation',
	'Question::InYourMind': 'in_your_mind',
	'Question::MultipleChoiceSingleAnswer': 'multiple_choice',
	'Question::FillInTheBlanks': 'fill_in_the_blanks',
	'Question::OpenQuestionMultipleAnswers': 'open',
	'Question::DictationMultipleChoice': 'dictation_multiple',
	'Question::MultipleChoiceMultipleAnswers': 'multiple_choice',
};

export const getReportQuestionPath = (sectionId, questionId) => {
	return `/activiteit/${sectionId}/${questionId}`;
};

export const getReportSectionPath = (sectionId) => {
	return `/activiteit/${sectionId}`;
};
