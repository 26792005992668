import { processRequest } from '../../../services/Api';
import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import { addImageModalActionTypes } from './Constants';
import * as addImageActions from './Actions';
import * as errorHandlerActions from '../../errorHandler/Actions';
import { getUploadType } from './Selectors';

export default function* () {
	yield all([
		yield takeLatest(addImageModalActionTypes.GET_IMAGES_REQUEST, handleImagesRequest),
		yield takeLatest(addImageModalActionTypes.UPLOAD_IMAGE_REQUEST, handleUploadImageRequest),
	]);
}

export function* handleImagesRequest(action) {
	try {
		const { query } = action.payload;
		const { data } = yield call(
			processRequest,
			`external_services/pixabay_images?term=${query}`,
		);

		yield put(addImageActions.getImagesSuccess(data));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(addImageActions.getImagesError());
	}
}

export function* handleUploadImageRequest(action) {
	try {
		const { file, itemId } = action.payload;
		const type = yield select(getUploadType);

		const { data } = yield call(processRequest, `${type}/presigned_image_url`);
		const presignedUrl = data.signed_url;

		yield call(processRequest, presignedUrl, 'PUT', file, true);

		const linkToFile = presignedUrl.split('?')[0];

		yield put(addImageActions.uploadImageSuccess(linkToFile, itemId));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(addImageActions.uploadImageError());
	}
}
