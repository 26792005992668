import { stickersOnboardingActionTypes } from './Constants';

const initialState = {
	loading: false,
};

export default function stickersOnboardingReducer(state = initialState, action) {
	const { type, payload } = action;

	const { data } = payload || {};

	switch (type) {
		case stickersOnboardingActionTypes.SEND_STICKERS_ONBOARDED_REQUEST:
			return {
				...state,
				loading: true,
			};

		default:
			return state;
	}
}
