import { put, call, takeLatest, all } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import { historyReplace } from '../../services/History';
import { forgotPasswordActionTypes } from './Constants';
import * as forgotPasswordActions from './Actions';
import * as notificationActions from '../../components/notification/Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			forgotPasswordActionTypes.FORGOT_PASSWORD_REQUEST,
			handleForgotPasswordRequest,
		),
	]);
}

export function* handleForgotPasswordRequest(action) {
	try {
		const { email } = action.payload;

		const { data } = yield call(processRequest, 'password/request_reset', 'POST', { email });
		yield put(forgotPasswordActions.forgotPasswordSuccess());
		yield call(historyReplace, '/inloggen');
		yield put(notificationActions.createNotification(data.info, null, false, 5000));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(forgotPasswordActions.forgotPasswordError());
	}
}
