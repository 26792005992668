import { STATUSES, STEP_SUBCATEGORIES, STEPS, SUBJECT_TYPES } from './Constants';

export const getGoalSettingsSteps = (subjects = []) => {
	if (!subjects.some((subject) => subject.selected)) return STEPS;
	const position = STEPS.findIndex((step) => step === STEP_SUBCATEGORIES);
	if (position < 0) return STEPS;
	const categories = subjects
		.filter((subject) => subject.selected)
		.map((subject) => `${STEP_SUBCATEGORIES}-${subject.id}`);
	const newSteps = [...STEPS];
	newSteps.splice(position, 1, ...categories);
	return newSteps;
};

export const setSelectedDuration = (selectedDuration, durations) => {
	return durations.map((duration) => {
		duration.is_selected = duration.id === selectedDuration.id;
		return duration;
	});
};

export const setSelectedSubject = (selectedSubject, selected, subjects) => {
	if (selected) {
		return subjects.map((subject) => {
			if (subject.id === selectedSubject.id) {
				subject.selected = true;
			}
			return subject;
		});
	} else {
		return subjects.map((subject) => {
			if (subject.id === selectedSubject.id) {
				subject.selected = false;
				subject.topic_categories = subject.topic_categories.map((category) => {
					category.selected = false;
					category.partially_selected = false;
					category.topic_subcategories = category.topic_subcategories.map(
						(subcategory) => {
							subcategory.selected = false;
							return subcategory;
						},
					);
					return category;
				});
			}
			return subject;
		});
	}
};

export const setSelectedCategory = (subjectId, categoryId, selected, subjects) => {
	return subjects.map((subject) => {
		if (subject.id === subjectId) {
			subject.topic_categories =
				subject.type === SUBJECT_TYPES.practice
					? selectPracticeCategory(categoryId, selected, subject.topic_categories)
					: selectExamCategory(categoryId, selected, subject.topic_categories);
		}
		return subject;
	});
};

export const selectPracticeCategory = (categoryId, selected, categories) => {
	return categories.map((category) => {
		if (category.id === categoryId && category.status === STATUSES.ACTIVE) {
			category.selected = selected;
			category.partially_selected = false;
			category.topic_subcategories = category.topic_subcategories.map((subcategory) => {
				if (subcategory.status === STATUSES.ACTIVE) {
					subcategory.selected = selected;
				}
				return subcategory;
			});
		}
		return category;
	});
};

export const selectExamCategory = (categoryId, selected, categories) => {
	return categories.map((category) => {
		const isSelectedCategory = category.id === categoryId;
		category.selected = isSelectedCategory && selected;
		category.partially_selected = false;
		category.topic_subcategories = category.topic_subcategories.map((subcategory) => {
			subcategory.selected = isSelectedCategory && selected;
			return subcategory;
		});
		return category;
	});
};

export const setSelectedSubcategory = (
	subjectId,
	categoryId,
	subcategoryId,
	selected,
	subjects,
) => {
	return subjects.map((subject) => {
		if (subject.id === subjectId) {
			subject.topic_categories =
				subject.type === SUBJECT_TYPES.practice
					? selectPracticeSubcategory(
							categoryId,
							subcategoryId,
							selected,
							subject.topic_categories,
					  )
					: selectExamSubcategory(
							categoryId,
							subcategoryId,
							selected,
							subject.topic_categories,
					  );
		}
		return subject;
	});
};

export const selectPracticeSubcategory = (categoryId, subcategoryId, selected, categories) => {
	return categories.map((category) => {
		if (category.id === categoryId) {
			let selectedCount = 0;
			category.topic_subcategories = category.topic_subcategories.map((subcategory) => {
				if (
					subcategory.id === subcategoryId &&
					[STATUSES.ACTIVE, STATUSES.CHALLENGE].includes(subcategory.status)
				) {
					subcategory.selected = selected;
				}
				if (subcategory.selected) {
					selectedCount++;
				}
				return subcategory;
			});
			if (selectedCount === category.topic_subcategories.length) {
				category.selected = true;
				category.partially_selected = false;
			} else if (selectedCount > 0) {
				category.selected = true;
				category.partially_selected = true;
			} else {
				category.selected = false;
				category.partially_selected = false;
			}
		}
		return category;
	});
};

export const selectExamSubcategory = (categoryId, subcategoryId, selected, categories) => {
	return categories.map((category) => {
		const isSelectedCategory = category.id === categoryId;

		if (isSelectedCategory) {
			let selectedCount = 0;
			category.selected = isSelectedCategory;
			category.topic_subcategories = category.topic_subcategories.map((subcategory) => {
				if (subcategory.id === subcategoryId) {
					subcategory.selected = isSelectedCategory && selected;
				}
				if (subcategory.selected) {
					selectedCount++;
				}
				return subcategory;
			});
			if (selectedCount === category.topic_subcategories.length) {
				category.selected = true;
				category.partially_selected = false;
			} else if (selectedCount > 0) {
				category.selected = true;
				category.partially_selected = true;
			} else {
				category.selected = false;
				category.partially_selected = false;
			}
		} else {
			category.selected = false;
			category.topic_subcategories = category.topic_subcategories.map((subcategory) => {
				subcategory.selected = false;
				return subcategory;
			});
		}

		return category;
	});
};

export const generateGoalSettingsPayload = (subjects) => {
	const userGoalContents = [];
	subjects.forEach((subject) => {
		const { topic_categories: categories } = subject;
		categories.forEach((category) => {
			const {
				topic_subcategories: subcategories,
				selected,
				partially_selected,
				status,
			} = category;
			if (partially_selected) {
				subcategories.forEach((subcategory) => {
					if (
						subcategory.selected &&
						[STATUSES.ACTIVE, STATUSES.CHALLENGE].includes(subcategory.status)
					) {
						userGoalContents.push({
							topic_category_id: category.id,
							topic_subcategory_id: subcategory.id,
						});
					}
				});
			} else if (selected && [STATUSES.ACTIVE, STATUSES.CHALLENGE].includes(status)) {
				userGoalContents.push({
					topic_category_id: category.id,
				});
			}
		});
	});

	return {
		user_goal: {
			user_goal_contents_attributes: userGoalContents,
		},
	};
};

export const getTotalProgress = (subjects) => {
	const selectedSubjectsLength = subjects.filter((subject) => subject.selected).length;
	return selectedSubjectsLength ? STEPS.length - 2 + selectedSubjectsLength : STEPS.length;
};

export const getSubcategoriesByPopularType = (subject, popularType) => {
	return subject.topic_categories
		.reduce((acc, topic_category) => {
			const result = topic_category.topic_subcategories.reduce((acc, topic_subcategory) => {
				if (
					topic_subcategory.most_popular &&
					topic_subcategory.most_popular_type === popularType
				) {
					topic_subcategory.subjectId = subject.id;
					topic_subcategory.categoryId = topic_category.id;
					acc.push(topic_subcategory);
				}
				return acc;
			}, []);
			return acc.concat(result);
		}, [])
		.sort((a, b) => a.most_popular_position - b.most_popular_position);
};

export const setExtraProperties = (subjects) => {
	return subjects.map((subject) => {
		subject.topic_categories = subject.topic_categories.map((category) => {
			category.topic_subcategories = category.topic_subcategories.map((subcategory) => {
				subcategory.subjectId = subject.id;
				subcategory.categoryId = category.id;
				return subcategory;
			});
			return category;
		});
		return subject;
	});
};

export const getSelectedNotPopularSubcategoriesLength = (subject) => {
	return subject.topic_categories.reduce((acc, topic_category) => {
		const result = topic_category.topic_subcategories.reduce((acc, topic_subcategory) => {
			if (!topic_subcategory.most_popular && topic_subcategory.selected) {
				acc++;
			}
			return acc;
		}, 0);
		return acc + result;
	}, 0);
};
