import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import { reportActionTypes } from './Constants';
import { getPlayListSections } from './Selectors';
import * as reportActions from './Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			reportActionTypes.GET_PLAYLIST_SECTION_REPORT_REQUEST,
			handlePlaylistSectionReportRequest,
		),
	]);
}

export function* handlePlaylistSectionReportRequest({ payload }) {
	const { id } = payload || {};
	const playListSections = yield select(getPlayListSections);

	if (playListSections[id]) {
		yield put(reportActions.getPlaylistSectionReportSuccess(playListSections[id]));
		return;
	}

	try {
		const { data } = yield call(processRequest, `user_learning_path_sections/${id}`, 'GET', {
			preview: true,
		});

		yield put(reportActions.getPlaylistSectionReportSuccess(data?.user_learning_path_section));
	} catch (e) {
		yield put(reportActions.getPlaylistSectionReportError(e));
		yield put(errorHandlerActions.handleError(e));
	}
}
