import keyMirror from 'keymirror';

export const createActivationCodeActionTypes = keyMirror({
	TOGGLE_CREATE_ACTIVATION_CODE_MODAL: null,
	GET_ADMIN_PACKAGES: null,
	GET_ADMIN_PACKAGES_SUCCESS: null,
	GET_ADMIN_PACKAGES_ERROR: null,
	CREATE_ACTIVATION_CODE: null,
	CREATE_ACTIVATION_CODE_SUCCESS: null,
	CREATE_ACTIVATION_CODE_ERROR: null,
	CLEAN_ACTIVATION_CODE_MODAL_STATE: null,
});

export const CREATE_ACTIVATION_CODE_FORM = 'CreateActivationCodeForm';
