import { managePlaylistActionTypes } from './Constants';
import { change } from 'redux-form';

export function toggleManagePlaylistModal({ open, playlistId, topic, callback }) {
	return {
		type: managePlaylistActionTypes.TOGGLE_MANAGE_PLAYLIST_MODAL,
		payload: {
			open,
			playlistId,
			topic,
			callback,
		},
	};
}

export function toggleMovePlaylistToTopicModal({ open, playlistId, topic, callback }) {
	return {
		type: managePlaylistActionTypes.TOGGLE_MOVE_PLAYLIST_TO_TOPIC_MODAL,
		payload: {
			open,
			playlistId,
			callback,
		},
	};
}

export function getPlaylistRequest(id) {
	return {
		type: managePlaylistActionTypes.GET_PLAYLIST_MODAL_REQUEST,
		payload: { id },
	};
}

export function getPlaylistSuccess(playlist) {
	return {
		type: managePlaylistActionTypes.GET_PLAYLIST_MODAL_SUCCESS,
		payload: {
			playlist,
		},
	};
}

export function getPlaylistError(error) {
	return {
		type: managePlaylistActionTypes.GET_PLAYLIST_MODAL_ERROR,
		payload: {
			error,
		},
	};
}

export function managePlaylistRequest({ formData, playlistId, topicId, callback }) {
	return {
		type: managePlaylistActionTypes.MANAGE_PLAYLIST_MODAL_REQUEST,
		payload: {
			formData,
			playlistId,
			topicId,
			callback,
		},
	};
}

export function managePlaylistSuccess() {
	return {
		type: managePlaylistActionTypes.MANAGE_PLAYLIST_MODAL_SUCCESS,
	};
}

export function managePlaylistError(error) {
	return {
		type: managePlaylistActionTypes.MANAGE_PLAYLIST_MODAL_ERROR,
		payload: {
			error,
		},
	};
}

export function cleanManagePlaylistState() {
	return {
		type: managePlaylistActionTypes.CLEAN_MANAGE_PLAYLIST_MODAL_STATE,
	};
}

export function changeFormField(formName, fieldName, value) {
	return change(formName, fieldName, value);
}
