import Cookies from 'js-cookie';
import _ from 'lodash';
import fromPairs from 'lodash/fromPairs';
import i18n from 'i18next';
import moment from 'moment';
import React from 'react';
import Swiper from 'swiper';
import katex from 'katex';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { isAuthenticated } from './AccessToken';
import {
	UTM_TAGS_TTL_DAYS,
	IS_MOBILE_APP,
	COOKIES,
	AVAILABLE_LANGUAGES,
	IS_INTERNATIONAL_TYPE,
	IS_ADMIN,
	FRONTEND_URL,
	GTM_CONTAINER_ID,
	GA4_TAG_ID,
	BUGSNAG_API_KEY,
	REACT_APP_BUILD_NUMBER,
	APP_ENVIRONMENT,
} from './Constants';
import configData from '../config.json';

export function Timer(callback, delay) {
	let timerId;
	let start;
	let remaining = delay;

	this.pause = () => {
		window.clearTimeout(timerId);
		remaining -= new Date() - start;
	};

	this.resume = () => {
		start = new Date();
		window.clearTimeout(timerId);
		timerId = window.setTimeout(callback, remaining);
	};

	this.resume();
}

export const isSecure = () => {
	return window.location.protocol === 'https:';
};

export const getIsSafari = () => {
	const userAgent = navigator.userAgent.toLowerCase();
	const isChrome = userAgent.indexOf('chrome') > -1;
	let isSafari = userAgent.indexOf('safari') > -1;
	if (isChrome && isSafari) isSafari = false;
	return isSafari;
};

export const getUtmTagsLikeObject = (string) => {
	return fromPairs(
		string.split('&').reduce((acc, q) => {
			if (q.indexOf('utm_') !== -1) acc.push(q.split('='));
			return acc;
		}, []),
	);
};

export const setUtmTags = (query) => {
	const queryString = query.substr(1);
	const utmTags = getUtmTagsLikeObject(queryString);

	// eslint-disable-next-line guard-for-in
	for (const tag in utmTags) {
		setCookie(tag, utmTags[tag], {
			expires: UTM_TAGS_TTL_DAYS,
			secure: isSecure(),
		});
	}
};

export const getUtmTags = () => {
	return Object.entries(Cookies.get())
		.reduce((acc, [key, value]) => {
			if (key.indexOf('utm_') !== -1) acc.push(`${key}=${value}`);
			return acc;
		}, [])
		.join('&');
};

export const getVWOExperimentVersions = () => {
	return Object.entries(Cookies.get()).reduce((acc, [key, value]) => {
		if (key.startsWith('_vis_opt_exp_') && key.endsWith('_combi')) {
			const experiment_id = key.replace('_vis_opt_exp_', '').replace('_combi', '');
			acc.push({ experiment_id: experiment_id, variation: value });
		}
		return acc;
	}, []);
};

export const removeUtmTags = (utmTagsObject) => {
	// eslint-disable-next-line guard-for-in
	for (const tag in utmTagsObject) {
		removeCookie(tag);
	}
};

export const setLastPageSlug = (lastPageSlug) => {
	setCookie(COOKIES.lastPageSlug, lastPageSlug, {
		expires: UTM_TAGS_TTL_DAYS,
		secure: isSecure(),
	});
};

export const deleteCharactersInString = (string, character) => {
	return string
		.split('')
		.filter((item) => item !== character)
		.join('');
};

export const nameInUrlFormat = (name) => {
	let convertedUrl = name;
	if (name) {
		if (name.includes('%')) {
			convertedUrl = deleteCharactersInString(name, '%');
		}

		if (name.includes('#')) {
			convertedUrl = deleteCharactersInString(convertedUrl, '#');
		}

		return convertedUrl
			.trim()
			.split(' ')
			.join('-')
			.split('/')
			.join('-')
			.split('.')
			.join('')
			.split('?')
			.join('')
			.toLowerCase();
	}
	return name;
};

let clientVersion = null;
let clientBuildNumber = null;

export const setClientVersion = (_clientVersion) => {
	clientVersion = _clientVersion;
};

export const setClientBuildNumber = (_clientBuildNumber) => {
	clientBuildNumber = _clientBuildNumber;
};

export const getClientVersion = () => {
	return clientVersion;
};

export const getClientBuildNumber = () => {
	return clientBuildNumber;
};

function syncCookiesInMobile() {
	if (IS_MOBILE_APP) window.cordova.plugins.CookieManagementPlugin.flush();
}

export const getCookie = (key) => {
	if (isAuthenticationCookie(key)) {
		const cookie = Cookies.get(key + configData.authentication_cookies_suffix);
		if (cookie) return cookie;
	}

	return Cookies.get(key);
};

function isAuthenticationCookie(key) {
	const authenticationCookieKeys = ['accessToken', 'temporaryToken', 'renewTime', 'loggedIn'];
	return _(COOKIES).pick(authenticationCookieKeys).values().value().includes(key);
}

export const setCookie = (key, val, options = {}) => {
	if (isAuthenticationCookie(key)) {
		key = key + configData.authentication_cookies_suffix;
		if (configData.authentication_cookies_domain) {
			options.domain = configData.authentication_cookies_domain;
		}
	}

	Cookies.set(key, val, options);
	syncCookiesInMobile();
};

export const removeCookie = (key, options = {}) => {
	Cookies.remove(key, options);
	if (isAuthenticationCookie(key)) {
		const keySuffix = configData.authentication_cookies_suffix;
		if (configData.authentication_cookies_domain) {
			options.domain = configData.authentication_cookies_domain;
		}
		Cookies.remove(key + keySuffix, options);
	}
	syncCookiesInMobile();
};

export const createSwipe = () => {
	// eslint-disable-next-line no-new
	new Swiper('.swiper-container', {
		slidesPerView: 'auto',
		freeMode: true,
		roundLengths: true,
		watchOverflow: true,
	});
};

export const getTimeRange = (created_at) => {
	const posted = new Date() - new Date(created_at);

	const years = Math.round(posted / 31557600000);
	const months = Math.round(posted / 2629800000);
	const weeks = Math.round(posted / 604800000);
	const days = Math.round(posted / 86400000);
	const hours = Math.round(posted / 3600000);
	const minutes = Math.round(posted / 60000);

	if (years >= 1) {
		return `${years} ${i18n.t('general.year')} ${i18n.t('general.past')}`;
	}
	if (months >= 1) {
		return `${months} ${i18n.t('general.month', { count: months })} ${i18n.t('general.past')}`;
	}
	if (weeks >= 1) {
		return `${weeks} ${i18n.t('general.week', { count: weeks })} ${i18n.t('general.past')}`;
	}
	if (days >= 1) {
		return `${days} ${i18n.t('general.day', { count: days })} ${i18n.t('general.past')}`;
	}
	if (hours >= 1) {
		return `${hours} ${i18n.t('general.hour')} ${i18n.t('general.past')}`;
	}

	return minutes <= 1
		? i18n.t('general.just_now')
		: `${minutes} ${i18n.t('general.minutes')} ${i18n.t('general.past')}`;
};

export const handleRatingPlugin = () => {
	window.AppRate.setPreferences({
		usesUntilPrompt: 5,
		reviewType: {
			ios: 'InAppReview',
			android: 'InAppReview',
		},
		storeAppURL: {
			ios: '1191740855',
			android: 'market://details?id=nl.wrts.mobiel',
		},
		showPromptForInAppReview: false,
	});
};

export const isBootAgent = () =>
	window.navigator.userAgent.toLowerCase().includes('headlesschrome');

export const validateVimeoVideoId = (e, setVideoId) => {
	const val = e.target ? e.target.value : e;

	if (val && val.trim()) {
		if (val.includes('vimeo.com')) {
			const valArray = val.split('/');
			const valId = valArray[valArray.length - 1];

			if (valId && valId.match(/^\d{8}/)) {
				setVideoId(valArray[valArray.length - 1]);
				return null;
			}
			return i18n.t('general.invalid_value');
		}
		if (!val.match(/^\d{8}/)) {
			return i18n.t('general.invalid_value');
		}
	}
	return null;
};

export const setLocalStorageItem = (key, value) => {
	window.localStorage.setItem(key, value);
};
export const getLocalStorageItem = (key) => window.localStorage.getItem(key);

export const removeLocalStorageItem = (key) => {
	window.localStorage.removeItem(key);
};

export const setSessionStorageItem = (key, value) => {
	window.sessionStorage.setItem(key, value);
};
export const getSessionStorageItem = (key) => window.sessionStorage.getItem(key);

export const handleMarkdown = (Tag, props, children) => {
	if (Tag === 'em') {
		return <span className="green">{children}</span>;
	}

	return <Tag {...props}>{children}</Tag>;
};

export const convertImageURLToBlob = async (imageUrl) => {
	const response = await fetch(imageUrl);
	return response.blob();
};

export const formatDate = (date) => moment(date).format('DD-MM-YYYY');

export const renderFormulas = (content, isEditor) => {
	let formattedContent = content || '';
	if (typeof content === 'object') formattedContent = JSON.stringify(content);

	if (!isEditor) {
		formattedContent = wrapVideoIframes(formattedContent);
	}

	if (formattedContent.search(/\$(.*?)\$/g) < 0) {
		return disableContentEditableElements(formattedContent);
	}

	return disableContentEditableElements(formattedContent).replace(
		/\$(.*?)\$/g,
		function (outer, inner) {
			let renderedFormula = '';
			try {
				const formula = katex.renderToString(inner);

				if (isEditor) {
					renderedFormula = `<span class="ql-formula" data-value="${inner}">${formula}</span>`;
				} else {
					renderedFormula = formula;
				}
			} catch (e) {
				renderedFormula = inner;
			}
			return renderedFormula;
		},
	);
};

export const renderFractions = (content) => {
	const res = content.match(/\d+\/\d+/g);
	res?.forEach((match) => {
		const numbers = match.split('/');
		const formula = katex.renderToString(`\\frac{${numbers[0]}}{${numbers[1]}}`);
		const matchRegex = new RegExp(match, 'g');
		content = content.replace(matchRegex, formula);
	});
	return content;
};

export const disableContentEditableElements = (content, isEditor) => {
	if (!isEditor && content && content.includes('contenteditable="true"')) {
		return content.replace(/contenteditable="true"/g, 'contenteditable="false"');
	}
	return content || '';
};

export const wrapVideoIframes = (content) => {
	const iframeRegex = /<iframe[^>]*class="[^"]*ql-video[^"]*"[^>]*>([\s\S]*?)<\/iframe>/gi;
	return content?.replace(iframeRegex, '<div class="video-container">$&</div>');
};

export const getHTMLCollection = (innerHTML, classname) => {
	const tmp = document.createElement('div');
	tmp.innerHTML = innerHTML || '';
	return tmp.getElementsByClassName(classname);
};

export const encodingShareUrl = (url) => url.replace('%26', '&');

export const formatSecondsTime = (sec) => moment.utc(sec * 1000).format('mm:ss');

export const getRandomValueFromRange = (min, max) =>
	Math.floor(Math.random() * (max - min + 1)) + min;

export const getSystemPreferLanguage = () => {
	const { languages, language, browserLanguage } = window.navigator;
	let lang = languages ? languages[0] : null;
	lang = lang || language || browserLanguage;

	let shortLang = lang;
	if (shortLang.indexOf('-') !== -1) [shortLang] = shortLang.split('-');
	if (shortLang.indexOf('_') !== -1) [shortLang] = shortLang.split('_');

	return shortLang;
};

export const getLanguageLocale = () => {
	const { pathname } = window.location;
	const isLoggedIn = isAuthenticated();
	const language = pathname.split(`/`)[1];
	let selectedLanguage = getLocalStorageItem('selectedLanguage');
	const systemPreferLanguage = getSystemPreferLanguage();

	if (
		!isLoggedIn &&
		AVAILABLE_LANGUAGES &&
		AVAILABLE_LANGUAGES.includes(language) &&
		language !== selectedLanguage
	) {
		selectedLanguage = language;
		setLocalStorageItem('selectedLanguage', language);
	}

	if (!selectedLanguage && AVAILABLE_LANGUAGES) {
		const defaultLanguage =
			systemPreferLanguage && AVAILABLE_LANGUAGES.includes(systemPreferLanguage)
				? systemPreferLanguage
				: AVAILABLE_LANGUAGES[0];
		if (defaultLanguage !== selectedLanguage) {
			selectedLanguage = defaultLanguage;
			setLocalStorageItem('selectedLanguage', defaultLanguage);
		}
	}

	if (AVAILABLE_LANGUAGES && AVAILABLE_LANGUAGES.length === 1) {
		if (AVAILABLE_LANGUAGES[0] !== selectedLanguage) {
			setLocalStorageItem('selectedLanguage', AVAILABLE_LANGUAGES[0]);
		}
		selectedLanguage = undefined;
	}
	return selectedLanguage;
};

export const withLanguageLocale = (url) => {
	if (IS_ADMIN) return withCountryCode(url);

	const locale = getLanguageLocale();

	return IS_INTERNATIONAL_TYPE && locale && !IS_ADMIN ? `/${locale}${url}` : url;
};

export const withCountryCode = (url) => {
	const selectedCountry = getLocalStorageItem('selectedCountry');

	return selectedCountry && IS_ADMIN ? `/${selectedCountry}${url}` : url;
};

export const getLocalizedLinks = (pathname) => {
	const path = pathname.split('/').slice(2).join('/');
	const locales = [...AVAILABLE_LANGUAGES, 'x-default'];

	return locales.map((item) => {
		const route = item === 'x-default' ? path : `${item}/${path}`;
		const url = `${FRONTEND_URL}/${route}`;
		return <link key={item} rel="alternate" href={url} hrefLang={item} />;
	});
};

export const setGTMData = (GTMData) => {
	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(GTMData);
};

export const slugify = (str) =>
	str
		.toLowerCase()
		.trim()
		.replace(/[^\w\s-]/g, '')
		.replace(/[\s_-]+/g, '-')
		.replace(/^-+|-+$/g, '');

export const getRandomInt = (min, max) => {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min) + min);
};

export const convertQueryToObject = (query) => {
	const urlParams = new URLSearchParams(query.replace(/\?/g, ''));
	const entries = urlParams.entries();
	const result = {};
	for (const [key, value] of entries) {
		result[key] = value;
	}
	return result;
};

export const removeHtmlTags = (html) => {
	if (!html) return '';

	const div = document.createElement('div');
	div.innerHTML = html;
	return div.textContent || div.innerText || '';
};

export const identifyHotjar = (userData, dailyReport) => {
	if (!window.hj) return;

	const { id, full_name, child_name, grade, finished_daily_goal } = userData || {};
	const { minutes_played } = dailyReport || {};

	window.hj('identify', id, {
		child_name,
		parent_name: full_name,
		grade: grade?.title,
		learning_path_length: minutes_played,
		finished_daily_goal,
	});
};

export const sendHotjarEvent = (eventName) => {
	window?.hj && window.hj('event', eventName);
};

export const setBugsnagUser = (userId) => {
	Bugsnag.isStarted() && Bugsnag.setUser(userId);
};

export const initializeGtag = () => {
	if (!GTM_CONTAINER_ID) return;

	window.gtag = function () {
		dataLayer?.push(arguments);
	};

	gtag('js', new Date());
	gtag('config', GA4_TAG_ID);

	new Promise((resolve) => {
		gtag('get', GA4_TAG_ID, 'client_id', resolve);
	}).then((data) => {
		window.ga4ClientId = data;
	});

	new Promise((resolve) => {
		gtag('get', GA4_TAG_ID, 'session_id', resolve);
	}).then((data) => {
		window.ga4SessionId = data;
	});
};

export const initializeBugsnag = () => {
	let ErrorBoundary = null;

	if (BUGSNAG_API_KEY !== null && !FRONTEND_URL.includes('localhost')) {
		Bugsnag.start({
			apiKey: BUGSNAG_API_KEY,
			plugins: [new BugsnagPluginReact()],
			appVersion: IS_MOBILE_APP
				? `${getClientVersion()} · ${getClientBuildNumber()}`
				: REACT_APP_BUILD_NUMBER,
			releaseStage: APP_ENVIRONMENT,
			onError: (event) => {
				if (event.originalError?.message === 'Request failed with status code 401') {
					return false;
				}
				return true;
			},
		});

		ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
	}

	return ErrorBoundary;
};

export const initializeLogRocket = (userId) => {
	if (APP_ENVIRONMENT === 'production' && !IS_ADMIN) {
		LogRocket.init('futurewhiz/studira', {
			network: {
				requestSanitizer: (request) => {
					const sensitiveEndpoints = [
						'get_user_data',
						'users',
						'subscriptions',
						'auth/get_token',
					];
					if (
						sensitiveEndpoints.some((endpoint) =>
							request.url.toLowerCase().includes(endpoint),
						)
					) {
						return null;
					}
					return request;
				},
			},
		});
		setupLogRocketReact(LogRocket);
		if (LogRocket._isInitialized) {
			LogRocket.identify(userId);
		}
	}
};

export const getModalTitle = (type, itemType) => {
	if (type === 'combine') return i18n.t('list_details.actions.combine_lists');

	return i18n.t(`list_details.actions.add_${itemType}_to_${type}`);
};

export const isValidUrl = (urlString) => {
	const urlPattern = new RegExp('(?:https?)://(w+:?w*)?(S+)(:d+)?(/|/([w#!:.?+=&%!-/]))?');
	return !!urlPattern.test(urlString);
};

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};
