import moment from 'moment';
import advertIcon from '../../images/illustrations/icons/advertIcon.svg';
import appleIcon from '../../images/illustrations/icons/apple.svg';
import backpackIcon from '../../images/illustrations/icons/backpack.svg';
import familyPictureIcon from '../../images/illustrations/icons/familyPicture.svg';
import mugIcon from '../../images/illustrations/icons/mug.svg';
import planetsIcon from '../../images/illustrations/icons/planets.svg';
import { PRIVACY_POLICY_TEACHER_URL, PRIVACY_POLICY_URL } from '../../services/Constants';

export const getDateOfBirth = (data) => {
	const { day, month, year } = data;

	return moment(`${day} ${month.value} ${year}`, 'DD MM YYYY').format('YYYY-MM-DD');
};

export const getAge = (date) => {
	const { day, month, year } = date;
	if (!day || !month || !year) return;
	if (year.toString().length !== 4) return;
	const now = moment(new Date());

	return moment.duration(now.diff(moment(`${day} ${month} ${year}`, 'dd mm yyyy'))).as('years');
};

export const getBannerImage = (imageName) => {
	switch (imageName) {
		case 'advert':
			return advertIcon;
		case 'apple':
			return appleIcon;
		case 'backpack':
			return backpackIcon;
		case 'family_picture':
			return familyPictureIcon;
		case 'mug':
			return mugIcon;

		default:
			return planetsIcon;
	}
};

export const getPrivacyPolicyUrl = (isTeacher) =>
	isTeacher ? PRIVACY_POLICY_TEACHER_URL : PRIVACY_POLICY_URL;
