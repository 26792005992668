import { videoModalActionTypes } from './Constants';

const initialState = {
	open: false,
	videoId: null,
	onClose: null,
};

export default function videoModalReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data } = payload || {};

	switch (type) {
		case videoModalActionTypes.TOGGLE_VIDEO_MODAL:
			return {
				...state,
				...data,
			};

		default:
			return state;
	}
}
