import { goalSettingsActionTypes } from './Constants';

export function getGoalSettingsRequest() {
	return {
		type: goalSettingsActionTypes.GET_GOAL_SETTINGS_REQUEST,
	};
}

export function getGoalSettingsSuccess(data) {
	return {
		type: goalSettingsActionTypes.GET_GOAL_SETTINGS_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getGoalSettingsError(error) {
	return {
		type: goalSettingsActionTypes.GET_GOAL_SETTINGS_ERROR,
		error: true,
		payload: {
			error,
		},
	};
}

export function getGoalDurationsRequest() {
	return {
		type: goalSettingsActionTypes.GET_GOAL_DURATIONS_REQUEST,
	};
}

export function getGoalDurationsSuccess(data) {
	return {
		type: goalSettingsActionTypes.GET_GOAL_DURATIONS_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getGoalDurationsError(error) {
	return {
		type: goalSettingsActionTypes.GET_GOAL_DURATIONS_ERROR,
		error: true,
		payload: {
			error,
		},
	};
}

export function setSelectedSubject(subject, selected) {
	return {
		type: goalSettingsActionTypes.SET_SELECTED_SUBJECT,
		payload: {
			subject,
			selected,
		},
	};
}

export function setSelectedCategory(subjectId, categoryId, selected) {
	return {
		type: goalSettingsActionTypes.SET_SELECTED_CATEGORY,
		payload: {
			subjectId,
			categoryId,
			selected,
		},
	};
}

export function setSelectedSubcategory(subjectId, categoryId, subcategoryId, selected) {
	return {
		type: goalSettingsActionTypes.SET_SELECTED_SUBCATEGORY,
		payload: {
			subjectId,
			categoryId,
			subcategoryId,
			selected,
		},
	};
}

export function setSelectedDuration(duration) {
	return {
		type: goalSettingsActionTypes.SET_SELECTED_DURATION,
		payload: {
			duration,
		},
	};
}

export function setCurrentProgress(progress) {
	return {
		type: goalSettingsActionTypes.SET_CURRENT_PROGRESS,
		payload: {
			progress,
		},
	};
}

export function saveGoalSettingsRequest(frequency, durationId, subjects) {
	return {
		type: goalSettingsActionTypes.SAVE_GOAL_SETTING_REQUEST,
		payload: {
			frequency,
			durationId,
			subjects,
		},
	};
}

export function saveGoalSettingsSuccess() {
	return {
		type: goalSettingsActionTypes.SAVE_GOAL_SETTING_SUCCESS,
	};
}

export function saveGoalSettingsError() {
	return {
		type: goalSettingsActionTypes.SAVE_GOAL_SETTING_ERROR,
	};
}

export function setForceFirstStep(flag) {
	return {
		type: goalSettingsActionTypes.SET_FORCE_FIRST_STEP,
		payload: {
			flag,
		},
	};
}

export function toggleDiscardChangesModal(data) {
	return {
		type: goalSettingsActionTypes.TOGGLE_DISCARD_CHANGES_MODAL,
		payload: {
			data,
		},
	};
}

export function setFrequency(frequency) {
	return {
		type: goalSettingsActionTypes.SET_FREQUENCY,
		payload: {
			frequency,
		},
	};
}

export function getFrequencyRequest() {
	return {
		type: goalSettingsActionTypes.GET_FREQUENCY_REQUEST,
	};
}

export function getFrequencySuccess(frequency, onboardingFrequency) {
	return {
		type: goalSettingsActionTypes.GET_FREQUENCY_SUCCESS,
		payload: {
			frequency,
			onboardingFrequency,
		},
	};
}

export function getFrequencyError(error) {
	return {
		type: goalSettingsActionTypes.GET_FREQUENCY_ERROR,
		error: true,
		payload: {
			error,
		},
	};
}
