import { sidebarActionTypes } from './Constants';

const initialState = {
	expanded: true,
	isOpenedSettingsMenu: false,
};

export default function signInReducer(state = initialState, action) {
	const { type, payload } = action;
	const { status } = payload || {};

	switch (type) {
		case sidebarActionTypes.SET_EXPANDED_MENU:
			return {
				...state,
				expanded: status,
			};

		case sidebarActionTypes.SET_SETTINGS_MENU:
			return {
				...state,
				isOpenedSettingsMenu: status,
			};

		default:
			return state;
	}
}
