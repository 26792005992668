import keyMirror from 'keymirror';
import React from 'react';

export const signUpActionTypes = keyMirror({
	SIGN_UP_REQUEST: null,
	SIGN_UP_SUCCESS: null,
	SIGN_UP_RERUN: null,
	SIGN_UP_ERROR: null,

	CHECK_EMAIL_IF_EXIST_REQUEST: null,
	CHECK_EMAIL_IF_EXIST_SUCCESS: null,
	CHECK_EMAIL_IF_EXIST_ERROR: null,

	SET_INITIAL_VALUES: null,

	SET_IS_SOCIAL_LOGIN: null,

	SET_AGE: null,

	GET_EDUCATION_TYPE_REQUEST: null,
	GET_EDUCATION_TYPE_SUCCESS: null,
	GET_EDUCATION_TYPE_ERROR: null,

	GET_SCHOOLS_REQUEST: null,
	GET_SCHOOLS_SUCCESS: null,
	GET_SCHOOLS_ERROR: null,

	SET_EDUCATION_INFO: null,
	SET_EDUCATION_INITIAL_INFO: null,

	SET_IS_TEACHER: null,

	CLEAR_EDUCATION_FORM_FIELD: null,
	CLEAN_SIGN_UP_STATE: null,
	SET_LAST_PAGE_SLUG: null,

	GET_MOTIVATION_DATA_REQUEST: null,
	GET_MOTIVATION_DATA_SUCCESS: null,
	GET_MOTIVATION_DATA_ERROR: null,

	SELECT_MOTIVATION: null,
	DOWNGRADE_TEACHER: null,

	RESEND_EMAIL_VERIFICATION_REQUEST: null,
	RESEND_EMAIL_VERIFICATION_SUCCESS: null,
	RESEND_EMAIL_VERIFICATION_ERROR: null,

	CONFIRM_EMAIL_REQUEST: null,
	CONFIRM_EMAIL_SUCCESS: null,
	CONFIRM_EMAIL_ERROR: null,
});

export const getMonths = (t) => [
	{ value: '01', title: t('general.months_list.january') },
	{ value: '02', title: t('general.months_list.february') },
	{ value: '03', title: t('general.months_list.march') },
	{ value: '04', title: t('general.months_list.april') },
	{ value: '05', title: t('general.months_list.may') },
	{ value: '06', title: t('general.months_list.june') },
	{ value: '07', title: t('general.months_list.july') },
	{ value: '08', title: t('general.months_list.august') },
	{ value: '09', title: t('general.months_list.september') },
	{ value: '10', title: t('general.months_list.october') },
	{ value: '11', title: t('general.months_list.november') },
	{ value: '12', title: t('general.months_list.december') },
];

export const YOUNGER_THAN = 13;
export const OLDER_THAN = 19;

export const SIGN_UP_EDUCATION_FORM = 'SignUpEducationForm';

export const getMessage = (t) => (
	<>
		{t('sign_up.e_mail_already_used')}
		<a href="/wachtwoord-vergeten" className="inline-link">
			{t('sign_up.have_you_forgotten_password')}
		</a>
		?
	</>
);

export const SIGN_UP_FORM = 'SignUpForm';
export const requestParentEmailExperiment = '2022-Q2-request-parent-email-signup-form';
export const CHILD_FORM = 'ChildForm';
