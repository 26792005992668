import { put, call, takeLatest, all } from 'redux-saga/effects';
import i18n from 'i18next';
import { processRequest } from '../../../../services/Api';
import { manageSubjectActionTypes } from './Constants';
import * as manageSubjectActions from './Actions';
import * as notificationActions from '../../../../components/notification/Actions';
import * as errorHandlerActions from '../../../../components/errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			manageSubjectActionTypes.GET_SUBJECT_DATA_REQUEST,
			handleGetSubjectDataRequest,
		),
		yield takeLatest(
			manageSubjectActionTypes.MANAGE_SUBJECT_REQUEST,
			handleManageSubjectRequest,
		),
	]);
}

export function* handleGetSubjectDataRequest(action) {
	try {
		const { subjectId } = action.payload;
		const { data } = yield call(processRequest, `admin/subjects/${subjectId}`);

		yield put(manageSubjectActions.getSubjectDataSuccess(data.subject));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(manageSubjectActions.getSubjectDataError());
	}
}

export function* handleManageSubjectRequest(action) {
	try {
		const { formData, subjectId, callback } = action.payload;
		const callType = subjectId ? 'PUT' : 'POST';

		const { data } = yield call(
			processRequest,
			`admin/subjects${subjectId ? `/${subjectId}` : ''}`,
			callType,
			{ subject: formData },
		);

		yield put(manageSubjectActions.manageSubjectSuccess(data.subject));
		callback && callback();

		yield put(
			notificationActions.createNotification(
				subjectId
					? i18n.t('general.saved_changes')
					: i18n.t('modals.manage_subject_modal.add_notification'),
				null,
				false,
				3000,
			),
		);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(manageSubjectActions.manageSubjectError());
	}
}
