import keyMirror from 'keymirror';

export const addImageModalActionTypes = keyMirror({
	HANDLE_ADD_IMAGE_MODAL: null,

	GET_IMAGES_REQUEST: null,
	GET_IMAGES_SUCCESS: null,
	GET_IMAGES_ERROR: null,

	UPLOAD_IMAGE_REQUEST: null,
	UPLOAD_IMAGE_SUCCESS: null,
	UPLOAD_IMAGE_ERROR: null,

	CLEAR_ADD_IMAGE_MODAL_STATE: null,
});

export const convertImagePathToFile = (imagePath) => {
	if (imagePath.includes('/storage/')) {
		imagePath = `file://${imagePath}`;
	}
	return new Promise((resolve, reject) => {
		window.resolveLocalFileSystemURL(imagePath, (fileEntry) => {
			fileEntry.file((resFile) => {
				const reader = new FileReader();
				reader.onloadend = (evt) => {
					const imgBlob = new Blob([evt.target.result], { type: 'image/jpeg' });
					imgBlob.name = fileEntry.name;
					resolve(imgBlob);
				};

				reader.onerror = (e) => {
					console.log(`Failed file read: ${e.toString()}`);
					reject(e);
				};

				reader.readAsArrayBuffer(resFile);
			});
		});
	});
};
