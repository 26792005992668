import keyMirror from 'keymirror';

export const managePlaylistActionTypes = keyMirror({
	TOGGLE_MANAGE_PLAYLIST_MODAL: null,
	TOGGLE_MOVE_PLAYLIST_TO_TOPIC_MODAL: null,

	GET_PLAYLIST_MODAL_REQUEST: null,
	GET_PLAYLIST_MODAL_SUCCESS: null,
	GET_PLAYLIST_MODAL_ERROR: null,

	MANAGE_PLAYLIST_MODAL_REQUEST: null,
	MANAGE_PLAYLIST_MODAL_SUCCESS: null,
	MANAGE_PLAYLIST_MODAL_ERROR: null,

	CLEAN_MANAGE_PLAYLIST_MODAL_STATE: null,
});

export const DIFFICULTIES = [
	{ name: 'Easy', value: 'easy' },
	{ name: 'Medium', value: 'medium' },
	{ name: 'Hard', value: 'hard' },
];

export const MANAGE_PLAYLIST_FORM = 'ManagePlaylistForm';
export const MOVE_PLAYLIST_FORM = 'MovePlaylistForm';
