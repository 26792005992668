import { mergeSubjectCategories, subjectDetailsActionTypes } from './Constants';

const initialState = {
	subjectDetails: {},
	loadingSubjectDetails: true,
	loadingSubjectCategories: true,
	subjectCategories: [],
	subjectCategoriesAppliedFilters: {},
};

export default function subjectDetailsReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data, subjectData, subjectCategories, categoryId, isOpen, subcategories } =
		payload || {};

	switch (type) {
		case subjectDetailsActionTypes.GET_ADMIN_SUBJECT_DETAILS_REQUEST:
			return {
				...state,
				loadingSubjectDetails: true,
			};
		case subjectDetailsActionTypes.GET_ADMIN_SUBJECT_DETAILS_SUCCESS:
			return {
				...state,
				subjectDetails: subjectData,
				loadingSubjectDetails: false,
			};
		case subjectDetailsActionTypes.GET_ADMIN_SUBJECT_DETAILS_ERROR:
			return {
				...state,
				loadingSubjectDetails: false,
			};
		case subjectDetailsActionTypes.CLEAN_ADMIN_SUBJECT_DETAILS_STATE:
			return initialState;
		case subjectDetailsActionTypes.GET_ADMIN_SUBJECT_CATEGORIES_REQUEST:
			return {
				...state,
				loadingSubjectCategories: true,
			};
		case subjectDetailsActionTypes.GET_ADMIN_SUBJECT_CATEGORIES_SUCCESS:
			return {
				...state,
				loadingSubjectCategories: false,
				subjectCategories: mergeSubjectCategories(
					subjectCategories,
					state.subjectCategories,
				),
			};
		case subjectDetailsActionTypes.GET_ADMIN_SUBJECT_CATEGORIES_ERROR:
			return {
				...state,
				loadingSubjectCategories: false,
			};
		case subjectDetailsActionTypes.CHANGE_ADMIN_SUBJECT_CATEGORIES_FILTER:
			return {
				...state,
				subjectCategoriesAppliedFilters: data.appliedFilters,
			};
		case subjectDetailsActionTypes.SET_ADMIN_CATEGORY_OPEN_STATE:
			return {
				...state,
				subjectCategories: state.subjectCategories.map((subjectCategory) => {
					return categoryId === subjectCategory.id
						? { ...subjectCategory, is_open: isOpen }
						: { ...subjectCategory };
				}),
			};
		case subjectDetailsActionTypes.SET_ADMIN_ALL_CATEGORIES_OPEN_STATE:
			return {
				...state,
				subjectCategories: state.subjectCategories.map((subjectCategory) => {
					return { ...subjectCategory, is_open: isOpen };
				}),
			};
		case subjectDetailsActionTypes.GET_ADMIN_SUBCATEGORIES_REQUEST:
			return {
				...state,
				subjectCategories: state.subjectCategories.map((subjectCategory) => {
					return categoryId === subjectCategory.id
						? { ...subjectCategory, loading: true }
						: { ...subjectCategory };
				}),
			};
		case subjectDetailsActionTypes.GET_ADMIN_SUBCATEGORIES_SUCCESS:
			return {
				...state,
				subjectCategories: state.subjectCategories.map((subjectCategory) => {
					return categoryId === subjectCategory.id
						? { ...subjectCategory, loading: false, subcategories }
						: { ...subjectCategory };
				}),
			};
		case subjectDetailsActionTypes.GET_ADMIN_SUBCATEGORIES_ERROR:
			return {
				...state,
				subjectCategories: state.subjectCategories.map((subjectCategory) => {
					return categoryId === subjectCategory.id
						? { ...subjectCategory, loading: false }
						: { ...subjectCategory };
				}),
			};
		default:
			return state;
	}
}
