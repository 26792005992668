import { languageSelectorActionTypes } from './Constants';

const initialState = {
	loading: false,
	languages: null,
};

export default function languageSelectorReducer(state = initialState, action) {
	const { type, payload } = action;
	const { locales } = payload || {};

	switch (type) {
		case languageSelectorActionTypes.GET_AVAILABLE_LANGUAGES_REQUEST:
			return {
				...state,
				loading: true,
			};

		case languageSelectorActionTypes.GET_AVAILABLE_LANGUAGES_SUCCESS:
			const formattedLocales = locales.map(({ code, localized_name, language }) => ({
				locale: code,
				flag_code: language.flag_code,
				language_code: language.code,
				localized_name,
			}));

			return {
				...state,
				loading: false,
				languages: formattedLocales,
			};

		case languageSelectorActionTypes.GET_AVAILABLE_LANGUAGES_ERROR:
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
}
