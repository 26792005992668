import { subjectsActionTypes } from './Constants';

const initialState = {
	loadingSubjects: true,
	subjects: [],
};

export default function subjectsReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data } = payload || {};

	switch (type) {
		case subjectsActionTypes.GET_ADMIN_SUBJECTS_REQUEST:
			return {
				...state,
			};
		case subjectsActionTypes.GET_ADMIN_SUBJECTS_SUCCESS:
			return {
				...state,
				loadingSubjects: false,
				subjects: data,
			};
		case subjectsActionTypes.GET_ADMIN_SUBJECTS_ERROR:
			return {
				...state,
				loadingSubjects: false,
			};
		case subjectsActionTypes.CHANGE_ADMIN_SUBJECT_POSITION_REQUEST:
			return {
				...state,
				loadingSubjects: false,
			};
		case subjectsActionTypes.CHANGE_ADMIN_SUBJECT_POSITION_SUCCESS:
			return {
				...state,
				loadingSubjects: false,
			};
		case subjectsActionTypes.CHANGE_ADMIN_SUBJECT_POSITION_ERROR:
			return {
				...state,
				loadingSubjects: false,
			};
		case subjectsActionTypes.DELETE_ADMIN_SUBJECT_REQUEST:
			return {
				...state,
				loadingSubjects: true,
			};
		case subjectsActionTypes.DELETE_ADMIN_SUBJECT_SUCCESS:
			return {
				...state,
				loadingSubjects: false,
			};
		case subjectsActionTypes.DELETE_ADMIN_SUBJECT_ERROR:
			return {
				...state,
				loadingSubjects: false,
			};
		default:
			return state;
	}
}
