export const STICKER_REWARDS = {
	CHOOSE_PROFILE_EMOJI: 'emoji_as_profile_image',
	SKIP_TILE: 'skip_a_tile',
	SHORTER_LEARNING_PATH: 'shorter_learning_path',
	NAME_GENERATOR: 'name_generator',
	CONFETTI_DAY: 'confetti_day',
	COLOR_CHANGER: 'color_changer',
	SEND_MESSAGE_TO_PARENTS: 'send_a_message_to_parents',
	REPLACE_TILE: 'replace_a_tile',
};

export const STICKER_SHAPES = {
	SQUIRCLE: 'squircle',
	HEXAGON: 'hexagon',
	FLAG: 'flag',
	FLOWER: 'flower',
};

export const STICKER_COLORS = {
	orange: ['#FF6B18', '#FF8541', '#FFE4D6'],
	yellow: ['#FFB801', '#FFCF0D', '#FFF3C2'],
	turquoise: ['#28D4B5', '#6EE8D2', '#DCF9F4'],
	lavender: ['#8E6BFF', '#B199FF', '#EFEBFF'],
};
