import { topicsActionTypes } from './Constants';

const initialState = {
	topics: {},
	appliedFilters: {},
	subcategories: [],
	availableFilters: {},
	showFilters: false,
	loadingTopics: false,
	loadingGenerateInstructions: false,
};

export default function topicsReducer(state = initialState, action) {
	const { type, payload } = action;
	const { open, topicId, playlists, available_filters, subcategories, appliedFilters } =
		payload || {};
	switch (type) {
		case topicsActionTypes.GET_ADMIN_TOPICS_REQUEST:
			return {
				...state,
				loadingTopics: true,
			};
		case topicsActionTypes.GET_ADMIN_TOPICS_SUCCESS:
			return {
				...state,
				loadingTopics: false,
				availableFilters: available_filters,
				subcategories,
			};
		case topicsActionTypes.GET_ADMIN_TOPICS_ERROR:
			return {
				...state,
				loadingTopics: false,
			};
		case topicsActionTypes.SET_ADMIN_TOPIC_OPEN_STATE:
			return {
				...state,
				subcategories: state.subcategories.map((subcategory) => {
					const topicsResults = subcategory.results.map((topic) => {
						return topicId === topic.id ? { ...topic, is_open: open } : { ...topic };
					});
					return { ...subcategory, results: topicsResults };
				}),
			};
		case topicsActionTypes.SET_ADMIN_ALL_TOPICS_OPEN_STATE:
			return {
				...state,
				subcategories: state.subcategories.map((subcategory) => {
					const topicsResults = subcategory.results.map((topic) => {
						return { ...topic, is_open: open };
					});
					return { ...subcategory, results: topicsResults };
				}),
			};
		case topicsActionTypes.DELETE_ADMIN_TOPIC_REQUEST:
			return {
				...state,
				loading: true,
			};
		case topicsActionTypes.DELETE_ADMIN_TOPIC_SUCCESS:
			return {
				...state,
				loading: false,
			};
		case topicsActionTypes.DELETE_ADMIN_TOPIC_ERROR:
			return {
				...state,
				loading: false,
			};
		case topicsActionTypes.GET_TOPIC_PLAYLISTS_REQUEST:
			return {
				...state,
				topics: { ...state.topics, [topicId]: { loading: true, playlists: [] } },
			};
		case topicsActionTypes.GET_TOPIC_PLAYLISTS_SUCCESS:
			return {
				...state,
				topics: {
					...state.topics,
					[topicId]: { ...state.topics[topicId], loading: false, playlists },
				},
			};
		case topicsActionTypes.CLEAN_ADMIN_TOPICS_STATE:
			return initialState;

		case topicsActionTypes.SET_ADMIN_APPLIED_FILTERS:
			return {
				...state,
				appliedFilters,
			};
		case topicsActionTypes.GENERATE_INSTRUCTIONS_REQUEST:
			return {
				...state,
				loadingGenerateInstructions: true,
			};
		case topicsActionTypes.GENERATE_INSTRUCTIONS_SUCCESS:
		case topicsActionTypes.GENERATE_INSTRUCTIONS_ERROR:
			return {
				...state,
				loadingGenerateInstructions: false,
			};
		default:
			return state;
	}
}
