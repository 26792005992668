import keyMirror from 'keymirror';

export const progressActionTypes = keyMirror({
	GET_PROGRESS_OVERVIEW_REQUEST: null,
	GET_PROGRESS_OVERVIEW_SUCCESS: null,
	GET_PROGRESS_OVERVIEW_ERROR: null,

	GET_PROGRESS_DETAILS_REQUEST: null,
	GET_PROGRESS_DETAILS_SUCCESS: null,
	GET_PROGRESS_DETAILS_ERROR: null,

	TOGGLE_INFO_MODAL: null,
	CLEAR_PROGRESS_DETAILS: null,
});

export const TOPIC_LEVEL_STATUS = {
	GOOD: 'good',
	NEED_PRACTICE: 'need_practice',
	EXCELLENT: 'excellent',
	REPEAT: 'repeat',
};

export const TOPIC_LEVEL_STATUS_MAPPING = {
	[TOPIC_LEVEL_STATUS.GOOD]: { class: 'blue', icon: 'icon-thumbs-up' },
	[TOPIC_LEVEL_STATUS.NEED_PRACTICE]: { class: 'orange', icon: 'icon-clock-plus' },
	[TOPIC_LEVEL_STATUS.REPEAT]: { class: 'blue repeat', icon: 'icon-thumbs-up' },
	[TOPIC_LEVEL_STATUS.EXCELLENT]: { class: 'green', icon: 'icon-crown' },
};

export const PROGRESS_INFO_TYPE = {
	DEVELOPMENT: 'development',
	MASTERY: 'mastery',
};
