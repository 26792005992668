/* eslint-disable no-lonely-if */
import i18n from 'i18next';
import { getTranslationsInstance } from '../../shared/Selectors';
import { processRequest } from '../../services/Api';
import { put, call, takeLatest, all, select, delay } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { historyReplace } from '../../services/History';
import { getDateOfBirth } from './Services';
import {
	getCountryId,
	getUserId,
	getIsTeacher,
	getToken,
	getUserIdAfterSocialLogin,
	getLastPageSlug,
	getUserData,
} from './Selectors';
import {
	getUtmTagsLikeObject,
	getUtmTags,
	removeUtmTags,
	getCookie,
	removeCookie,
	getLocalStorageItem,
	removeLocalStorageItem,
	setGTMData,
} from '../../services/Helper';
import { COOKIES, IS_MOBILE_APP, IS_ADMIN, IS_INTERNATIONAL_TYPE } from '../../services/Constants';
import { removeActivationCodeCookie } from '../activationCode/Constants';
import { signUpActionTypes, getMessage } from './Constants';
import { setAccessToken } from '../../services/AccessToken';
import * as signUpActions from './Actions';
import * as profileActions from '../profile/Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';
import * as notificationActions from '../../components/notification/Actions';
import * as sharedActions from '../../shared/Actions';

export default function* () {
	yield all([
		yield takeLatest(signUpActionTypes.GET_SCHOOLS_REQUEST, handleGetSchoolsRequest),
		yield takeLatest(signUpActionTypes.SIGN_UP_REQUEST, handleSignUpRequest),
		yield takeLatest(signUpActionTypes.CHECK_EMAIL_IF_EXIST_REQUEST, handleCheckEmailRequest),
		yield takeLatest(
			signUpActionTypes.GET_MOTIVATION_DATA_REQUEST,
			handleGetMotivationDataRequest,
		),
		yield takeLatest(signUpActionTypes.SELECT_MOTIVATION, handleSelectMotivationRequest),
		yield takeLatest(signUpActionTypes.DOWNGRADE_TEACHER, handleDowngradeTeachernRequest),
		yield takeLatest(
			signUpActionTypes.RESEND_EMAIL_VERIFICATION_REQUEST,
			handleResendEmailVerificationRequest,
		),
		yield takeLatest(signUpActionTypes.CONFIRM_EMAIL_REQUEST, handleConfirmEmailRequest),
	]);
}

export function* handleGetSchoolsRequest({ payload }) {
	try {
		const { q } = payload;
		const countryId = yield select(getCountryId);
		const { data } = yield call(processRequest, `countries/${countryId}/schools`, 'GET', { q });

		yield put(signUpActions.getSchoolsSuccess({ ...data }));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'ignore'));
		yield put(signUpActions.getSchoolsError(e));
	}
}

export function* handleSignUpRequest({ payload }) {
	const translationsInstance = yield select(getTranslationsInstance);

	try {
		const { data: formData, recaptchaToken } = payload || {};
		const userId = yield select(getUserIdAfterSocialLogin);
		const token = yield select(getToken);
		const isTeacher = yield select(getIsTeacher);
		const lastPageSlug = yield select(getLastPageSlug);
		const activationCode = getCookie(COOKIES.activationCode);
		const {
			email,
			parent_email,
			first_name,
			last_name,
			password,
			needUpdateEmail,
			needUpdateBirthday,
			marketing_email_preference,
			locale,
		} = formData || {};
		const birthday = needUpdateBirthday && getDateOfBirth(formData);
		let method = 'POST';
		let url = `public/user_accounts`;
		if (token) {
			method = 'PUT';
			url = `users/${userId}`;
		}
		const requestPayload = {
			first_name,
			last_name,
			is_teacher: isTeacher,
		};

		if (needUpdateBirthday) {
			requestPayload.birthday = birthday;
		}
		if (parent_email) {
			requestPayload.parent_email = parent_email;
		}
		if (IS_INTERNATIONAL_TYPE && locale) requestPayload.locale_code = locale;
		const userEmailPayload = {};
		if (needUpdateEmail) {
			userEmailPayload.account = { email };
		}
		let requestBody = {
			[token ? 'user' : 'user_account']: token
				? { ...requestPayload, ...userEmailPayload }
				: { ...requestPayload, email, password },
		};

		requestBody.marketing_email_preference = marketing_email_preference;

		if (recaptchaToken) {
			requestBody.recaptcha_token = recaptchaToken;
		}

		if (activationCode) {
			requestBody[token ? 'user' : 'user_account'].activation_code = activationCode;
		}
		const utmTagsString = getUtmTags();
		const utmTags = utmTagsString && getUtmTagsLikeObject(utmTagsString);

		if (utmTags) {
			requestBody = { ...requestBody, utm_tags: utmTags };
		}
		if (lastPageSlug) {
			requestBody = { ...requestBody, last_page_slug: lastPageSlug };
		}
		const { data } = yield call(processRequest, url, method, requestBody);

		utmTags && removeUtmTags(utmTags);

		if (token) {
			const newToken = yield call(
				processRequest,
				'users/access_token',
				'GET',
				{},
				false,
				token,
			);
			const { auth_token } = newToken && newToken.data;

			setAccessToken(auth_token);
		}

		yield put(
			signUpActions.signUpSuccess({
				user_account: data && data.user_account,
				token: data && data.auth_token,
				saveToken: !token,
			}),
		);

		if (activationCode) {
			if (data.activation_code.success) {
				yield removeActivationCodeCookie();
				localStorage.activationCode = 'success';
			} else {
				localStorage.activationCode = 'failed';
			}
		}

		setGTMData({
			event: isTeacher ? 'registrationTeacher' : 'registrationStudent',
		});
		if (IS_INTERNATIONAL_TYPE && locale) {
			setGTMData({
				event: 'locale',
				status: 'fromRegistration',
				locale,
			});
		}

		if (isTeacher) {
			yield put(profileActions.getUserDetailsSuccess({ is_teacher: true }));
		}

		yield call(historyReplace, '/sign-up/education-details');
	} catch (e) {
		const { status, data } = e.response || {};
		if (status !== 409 && status !== 429) {
			yield put(errorHandlerActions.handleError(e, 'notification'));
			yield put(signUpActions.signUpError(e));
		}
		if (status === 409) {
			yield put(
				stopSubmit('SignUpForm', {
					[data.field]: getMessage(translationsInstance),
				}),
			);
		}
		if (status === 422) {
			yield put(
				stopSubmit('SignUpForm', {
					email: getMessage(translationsInstance),
				}),
			);
		}
		if (status === 429) {
			yield put(signUpActions.signUpRerun({ needToExecuteRecaptcha: true }));
		}
	}
}

export function* handleCheckEmailRequest({ payload }) {
	try {
		const { email } = payload;
		const translationsInstance = yield select(getTranslationsInstance);

		yield call(processRequest, 'public/user_accounts', 'GET', { email });

		yield put(
			stopSubmit('SignUpForm', {
				email: getMessage(translationsInstance),
			}),
		);

		yield put(signUpActions.checkEmailIfExistSuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'ignore'));
		yield put(signUpActions.checkEmailIfExistError(e.response.data.flagged_email));
	}
}

function* handleStepsAfterReferrerData() {
	const activationCode = getCookie(COOKIES.activationCode);
	const isTeacher = yield select(getIsTeacher);
	const userData = yield select(getUserData);

	if (!IS_ADMIN && !IS_MOBILE_APP && !isTeacher && userData.needs_to_choose_package) {
		const coupon = getCookie(COOKIES.coupon);
		const discountCode = getLocalStorageItem('discountCode');

		if (coupon || discountCode) {
			yield call(historyReplace, `/coupon/${coupon || discountCode}`);
			coupon && removeCookie(COOKIES.coupon);
			discountCode && removeLocalStorageItem('discountCode');
			return;
		}
		if (localStorage.activationCode) {
			if (localStorage.activationCode === 'success') {
				yield call(historyReplace, '/activation-code-applying');
				return;
			}
			yield call(historyReplace, `/activeren/${activationCode}/apply`);
			return;
		}
		yield call(historyReplace, '/packages');
		return;
	}

	if (!IS_ADMIN && userData.needs_to_verify_email) {
		yield call(historyReplace, '/verify-email');
		return;
	}

	if (localStorage.activationCode) {
		if (localStorage.activationCode === 'success') {
			yield call(historyReplace, '/success-package-activation');
			return;
		}
		yield call(historyReplace, `/activeren/${activationCode}/apply`);
		return;
	}
	yield call(historyReplace, '/');
}

export function* handleGetMotivationDataRequest() {
	try {
		const { data } = yield call(processRequest, 'motivations');

		yield put(signUpActions.getMotivationsDataSuccess(data.motivations));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'ignore'));
		yield put(signUpActions.getMotivationsDataError());
	}
}

export function* handleSelectMotivationRequest({ payload }) {
	try {
		const { motivationId } = payload || {};
		yield call(processRequest, `motivations/${motivationId}/select`, 'POST');
		yield call(handleStepsAfterReferrerData);
	} catch (e) {
		yield call(handleStepsAfterReferrerData);
		yield put(errorHandlerActions.handleError(e, 'ignore'));
		yield put(signUpActions.getMotivationsDataError());
	}
}

export function* handleDowngradeTeachernRequest() {
	try {
		const userId = yield select(getUserId);
		let needsToVerifyEmail = true;
		let needsToFillEducationDetails = false;

		yield call(processRequest, `users/${userId}/downgrade_teacher`, 'POST');

		while (needsToVerifyEmail) {
			yield delay(1000);
			const { data } = yield call(processRequest, 'get_user_data');
			needsToVerifyEmail = data.needs_to_verify_email;
			needsToFillEducationDetails = data.needs_to_fill_education_details;
		}

		if (!IS_ADMIN && needsToFillEducationDetails) {
			yield put(signUpActions.cleanSignUpState());
			return yield call(historyReplace, '/update-education-details');
		}

		yield call(historyReplace, '/');
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'ignore'));
	}
}

export function* handleResendEmailVerificationRequest(action) {
	try {
		const { userId, email } = action.payload;

		yield call(processRequest, `users/${userId}/resend_confirmation_email`, 'POST');
		yield put(signUpActions.resendEmailVerificationSuccess());

		yield put(sharedActions.getUserDataRequest());
		yield put(
			notificationActions.createNotification(
				i18n.t('lists.teacher_validation.resend_message', { email }),
				null,
				false,
				5000,
			),
		);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(signUpActions.resendEmailVerificationError());
	}
}

export function* handleConfirmEmailRequest(action) {
	try {
		const { accountId, confirmationToken } = action.payload;

		yield call(processRequest, `public/user_accounts/${accountId}/confirm_email`, 'POST', {
			confirmation_token: confirmationToken,
		});
		yield call(historyReplace, '/success-email-verification');
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'ignore'));
		yield call(historyReplace, '/');
		yield put(
			notificationActions.createNotification(
				i18n.t('lists.teacher_validation.error_message'),
				null,
				true,
				5000,
			),
		);
	}
}
