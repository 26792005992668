import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';
import { processRequest } from '../../../services/Api';
import { playlistData, playlistFullData, selectedQuestionData } from './Selectors';
import { adminPlaylistActionTypes, getPlaylistUrl, SECTION_TYPES } from './Constants';
import { historyReplace } from '../../../services/History';
import * as adminPlaylistActions from './Actions';
import * as errorHandlerActions from '../../../components/errorHandler/Actions';
import * as notificationActions from '../../../components/notification/Actions';
import * as exerciseActions from '../../../pages/exercise/Actions';

export default function* () {
	yield all([
		yield takeLatest(adminPlaylistActionTypes.GET_PLAYLIST_REQUEST, handleGetPlaylistRequest),
		yield takeLatest(
			adminPlaylistActionTypes.MANAGE_PLAYLIST_REQUEST,
			handleManagePlaylistRequest,
		),
		yield takeLatest(adminPlaylistActionTypes.GET_QUESTION_REQUEST, handleGetQuestionRequest),
		yield takeLatest(
			adminPlaylistActionTypes.CREATE_QUESTION_REQUEST,
			handleCreateQuestionRequest,
		),
		yield takeLatest(
			adminPlaylistActionTypes.UPDATE_QUESTION_REQUEST,
			handleUpdateQuestionRequest,
		),
		yield takeLatest(
			adminPlaylistActionTypes.MANAGE_QUESTION_REQUEST,
			handleManageQuestionRequest,
		),
		yield takeLatest(
			adminPlaylistActionTypes.CHANGE_QUESTION_POSITION,
			handleChangeQuestionPositionRequest,
		),
		yield takeLatest(
			adminPlaylistActionTypes.PREVIEW_PLAYLIST_REQUEST,
			handlePreviewPlaylistRequest,
		),
		yield takeLatest(
			adminPlaylistActionTypes.PUBLISH_PLAYLIST_REQUEST,
			handlePublishPlaylistRequest,
		),
		yield takeLatest(adminPlaylistActionTypes.REVERT_TEXT_REQUEST, handleRevertTextRequest),
		yield takeLatest(
			adminPlaylistActionTypes.CREATE_QUESTION_INSTRUCTION_REQUEST,
			handleCreateQuestionInstructionRequest,
		),
		yield takeLatest(
			adminPlaylistActionTypes.UPDATE_QUESTION_INSTRUCTION_REQUEST,
			handleUpdateQuestionInstructionRequest,
		),
		yield takeLatest(
			adminPlaylistActionTypes.DELETE_QUESTION_INSTRUCTION_REQUEST,
			handleDeleteQuestionInstructionRequest,
		),
		yield takeLatest(
			adminPlaylistActionTypes.GENERATE_INSTRUCTION_AUDIO_REQUEST,
			handleGenerateInstructionAudioRequest,
		),
		yield takeLatest(
			adminPlaylistActionTypes.DELETE_INSTRUCTION_AUDIO_REQUEST,
			handleDeleteInstructionAudioRequest,
		),
	]);
}

const getFilteredIndex = (list, id) => {
	let currentElIndex = 0;
	if (list.length)
		list.forEach((item, index) => {
			if (item.id === id) currentElIndex = index;
		});

	return currentElIndex;
};

export function* handleGetPlaylistRequest({ payload }) {
	try {
		const { playlistId, questionId } = payload || {};

		const {
			data: { playlist_section },
		} = yield call(processRequest, `admin/playlist_sections/quizzes/${playlistId}`);

		const { questions, last_modified_at, section_type } = playlist_section;
		let questionPresent = null;

		if (questionId) {
			questionPresent =
				questionId &&
				questions.find((item) => item.id.toString() === questionId.toString());
		}

		if (questionPresent) {
			yield put(adminPlaylistActions.getQuestion(questionPresent.id));
		} else if (section_type === SECTION_TYPES.READING) {
			yield call(historyReplace, getPlaylistUrl(playlistId, SECTION_TYPES.READING));
		} else if (questions.length) {
			yield call(historyReplace, getPlaylistUrl(playlistId, questions[0].id));
			yield put(adminPlaylistActions.getQuestion(questions[0].id));
		}

		yield put(adminPlaylistActions.getPlaylistSuccess(playlist_section));
		yield put(adminPlaylistActions.setUpdateTime(last_modified_at));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(adminPlaylistActions.getPlaylistError());
	}
}

export function* handleManagePlaylistRequest({ payload }) {
	try {
		const { formData, playlistId, questionId } = payload || {};

		yield call(processRequest, `admin/playlist_sections/${playlistId}`, 'PUT', {
			playlist_section: formData,
		});

		yield put(adminPlaylistActions.managePlaylistSuccess());
		yield put(adminPlaylistActions.getPlaylistRequest(playlistId, questionId, false));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(adminPlaylistActions.managePlaylistError(e.response?.data?.error));
	}
}

export function* handleRevertTextRequest({ payload }) {
	try {
		const { textId, playlistId, questionId } = payload || {};

		yield call(processRequest, `admin/playlist_section_texts/${textId}/revert`, 'POST');

		yield put(adminPlaylistActions.revertTextSuccess());
		yield put(adminPlaylistActions.getPlaylistRequest(playlistId, questionId));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(adminPlaylistActions.revertTextError(e.response?.data?.error));
	}
}

export function* handleGetQuestionRequest({ payload }) {
	try {
		const { id } = payload || {};

		const { data } = yield call(processRequest, `questions/${id}`);

		yield put(adminPlaylistActions.selectPlaylistQuestion(data.question));
		yield put(adminPlaylistActions.getQuestionSuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(adminPlaylistActions.getQuestionError());
	}
}

export function* handleCreateQuestionRequest({ payload }) {
	try {
		const { data: formData } = payload || {};

		const { data } = yield call(processRequest, `admin/questions`, 'POST', {
			question: formData,
		});

		const playlist = yield select(playlistData);

		const {
			edited_after_quiz_publish,
			icon_name,
			id,
			position,
			question,
			status,
			stream_ids,
			title,
			valid,
		} = data.question || {};

		const newPlaylistData = {
			edited_after_quiz_publish,
			icon_name,
			id,
			position,
			question,
			status,
			stream_ids,
			title,
			valid,
		};

		yield put(
			adminPlaylistActions.createQuestionSuccess({
				...playlist,
				questions: [...playlist.questions, newPlaylistData],
			}),
		);

		yield put(adminPlaylistActions.getQuestion(id));
		yield put(adminPlaylistActions.setUpdateTime(new Date()));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(adminPlaylistActions.createQuestionError());
	}
}

export function* handleUpdateQuestionRequest({ payload }) {
	const { blockUpdate, loadingPublish } = yield select(playlistFullData);

	if (blockUpdate || loadingPublish) return;

	try {
		const playlist = yield select(playlistData);

		const { id, formData, index, updateSideEffect, tab } = payload || {};

		const { data } = yield call(processRequest, `questions/${id}`, 'PATCH', {
			question: formData,
			playlist_section_id: playlist?.id,
		});

		const filteredQuestions =
			playlist.questions && playlist.questions.filter((item) => !item.hide);

		if (formData.exercise_stage) {
			yield put(
				notificationActions.createNotification(
					i18n.t(
						`admin_quiz_editor.manage_confirmation_message.${formData.exercise_stage}`,
					),
					null,
					false,
					3000,
				),
			);
		}

		const selectedQuestion = yield select(selectedQuestionData);

		const newQuestionsList =
			playlist.questions &&
			(index !== undefined
				? playlist.questions.filter((question) => question.id !== id)
				: playlist.questions &&
				  playlist.questions.map((question) =>
						question.id === id ? data.question : question,
				  ));

		yield put(adminPlaylistActions.setQuestionsList(newQuestionsList));
		yield call(historyReplace, getPlaylistUrl(playlist.id, data.question.id, tab));

		if (!updateSideEffect) {
			if (index !== undefined) {
				const filteredNewQuestionsList = newQuestionsList.filter((item) => !item.hide);
				const formattedList = filteredNewQuestionsList.length
					? filteredQuestions
					: newQuestionsList;

				if (selectedQuestion.id === id && newQuestionsList.length) {
					yield put(
						adminPlaylistActions.getQuestion(
							formattedList[getFilteredIndex(formattedList, id) + 1]
								? formattedList[getFilteredIndex(formattedList, id) + 1].id
								: formattedList[getFilteredIndex(formattedList, id) - 1].id,
						),
					);
				}
			} else if (formData.type) {
				yield put(adminPlaylistActions.getQuestion(data.question.id, true));
			} else {
				yield put(adminPlaylistActions.selectPlaylistQuestion(data.question));
			}
		}

		yield put(adminPlaylistActions.updateQuestionSuccess(new Date()));
	} catch (e) {
		yield put(
			errorHandlerActions.handleError(
				e,
				e.response.status === 404 ? 'ignore' : 'notification',
			),
		);
		yield put(adminPlaylistActions.updateQuestionError());
	}
}

export function* handleManageQuestionRequest({ payload }) {
	try {
		const { id, type } = payload || {};
		const requestType = type === 'remove' ? 'DELETE' : 'POST';

		const { data } = yield call(
			processRequest,
			`questions/${id}${type !== 'remove' ? `/${type}` : ''}`,
			requestType,
		);

		const playlist = yield select(playlistData);
		const selectedQuestion = yield select(selectedQuestionData);
		const filteredQuestions = playlist.questions.filter((item) => !item.hide);

		const newQuestionsList =
			type === 'remove' && !data.question
				? playlist.questions.filter((question) => question.id !== id)
				: type === 'revert' || (type === 'remove' && !!data.question)
				? playlist.questions.map((question) =>
						question.id === id ? data.question : question,
				  )
				: [...playlist.questions, data.question];

		const filteredNewQuestionsList = newQuestionsList.filter((item) => !item.hide);

		const formattedList = filteredNewQuestionsList.length
			? filteredQuestions
			: newQuestionsList;

		if (
			selectedQuestion.id === id &&
			newQuestionsList.length &&
			type !== 'revert' &&
			!(!!data.question && type === 'remove')
		) {
			yield put(
				adminPlaylistActions.getQuestion(
					type === 'duplicate'
						? data.question.id
						: formattedList[getFilteredIndex(formattedList, id) + 1]
						? formattedList[getFilteredIndex(formattedList, id) + 1].id
						: formattedList[getFilteredIndex(formattedList, id) - 1].id,
				),
			);
		}

		if (type === 'revert' && id === selectedQuestion.id) {
			yield put(adminPlaylistActions.getQuestion(data.question.id, true));
			yield call(historyReplace, getPlaylistUrl(playlist.id, data.question.id));
		}

		if (type === 'remove' && !!data.question && id === selectedQuestion.id) {
			yield put(adminPlaylistActions.selectPlaylistQuestion(data.question));
		}

		yield put(adminPlaylistActions.setUpdateTime(new Date()));

		yield put(
			notificationActions.createNotification(
				i18n.t(`admin_quiz_editor.manage_confirmation_message.${type}`),
				null,
				false,
				3000,
			),
		);
		yield put(
			adminPlaylistActions.createQuestionSuccess({
				...playlist,
				questions: newQuestionsList,
			}),
		);
	} catch (e) {
		const { response } = e;
		const { data } = response || {};
		yield put(
			errorHandlerActions.handleError(e, 'notification', {
				code: 422,
				message: data.error,
			}),
		);
		yield put(adminPlaylistActions.manageQuestionError());
	}
}

export function* handleChangeQuestionPositionRequest({ payload }) {
	const { data: params } = payload || {};
	const { playlistId, questionId, position, errorCallback } = params || {};

	try {
		const { data } = yield call(
			processRequest,
			`admin/playlist_sections/${playlistId}/questions/${questionId}`,
			'PATCH',
			{
				question: { position },
			},
		);

		const playlist = yield select(playlistData);

		const newQuestionsList = playlist.questions.filter(
			(item) => item.id.toString() !== questionId.toString(),
		);
		newQuestionsList.splice(position - 1, 0, data.question);
		yield put(adminPlaylistActions.setQuestionsList(newQuestionsList));
		yield put(adminPlaylistActions.setUpdateTime(new Date()));
	} catch (e) {
		const { response } = e;
		const { data } = response || {};
		errorCallback && errorCallback();
		yield put(
			errorHandlerActions.handleError(e, 'notification', {
				code: 422,
				message: data.error,
			}),
		);
	}
}

export function* handlePreviewPlaylistRequest({ payload }) {
	try {
		const { playlistId, questionId } = payload || {};

		const { data } = yield call(
			processRequest,
			`admin/playlist_sections/quizzes/${playlistId}/preview`,
			'GET',
			{
				question_id: questionId,
			},
		);

		data.correct_answers_count = 0;
		data.wrong_answers_count = 0;

		yield put(exerciseActions.getExerciseSuccess(data));
		yield put(adminPlaylistActions.previewPlaylistSuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(adminPlaylistActions.previewPlaylistError());
	}
}

export function* handlePublishPlaylistRequest({ payload }) {
	try {
		const { playlistId, questionId } = payload || {};

		yield call(processRequest, `admin/playlist_sections/quizzes/${playlistId}/publish`, 'POST');
		yield put(
			notificationActions.createNotification(
				i18n.t('admin_quiz_editor.publish_notification'),
				null,
				false,
				3000,
			),
		);

		yield put(adminPlaylistActions.getPlaylistRequest(playlistId, questionId));
		yield put(adminPlaylistActions.publishPlaylistSuccess());
		yield put(adminPlaylistActions.clearPlaylistState());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(adminPlaylistActions.publishPlaylistError());
	}
}

export function* handleCreateQuestionInstructionRequest({ payload }) {
	try {
		const { playlistId, questionId, data: formData } = payload || {};

		const {
			data: { question_id: newQuestionId },
		} = yield call(
			processRequest,
			`admin/questions/${questionId}/question_instructions`,
			'POST',
			formData,
		);

		yield put(adminPlaylistActions.createQuestionInstructionSuccess());

		const {
			data: { playlist_section },
		} = yield call(processRequest, `admin/playlist_sections/quizzes/${playlistId}`);
		yield put(adminPlaylistActions.getPlaylistSuccess(playlist_section));

		if (newQuestionId !== questionId) {
			const { data } = yield call(processRequest, `questions/${newQuestionId}`);
			yield put(adminPlaylistActions.selectPlaylistQuestion(data.question));
			yield call(historyReplace, getPlaylistUrl(playlistId, newQuestionId, 'instruction'));
		}

		const { data } = yield call(processRequest, `questions/${newQuestionId || questionId}`);
		yield put(adminPlaylistActions.selectPlaylistQuestion(data.question));

		yield put(adminPlaylistActions.setUpdateTime(new Date()));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(adminPlaylistActions.createQuestionInstructionError());
	}
}

export function* handleUpdateQuestionInstructionRequest({ payload }) {
	try {
		const { playlistId, questionId, instructionId, data: formData } = payload || {};

		const {
			data: { question_id: newQuestionId },
		} = yield call(
			processRequest,
			`admin/questions/${questionId}/question_instructions/${instructionId}`,
			'PUT',
			formData,
		);

		yield put(adminPlaylistActions.updateQuestionInstructionSuccess());

		const {
			data: { playlist_section },
		} = yield call(processRequest, `admin/playlist_sections/quizzes/${playlistId}`);
		yield put(adminPlaylistActions.getPlaylistSuccess(playlist_section));

		if (newQuestionId !== questionId) {
			const { data } = yield call(processRequest, `questions/${newQuestionId}`);
			yield put(adminPlaylistActions.selectPlaylistQuestion(data.question));
			yield call(historyReplace, getPlaylistUrl(playlistId, newQuestionId, 'instruction'));
		}

		yield put(adminPlaylistActions.setUpdateTime(new Date()));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(adminPlaylistActions.updateQuestionInstructionError());
	}
}

export function* handleDeleteQuestionInstructionRequest({ payload }) {
	try {
		const { playlistId, questionId, instructionId } = payload || {};

		yield call(
			processRequest,
			`admin/questions/${questionId}/question_instructions/${instructionId}`,
			'DELETE',
		);

		yield put(adminPlaylistActions.deleteQuestionInstructionSuccess());

		const {
			data: { playlist_section },
		} = yield call(processRequest, `admin/playlist_sections/quizzes/${playlistId}`);
		yield put(adminPlaylistActions.getPlaylistSuccess(playlist_section));

		const { data } = yield call(processRequest, `questions/${questionId}`);
		yield put(adminPlaylistActions.selectPlaylistQuestion(data.question));

		yield put(adminPlaylistActions.setUpdateTime(new Date()));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(adminPlaylistActions.deleteQuestionInstructionError());
	}
}

export function* handleGenerateInstructionAudioRequest({ payload }) {
	try {
		const { playlistId, questionId, messageIds } = payload || {};

		yield call(
			processRequest,
			`admin/questions/${questionId}/question_instructions/generate_audio`,
			'POST',
			{ ids: messageIds },
		);

		yield delay(1000);

		yield put(adminPlaylistActions.generateInstructionAudioSuccess());

		const {
			data: { playlist_section },
		} = yield call(processRequest, `admin/playlist_sections/quizzes/${playlistId}`);
		yield put(adminPlaylistActions.getPlaylistSuccess(playlist_section));

		const { data } = yield call(processRequest, `questions/${questionId}`);
		yield put(adminPlaylistActions.selectPlaylistQuestion(data.question));

		yield put(adminPlaylistActions.setUpdateTime(new Date()));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(adminPlaylistActions.generateInstructionAudioError());
	}
}

export function* handleDeleteInstructionAudioRequest({ payload }) {
	try {
		const { questionId } = payload || {};

		yield call(
			processRequest,
			`admin/questions/${questionId}/question_instructions/delete_audio`,
			'POST',
		);

		yield delay(1000);

		yield put(adminPlaylistActions.deleteInstructionAudioSuccess());

		const { data } = yield call(processRequest, `questions/${questionId}`);
		yield put(adminPlaylistActions.selectPlaylistQuestion(data.question));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(adminPlaylistActions.deleteInstructionAudioError());
	}
}
