import { activationCodeActionTypes } from './Constants';

const initialState = {
	loading: false,
	activationLoading: false,
	activationCode: null,
	initialValues: {
		code: null
	},
	error: null,
};

export default function activationCodeReducer(state = initialState, action) {
	const { type, payload } = action;
	const { code, data, error } = payload || {};

	switch (type) {
		case activationCodeActionTypes.VALIDATE_ACTIVATION_CODE_REQUEST:
			return {
				loading: true,
			};

		case activationCodeActionTypes.VALIDATE_ACTIVATION_CODE_SUCCESS:

			return {
				...state,
				...data,
				loading: false,
			};

		case activationCodeActionTypes.VALIDATE_ACTIVATION_CODE_ERROR:
			return {
				...state,
				loading: false,
				initialValues: data,
				activationCode: code,
				error
			};

		case activationCodeActionTypes.ACTIVATE_PACKAGE_REQUEST:
			return {
				...state,
				activationLoading: true,
			};

		case activationCodeActionTypes.ACTIVATE_PACKAGE_SUCCESS:
		case activationCodeActionTypes.ACTIVATE_PACKAGE_ERROR:
			return {
				...state,
				activationLoading: false,
			};

		default:
			return state;
	}
}
