import keyMirror from 'keymirror';
import methods from '../../images/illustrations/proposition/methods.svg';
import options from '../../images/illustrations/proposition/options.svg';
import content from '../../images/illustrations/proposition/content.svg';
import tests from '../../images/illustrations/proposition/tests.svg';
import video from '../../images/illustrations/proposition/video.svg';
import tutors from '../../images/illustrations/proposition/tutors.svg';
import i18n from 'i18next';
import { BRAND_NAME } from '../../services/Constants';

export const packagesActionTypes = keyMirror({
	GET_CHOOSE_PACKAGE: null,
	GET_CHOOSE_PACKAGE_SUCCESS: null,

	CANCEL_SUBSCRIPTION_REQUEST: null,
	CANCEL_SUBSCRIPTION_SUCCESS: null,
	CANCEL_SUBSCRIPTION_ERROR: null,

	TOGGLE_CANCEL_MEMBERSHIP_MODAL: null,
	TOGGLE_REACTIVATE_SUBSCRIPTION_MODAL: null,

	CHOOSE_FREE_PACKAGE_REQUEST: null,
	CHOOSE_FREE_PACKAGE_ERROR: null,

	CLEAN_PACKAGES_STATE: null,

	REACTIVATE_SUBSCRIPTION_REQUEST: null,
	REACTIVATE_SUBSCRIPTION_SUCCESS: null,
	REACTIVATE_SUBSCRIPTION_ERROR: null,
});

export const PROPOSITION = [
	{
		title: i18n.t('packages.propositions_core.proposition_1.title'),
		desc: i18n.t('packages.propositions_core.proposition_1.description'),
		image: methods,
	},
	{
		title: i18n.t('packages.propositions_core.proposition_2.title'),
		desc: i18n.t('packages.propositions_core.proposition_2.description'),
		image: options,
	},
	{
		title: i18n.t('packages.propositions_core.proposition_3.title'),
		desc: i18n.t('packages.propositions_core.proposition_3.description'),
		image: content,
	},
];

export const PROPOSITION_NEXT = [
	{
		title: i18n.t('packages.propositions_next.proposition_1.title'),
		desc: i18n.t('packages.propositions_next.proposition_1.description'),
		image: tests,
	},
	{
		title: i18n.t('packages.propositions_next.proposition_2.title'),
		desc: i18n.t('packages.propositions_next.proposition_2.description'),
		image: video,
	},
	{
		title: i18n.t('packages.propositions_next.proposition_3.title'),
		desc: i18n.t('packages.propositions_next.proposition_3.description'),
		image: methods,
	},
	{
		title: i18n.t('packages.propositions_next.proposition_4.title'),
		desc: i18n.t('packages.propositions_next.proposition_4.description'),
		image: tutors,
	},
];

export const getUspSections = (t) => [
	{
		title: t('packages.usp_section.usp_1.title'),
		description: t('packages.usp_section.usp_1.description'),
	},
	{
		title: t('packages.usp_section.usp_2.title'),
		description: t('packages.usp_section.usp_2.description'),
	},
	{
		title: t('packages.usp_section.usp_3.title'),
		description: t('packages.usp_section.usp_3.description', { brandName: BRAND_NAME }),
	},
];

export const getSelectedPackage = (packages, selectedPackageId) =>
	packages
		.filter((item) => item.price)
		.filter((el) => el.id === selectedPackageId)
		.map(({ id, identifier, name }) => ({
			id,
			identifier,
			name,
		}))[0];
