import { processRequest } from '../../../../services/Api';
import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import i18n from "i18next";
import { userRoleModalActionTypes } from './Constants';
import { callbackHandler } from './Selectors';
import * as userRolesModalActions from './Actions';
import * as errorHandlerActions from '../../../../components/errorHandler/Actions';
import * as notificationActions from "../../../../components/notification/Actions";

export default function* () {
	yield all([
		yield takeLatest(userRoleModalActionTypes.GET_USER_ROLES_REQUEST, handleGetUserRoleRequest),
		yield takeLatest(userRoleModalActionTypes.SET_USER_ROLES_REQUEST, handleSetUserRoleRequest),
	]);
}

export function* handleGetUserRoleRequest({ payload }) {
	try {
		const { userId } = payload;

		const respData = {};

		const { data } = yield call(processRequest, `admin/users/${userId}/user_permission`);
		respData.infoData = data;
		const { data: publishersData } = yield call(processRequest, 'public/publishers');
		respData.publishers = publishersData.publishers;

		yield put(userRolesModalActions.getUserRolesSuccess(respData));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(userRolesModalActions.getUserRolesError());
	}
}

export function* handleSetUserRoleRequest({ payload }) {
	try {
		const { userId, data: formData } = payload;
		const callback = yield select(callbackHandler);
		yield call(processRequest, `admin/users/${userId}/user_permission`, 'PUT', formData);

		yield put(userRolesModalActions.setUserRolesSuccess());

		const roles = Object.keys(formData.roles)
			.reduce((output, key) => {
				if(formData.roles[key]) (output[key] = formData.roles[key]);

				return output;
			}, {});

		if(callback) callback(userId, { roles: Object.keys(roles) });

		yield put(
			notificationActions.createNotification(
				i18n.t('general.saved_changes'),
				null,
				false,
				3000,
			),
		);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(userRolesModalActions.setUserRolesError());
	}
}
