import { onboardingActionTypes } from './Constants';

export function saveGradeRequest(data) {
	return {
		type: onboardingActionTypes.SAVE_GRADE_REQUEST,
		payload: {
			data,
		},
	};
}

export function saveGradeSuccess(data) {
	return {
		type: onboardingActionTypes.SAVE_GRADE_SUCCESS,
		payload: {
			data,
		},
	};
}

export function saveGradeError() {
	return {
		type: onboardingActionTypes.SAVE_GRADE_ERROR,
	};
}

export function getGradesRequest() {
	return {
		type: onboardingActionTypes.GET_GRADES_REQUEST,
	};
}

export function getGradesSuccess(data) {
	return {
		type: onboardingActionTypes.GET_GRADES_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getGradesError() {
	return {
		type: onboardingActionTypes.GET_GRADES_ERROR,
	};
}

export function savePasswordRequest(password, redirectPath) {
	return {
		type: onboardingActionTypes.SAVE_PASSWORD_REQUEST,
		payload: {
			password,
			redirectPath,
		},
	};
}

export function savePasswordSuccess() {
	return {
		type: onboardingActionTypes.SAVE_PASSWORD_SUCCESS,
	};
}

export function savePasswordError() {
	return {
		type: onboardingActionTypes.SAVE_PASSWORD_ERROR,
	};
}

export function saveChildNameRequest(childName, redirectPath) {
	return {
		type: onboardingActionTypes.SAVE_CHILD_NAME_REQUEST,
		payload: {
			childName,
			redirectPath,
		},
	};
}

export function saveChildNameSuccess() {
	return {
		type: onboardingActionTypes.SAVE_CHILD_NAME_SUCCESS,
	};
}

export function saveChildNameError() {
	return {
		type: onboardingActionTypes.SAVE_CHILD_NAME_ERROR,
	};
}

export function setReferenceChanel(referenceChanel, redirectPath) {
	return {
		type: onboardingActionTypes.SET_REFERENCE_CHANEL,
		payload: { referenceChanel, redirectPath },
	};
}

export function setReferenceChanelSuccess() {
	return {
		type: onboardingActionTypes.SET_REFERENCE_CHANEL_SUCCESS,
	};
}

export function setReferenceChanelError() {
	return {
		type: onboardingActionTypes.SET_REFERENCE_CHANEL_ERROR,
	};
}
