import { manageSubcategoryActionTypes } from './Constants';

export function toggleManageSubcategoryModal({ open, subcategoryId, category, subject }) {
	return {
		type: manageSubcategoryActionTypes.TOGGLE_ADMIN_MANAGE_SUBCATEGORY_MODAL,
		payload: {
			open,
			subcategoryId,
			category,
			subject,
		},
	};
}

export function manageSubcategoryRequest({ formData, subcategoryId, categoryId, callback }) {
	return {
		type: manageSubcategoryActionTypes.MANAGE_ADMIN_SUBCATEGORY_REQUEST,
		payload: {
			formData,
			subcategoryId,
			categoryId,
			callback,
		},
	};
}

export function manageSubcategorySuccess() {
	return {
		type: manageSubcategoryActionTypes.MANAGE_ADMIN_SUBCATEGORY_SUCCESS,
	};
}

export function manageSubcategoryError(error) {
	return {
		type: manageSubcategoryActionTypes.MANAGE_ADMIN_SUBCATEGORY_ERROR,
		payload: {
			error,
		},
	};
}

export function cleanManageSubcategoryState() {
	return {
		type: manageSubcategoryActionTypes.CLEAN_ADMIN_MANAGE_SUBCATEGORY_STATE,
	};
}

export function getSubcategoryDataRequest({ subcategoryId }) {
	return {
		type: manageSubcategoryActionTypes.GET_ADMIN_SUBCATEGORY_DATA_REQUEST,
		payload: { subcategoryId },
	};
}

export function getSubcategoryDataSuccess(subcategoryData) {
	return {
		type: manageSubcategoryActionTypes.GET_ADMIN_SUBCATEGORY_DATA_SUCCESS,
		payload: {
			subcategoryData,
		},
	};
}

export function getSubcategoryDataError(error) {
	return {
		type: manageSubcategoryActionTypes.GET_ADMIN_SUBCATEGORY_DATA_ERROR,
		payload: {
			error,
		},
	};
}
