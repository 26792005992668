/* eslint-disable no-lonely-if */
import { processRequest } from '../../services/Api';
import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import { getUserId } from './Selectors';
import { onboardingActionTypes } from './Constants';
import { historyPush } from '../../services/History';
import * as onboardingActions from './Actions';
import * as errorHandlerActions from '../errorHandler/Actions';
import * as sharedActions from '../../shared/Actions';

export default function* () {
	yield all([
		yield takeLatest(onboardingActionTypes.GET_GRADES_REQUEST, handleGetGradesRequest),
		yield takeLatest(onboardingActionTypes.SAVE_GRADE_REQUEST, handleSaveGradeRequest),
		yield takeLatest(onboardingActionTypes.SAVE_PASSWORD_REQUEST, handleSavePasswordRequest),
		yield takeLatest(onboardingActionTypes.SAVE_CHILD_NAME_REQUEST, handleSaveChildNameRequest),
		yield takeLatest(onboardingActionTypes.SET_REFERENCE_CHANEL, handleSetReferenceChanel),
	]);
}

export function* handleGetGradesRequest() {
	try {
		const { data } = yield call(processRequest, `admin/grades`);

		yield put(onboardingActions.getGradesSuccess(data.grades));
	} catch (e) {
		yield put(onboardingActions.getGradesError());
		yield put(errorHandlerActions.handleError(e));
	}
}

export function* handleSaveGradeRequest({ payload }) {
	try {
		const userId = yield select(getUserId);
		const { data } = payload;
		const { grade } = data;

		yield call(processRequest, `users/${userId}`, 'PUT', {
			user: { grade_id: grade },
		});

		yield call(processRequest, `users/${userId}`, 'PUT', {
			user: { onboarding_completed: true },
		});

		yield put(sharedActions.getUserDataRequest());

		yield put(onboardingActions.saveGradeSuccess());
		yield call(historyPush, '/');
	} catch (e) {
		yield put(onboardingActions.saveGradeError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleSavePasswordRequest({ payload }) {
	try {
		const { password, redirectPath } = payload;

		yield call(processRequest, 'set_pwd', 'PATCH', { password });

		yield call(historyPush, redirectPath);

		yield put(onboardingActions.savePasswordSuccess());
	} catch (e) {
		yield put(onboardingActions.savePasswordError());
		yield put(errorHandlerActions.handleError(e));
	}
}

export function* handleSaveChildNameRequest({ payload }) {
	try {
		const userId = yield select(getUserId);

		const { childName: child_name, redirectPath } = payload;

		yield call(processRequest, `users/${userId}`, 'PUT', { user: { child_name } });

		yield call(historyPush, redirectPath);

		yield put(onboardingActions.saveChildNameSuccess());
	} catch (e) {
		yield put(onboardingActions.saveChildNameError());
		yield put(errorHandlerActions.handleError(e));
	}
}

export function* handleSetReferenceChanel({ payload }) {
	try {
		const userId = yield select(getUserId);

		const { referenceChanel: reference_channel, redirectPath } = payload;

		yield call(processRequest, `users/${userId}`, 'PUT', {
			user: { account: { reference_channel } },
		});

		yield call(historyPush, redirectPath);

		yield put(onboardingActions.setReferenceChanelSuccess());
	} catch (e) {
		yield put(onboardingActions.setReferenceChanelError());
		yield put(errorHandlerActions.handleError(e));
	}
}
