import keyMirror from 'keymirror';

export const chooseProfileImageModalActionTypes = keyMirror({
	TOGGLE_CHOOSE_PROFILE_IMAGE_MODAL: null,

	SET_AVATAR_COLOR: null,
	SET_AVATAR_COLOR_SUCCESS: null,
	SET_AVATAR_COLOR_ERROR: null,

	GET_PREFERRED_COLORS: null,
	GET_PREFERRED_COLORS_SUCCESS: null,
	GET_PREFERRED_COLORS_ERROR: null,
});
