import { put, call, takeLatest, all, select } from 'redux-saga/effects';
import moment from 'moment';
import { processRequest } from '../../services/Api';
import { activityActionTypes } from './Constants';
import { getReportActivity } from './Selectors';
import { isEmptyReportActivity } from './Helpers';
import * as activityActions from './Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			activityActionTypes.GET_REPORT_ACTIVITY_REQUEST,
			handleReportActivityRequest,
		),
		yield takeLatest(
			activityActionTypes.GET_PREVIOUS_REPORT_ACTIVITY_REQUEST,
			handlePreviousReportActivityRequest,
		),
		yield takeLatest(
			activityActionTypes.GET_NEXT_REPORT_ACTIVITY_REQUEST,
			handleNextReportActivityRequest,
		),
		yield takeLatest(activityActionTypes.GET_PLAYED_DAYS_REQUEST, handlePlayedDaysRequest),
	]);
}

export function* handleReportActivityRequest({ payload }) {
	const { date, weeks, callback } = payload || {};

	try {
		let activityData;

		const { data: reportData1 } = yield call(processRequest, `users/activity_report`, 'GET', {
			date,
			weeks,
			sort_direction: 'desc',
		});
		activityData = reportData1;

		if (isEmptyReportActivity(activityData.activity) && !activityData.started_date) {
			const weeksCount = 12;
			const { data: reportData2 } = yield call(
				processRequest,
				`users/activity_report`,
				'GET',
				{
					date,
					weeks: weeksCount,
					sort_direction: 'desc',
				},
			);
			yield put(
				activityActions.setPreviousDate(
					moment(date).subtract(weeksCount, 'weeks').format('YYYY-MM-DD'),
				),
			);
			activityData = reportData2;
		}

		yield put(
			activityActions.getReportActivitySuccess(
				activityData.activity,
				activityData.started_date,
			),
		);

		callback && callback();
	} catch (e) {
		yield put(activityActions.getReportActivityError(e));
		yield put(errorHandlerActions.handleError(e));
	}
}

export function* handlePreviousReportActivityRequest({ payload }) {
	const { date } = payload || {};

	try {
		yield put(activityActions.setPreviousDate(date));

		const { data } = yield call(processRequest, `users/activity_report`, 'GET', {
			date,
			sort_direction: 'desc',
		});

		const activity = yield select(getReportActivity);

		yield put(
			activityActions.getReportActivitySuccess(
				{ ...activity, ...data.activity },
				data.started_date,
			),
		);
	} catch (e) {
		yield put(activityActions.getReportActivityError(e));
		yield put(errorHandlerActions.handleError(e));
	}
}

export function* handleNextReportActivityRequest({ payload }) {
	const { date, callback } = payload || {};

	try {
		yield put(activityActions.setNextDate(date));

		const { data } = yield call(processRequest, `users/activity_report`, 'GET', {
			date,
			sort_direction: 'desc',
		});

		const activity = yield select(getReportActivity);

		yield put(
			activityActions.getReportActivitySuccess(
				{ ...data.activity, ...activity },
				data.started_date,
			),
		);

		callback && callback();
	} catch (e) {
		yield put(activityActions.getReportActivityError(e));
		yield put(errorHandlerActions.handleError(e));
	}
}

export function* handlePlayedDaysRequest({ payload }) {
	const { date } = payload || {};

	try {
		const { data } = yield call(processRequest, `users/played_days`, 'GET', date && { date });
		yield put(activityActions.getPlayedDaysSuccess(data));
	} catch (e) {
		yield put(activityActions.getPlayedDaysError(e));
		yield put(errorHandlerActions.handleError(e));
	}
}
