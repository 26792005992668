import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { clearSubmitErrors, stopSubmit } from 'redux-form';
import i18n from 'i18next';
import { processRequest } from '../../services/Api';
import { IS_INTERNATIONAL_TYPE } from '../../services/Constants';
import { setGTMData } from '../../services/Helper';
import { historyReplace } from '../../services/History';
import { DEFAULT_COUNTRY_ID, findElement, profileTypes, openExitSurveyLink } from './Constants';
import { getUserId, getUserData, getUserSubscription } from './Selectors';
import { getEducationDetail } from '../signUp/Selectors';
import * as profileActions from './Actions';
import * as notificationActions from '../../components/notification/Actions';
import * as sharedActions from '../../shared/Actions';
import * as signUpActions from '../signUp/Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(profileTypes.SET_AVATAR_IMAGE, handleSetAvatarRequest),
		yield takeLatest(profileTypes.SET_PERSONAL_DATA_REQUEST, handleSetPersonalDataRequest),
		yield takeLatest(profileTypes.SET_NEW_PASSWORD, handleChangePasswordRequest),
		yield takeLatest(profileTypes.CHECK_EMAIL, handleCheckEmailRequest),
		yield takeLatest(profileTypes.SET_NEW_EMAIL, handleChangeEmailRequest),
		yield takeLatest(profileTypes.DELETE_ACCOUNT_REQUEST, handleDeleteAccountRequest),
		yield takeLatest(profileTypes.CHECK_PUBLIC_NAME, handleCheckPublicName),
		yield takeLatest(profileTypes.EMAIL_PREFERENCES_REQUEST, handleEmailPreferences),
		yield takeLatest(profileTypes.GET_USER_DETAILS, handleGetUserDetailsRequest),
		yield takeLatest(
			profileTypes.GET_NOTIFICATION_PREFERENCES_REQUEST,
			handleGetNotificationPreferencesRequest,
		),
		yield takeLatest(
			profileTypes.UPDATE_NOTIFICATION_PREFERENCES_REQUEST,
			handleUpdateNotificationPreferencesRequest,
		),
		yield takeLatest(
			profileTypes.GET_USER_EDUCATION_DETAILS,
			handleGetUserEducationDetailsRequest,
		),
		yield takeLatest(profileTypes.UPDATE_USER_LOCALE_REQUEST, handleUpdateUserLocaleRequest),
		yield takeLatest(profileTypes.GET_USER_SUBSCRIPTION, handleGetUserSubscriptionRequest),
		yield takeLatest(profileTypes.CANCEL_SUBSCRIPTION_REQUEST, handleCancelSubscriptionRequest),
	]);
}

export function* handleGetUserDetailsRequest() {
	try {
		const userId = yield select(getUserId);

		const { data } = yield call(processRequest, `users/${userId}`);

		yield put(profileActions.getUserDetailsSuccess(data.user));
	} catch (e) {
		yield put(profileActions.getUserDetailsError(e));
		yield put(errorHandlerActions.handleError(e));
	}
}

export function* handleSetAvatarRequest(action) {
	try {
		const userId = yield select(getUserId);
		const { publicLink } = action.payload;
		yield call(processRequest, `users/${userId}`, 'PUT', {
			user: {
				remote_profile_image_url: publicLink,
			},
		});

		yield put(sharedActions.getUserDataRequest());
		yield put(profileActions.setAvatarSuccess());
	} catch (e) {
		yield put(profileActions.setAvatarError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleCheckPublicName(action) {
	try {
		const { username } = action.payload;

		yield call(processRequest, `users/exists?user_name=${username}`);

		yield put(
			stopSubmit('personalInfoForm', {
				username: i18n.t('profile_settings.user_name_exist_notification'),
			}),
		);
		yield put(profileActions.checkPublicNameRes());
	} catch (e) {
		yield put(clearSubmitErrors('personalInfoForm', 'username'));
		if (e.response.status !== 404) {
			yield put(errorHandlerActions.handleError(e, 'notification'));
		}
		yield put(profileActions.checkPublicNameRes());
	}
}

export function* handleSetPersonalDataRequest(action) {
	try {
		const { first_name, last_name, child_name, grade_id } = action.payload;
		const userData = yield select(getUserData);

		yield call(processRequest, `users/${userData.id}`, 'PUT', {
			user: {
				first_name,
				last_name,
				child_name,
				grade_id,
			},
		});

		yield put(profileActions.changePersonalDataSuccess());

		yield put(sharedActions.getUserDataRequest());

		yield put(
			notificationActions.createNotification(
				i18n.t('general.saved_changes'),
				null,
				false,
				3000,
			),
		);
	} catch (e) {
		yield put(profileActions.changePersonalDataError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleCheckEmailRequest(action) {
	try {
		const { email, admin } = action.payload;

		yield call(processRequest, `public/user_accounts?email=${email}`);

		yield put(
			stopSubmit(admin ? 'personalInfoForm' : 'changeEmailForm', {
				email: i18n.t('profile_settings.email_exist_notification'),
			}),
		);
	} catch (e) {
		const { admin } = action.payload;
		const formName = admin ? 'personalInfoForm' : 'changeEmailForm';
		if (e.response.status !== 404) {
			yield put(errorHandlerActions.handleError(e, 'notification'));
		}
		yield put(clearSubmitErrors(formName, 'email'));
	}
}

export function* handleChangeEmailRequest(action) {
	try {
		const { email, password } = action.payload;
		const userId = yield select(getUserId);
		const { data } = yield call(processRequest, `users/${userId}`, 'PUT', {
			user: {
				account: {
					email,
					current_password: password,
				},
			},
		});

		yield put(profileActions.changeEmailSuccess(data.auth_token));

		yield put(sharedActions.getUserDataRequest());

		yield put(
			notificationActions.createNotification(
				i18n.t('profile_settings.email_changed_notification'),
				null,
				false,
				3000,
			),
		);
	} catch (e) {
		yield put(stopSubmit('changeEmailForm', { password: e.response.data.error }));
		yield put(profileActions.changeEmailError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleChangePasswordRequest(action) {
	try {
		const { newPassword, oldPassword } = action.payload;
		const userId = yield select(getUserId);
		yield call(processRequest, `users/${userId}`, 'PUT', {
			user: {
				account: {
					password: newPassword,
					current_password: oldPassword,
				},
			},
		});

		yield put(sharedActions.getUserDataRequest());

		yield put(
			notificationActions.createNotification(
				i18n.t('profile_settings.password_changed_notification'),
				null,
				false,
				3000,
			),
		);

		yield put(profileActions.changePasswordSuccess());
	} catch (e) {
		if (e.response.status === 403) {
			yield put(stopSubmit('changePasswordForm', { oldPassword: e.response.data.error }));
		} else if (e.response.status === 422) {
			yield put(stopSubmit('changePasswordForm', { newPassword: e.response.data.error }));
		}
		yield put(profileActions.changePasswordError());
	}
}

export function* handleEmailPreferences(action) {
	try {
		const { status } = action.payload;
		const userId = yield select(getUserId);

		yield call(processRequest, `users/${userId}`, 'PUT', {
			user: {
				user_configuration: {
					mail_disabled: status,
				},
			},
		});

		yield put(sharedActions.getUserDataRequest());
		yield put(
			notificationActions.createNotification(
				i18n.t('profile_settings.email_preferences_changed'),
				null,
				false,
				3000,
			),
		);
	} catch (e) {
		yield put(sharedActions.getUserDataRequest());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleDeleteAccountRequest(action) {
	try {
		const userId = yield select(getUserId);
		const { password } = action.payload;

		yield call(processRequest, `users/${userId}`, 'DELETE', { password });

		yield put(profileActions.deleteAccountSuccess());

		yield call(historyReplace, '/');

		yield put(
			notificationActions.createNotification(
				i18n.t('profile_settings.deleted_account_notification'),
				null,
				false,
				5000,
			),
		);
	} catch (e) {
		yield put(profileActions.deleteAccountError());
		yield put(
			errorHandlerActions.handleError(e, 'notification', {
				code: 403,
				message: i18n.t('profile_settings.confirm_delete_account.error_message'),
			}),
		);
	}
}

export function* handleGetNotificationPreferencesRequest() {
	try {
		const { data } = yield call(processRequest, 'users/notification_preferences');

		yield put(profileActions.getNotificationPreferencesSuccess(data));
	} catch (e) {
		yield put(profileActions.getNotificationPreferencesError());
		yield put(errorHandlerActions.handleError(e));
	}
}

export function* handleUpdateNotificationPreferencesRequest(action) {
	try {
		const { status, term, section } = action.payload;
		const requestPayload = {
			user_notification_preference: {
				enabled: status,
				term,
				channel: section,
			},
		};

		yield call(
			processRequest,
			'users/notification_preferences/toggle',
			'PATCH',
			requestPayload,
		);
	} catch (e) {
		yield put(profileActions.updateNotificationPreferencesError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleGetUserEducationDetailsRequest(action) {
	try {
		const { userDetails, updateEducationForm } = action.payload;
		const {
			country_id,
			school_track_id: schoolTrackId,
			grade_id: gradeId,
			education_type_id,
			is_teacher,
			school_id,
			school_name,
		} = userDetails || {};

		const educationDetail = yield select(getEducationDetail);
		const educationInitialDetails = {
			country: { code: 'default', name: i18n.t('general.country') },
		};
		let educationData = null;

		const { data: countriesData } = yield call(processRequest, 'countries');

		const { countries } = countriesData || {};
		const selectedCountry = countries.filter((country) => country.default)[0];
		const selectedCountryId =
			country_id ||
			(selectedCountry && selectedCountry.id) ||
			(!IS_INTERNATIONAL_TYPE && DEFAULT_COUNTRY_ID);

		if (selectedCountryId) {
			const { data: educationDetailsData } = yield call(
				processRequest,
				`countries/${selectedCountryId}/education_types`,
			);
			educationData = educationDetailsData;
			const { education_types } = educationData || {};
			const getInitialCountry = () => countries.find((item) => item.id === selectedCountryId);
			const getInitialEducationType = () =>
				education_types.find((item) => item.id === education_type_id);
			const getInitialLevel = () =>
				!is_teacher &&
				schoolTrackId &&
				findElement(
					educationInitialDetails.education_type,
					'school_track_id',
					schoolTrackId,
				);
			const getInitialClass = () =>
				!updateEducationForm &&
				!is_teacher &&
				gradeId &&
				findElement(educationInitialDetails.level, 'grade_id', gradeId);

			educationInitialDetails.country = yield call(getInitialCountry);
			educationInitialDetails.education_type = yield call(getInitialEducationType);
			educationInitialDetails.level = yield call(getInitialLevel);
			educationInitialDetails.class = yield call(getInitialClass);
			educationInitialDetails.school = {
				id: school_id,
				name: school_name,
			};
		}
		yield put(
			signUpActions.setInitialEducationInfo({
				levels:
					(educationInitialDetails.education_type &&
						educationInitialDetails.education_type.options) ||
					[],
				education_types: educationData ? educationData.education_types : [],
				classes:
					(educationInitialDetails.level && educationInitialDetails.level.options) || [],
				educationInitialDetails,
				educationDetails: { ...educationDetail, ...educationInitialDetails },
				countries,
			}),
		);
	} catch (e) {
		yield put(profileActions.getUserEducationDetailsError(e));
		yield put(errorHandlerActions.handleError(e));
	}
}

export function* handleUpdateUserLocaleRequest({ payload }) {
	try {
		const { locale } = payload || {};
		const userId = yield select(getUserId);

		yield call(processRequest, `users/${userId}`, 'PUT', {
			user: {
				locale_code: locale,
			},
		});
		if (IS_INTERNATIONAL_TYPE && locale) {
			setGTMData({
				event: 'locale',
				status: 'fromProfileSettings',
				locale,
			});
		}
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleGetUserSubscriptionRequest() {
	try {
		const { data } = yield call(processRequest, 'subscriptions');

		yield put(profileActions.getUserSubscriptionSuccess(data.subscriptions));
	} catch (e) {
		yield put(profileActions.getUserSubscriptionError());
		yield put(errorHandlerActions.handleError(e));
	}
}

export function* handleCancelSubscriptionRequest({ payload }) {
	const { date } = payload;
	try {
		yield call(processRequest, 'subscriptions/cancel', 'DELETE', {
			cancellation_date: date,
		});
		yield put(profileActions.cancelSubscriptionSuccess());
		yield put(profileActions.toggleCancelSubscriptionModal(false));

		const userData = yield select(getUserData);
		const userSubscription = yield select(getUserSubscription);
		const exitSurveyData = {
			email: userData.email,
			name: userData.first_name,
			signupdate: userSubscription.dates.find((item) => item.name === 'Startdatum')?.date,
			id: userData.id,
			education_year: userData.grade.title,
			membership_plan: userSubscription.name,
		};

		yield put(profileActions.getUserSubscription());
		yield call(openExitSurveyLink, exitSurveyData);
	} catch (e) {
		yield put(profileActions.cancelSubscriptionError(e));
		yield put(profileActions.toggleCancelSubscriptionModal(false));
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}
