import keyMirror from 'keymirror';

export const forgotPasswordActionTypes = keyMirror({
	FORGOT_PASSWORD_REQUEST: null,
	FORGOT_PASSWORD_SUCCESS: null,
	FORGOT_PASSWORD_ERROR: null,
	SET_INITIAL_EMAIL: null,
});

export const FORGOT_PASSWORD_FORM = 'forgotPasswordForm';
