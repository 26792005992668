import { setLocalStorageItem } from '../../services/Helper';
import { couponReaderActionTypes } from './Constants';

const initialState = {
	loading: false,
};

export default function couponReducer(state = initialState, action) {
	const { type, payload } = action;
	const { discountCode } = payload || {};

	switch (type) {
		case couponReaderActionTypes.LIST_OPTIONS_REQUEST:
			return {
				...state,
				loading: true,
			};

		case couponReaderActionTypes.LIST_OPTIONS_SUCCESS:
		case couponReaderActionTypes.SET_COUPON_ERROR:
			return {
				...state,
				loading: false,
			};

		case couponReaderActionTypes.VALIDATE_PUBLIC_DISCOUNT_CODE_SUCCESS:
			setLocalStorageItem('discountCode', discountCode);

			return {
				...state,
				loading: false,
			};

		case couponReaderActionTypes.VALIDATE_PUBLIC_DISCOUNT_CODE_ERROR:
			return {
				...state,
				loading: false,
			};

		default:
			return state;
	}
}
