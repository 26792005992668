import { usersOverviewActionTypes } from './Constants';

const initialState = {
	loadingUsers: true,
	users: {},
	appliedFilters: {},
	loadingUserInfo: true,
	userInfo: null,
	loadingUserHistory: true,
	userHistory: [],
	userChargebeeDetails: {},
	submitLoadingUserDetails: false,
	submitLoadingUserSchoolDetails: false,
};

export default function usersOverviewReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data, withoutReloading } = payload || {};
	const { offset } = data || {};

	switch (type) {
		case usersOverviewActionTypes.GET_USERS_REQUEST:
			return {
				...state,
				loadingUsers: !offset && !withoutReloading,
			};
		case usersOverviewActionTypes.GET_USERS_SUCCESS:
			return {
				...state,
				loadingUsers: false,
				users: data,
			};
		case usersOverviewActionTypes.GET_USERS_ERROR:
			return {
				...state,
				loadingUsers: false,
			};

		case usersOverviewActionTypes.CHANGE_USERS_LIST_FILTERS:
			return {
				...state,
				appliedFilters: data.appliedFilters,
			};
		case usersOverviewActionTypes.GET_USER_INFO_REQUEST:
		case usersOverviewActionTypes.GET_USER_CHARGEBEE_INFO_REQUEST:
			return {
				...state,
				loadingUserInfo: !withoutReloading,
				loadingUserInfoContent: !withoutReloading,
			};
		case usersOverviewActionTypes.GET_USER_INFO_SUCCESS:
			return {
				...state,
				loadingUserInfo: false,
				loadingUserInfoContent: false,
				userInfo: data,
			};
		case usersOverviewActionTypes.GET_USER_INFO_ERROR:
		case usersOverviewActionTypes.GET_USER_CHARGEBEE_INFO_ERROR:
			return {
				...state,
				loadingUserInfo: false,
				loadingUserInfoContent: false,
			};
		case usersOverviewActionTypes.GET_USER_CHARGEBEE_INFO_SUCCESS:
			return {
				...state,
				loadingUserInfo: false,
				loadingUserInfoContent: false,
				userChargebeeDetails: data,
			};
		case usersOverviewActionTypes.CHANGE_USER_DETAILS_REQUEST:
			return {
				...state,
				submitLoadingUserDetails: true,
			};
		case usersOverviewActionTypes.CHANGE_USER_DETAILS_SUCCESS:
		case usersOverviewActionTypes.CHANGE_USER_DETAILS_ERROR:
			return {
				...state,
				submitLoadingUserDetails: false,
			};
		case usersOverviewActionTypes.CHANGE_USER_SCHOOL_DETAILS_REQUEST:
			return {
				...state,
				submitLoadingUserSchoolDetails: true,
			};
		case usersOverviewActionTypes.CHANGE_USER_SCHOOL_DETAILS_SUCCESS:
		case usersOverviewActionTypes.CHANGE_USER_SCHOOL_DETAILS_ERROR:
			return {
				...state,
				submitLoadingUserSchoolDetails: false,
			};
		case usersOverviewActionTypes.GET_USER_HISTORY_REQUEST:
			return {
				...state,
				loadingUserHistory: true,
			};
		case usersOverviewActionTypes.GET_USER_HISTORY_SUCCESS:
			return {
				...state,
				loadingUserHistory: false,
				userHistory: data,
			};
		case usersOverviewActionTypes.GET_USER_HISTORY_ERROR:
			return {
				...state,
				loadingUserHistory: false,
			};
		case usersOverviewActionTypes.CLEAR_USER_INFO:
			return {
				...state,
				loadingUserInfo: true,
				userInfo: {},
			};
		default:
			return state;
	}
}
