import { put, call, takeLatest, all, delay } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import i18n from 'i18next';
import { isAuthenticated } from '../../services/AccessToken';
import { processRequest } from '../../services/Api';
import { historyPush, historyReplace } from '../../services/History';
import { activationCodeActionTypes, formatCode } from './Constants';
import * as activationCodeActions from './Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';
import * as sharedActions from '../../shared/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			activationCodeActionTypes.VALIDATE_ACTIVATION_CODE_REQUEST,
			handleValidateActivationCodeRequest,
		),
		yield takeLatest(
			activationCodeActionTypes.ACTIVATE_PACKAGE_REQUEST,
			handleActivatePackageRequest,
		),
		yield takeLatest(
			activationCodeActionTypes.APPLY_ACTIVATION_CODE_REQUEST,
			handleApplyActivationCodeRequest,
		),
	]);
}

export function* handleValidateActivationCodeRequest(action) {
	const { code, navigateFromValidationPage, navigateFromLogin } = action.payload;
	const formattedCode = formatCode(code);
	let isPayingCustomer = false;
	let isTeacher = false;

	try {
		if (isAuthenticated() && !navigateFromLogin) {
			const userInfo = yield call(processRequest, 'get_user_data');
			yield put(sharedActions.getUserDataSuccess(userInfo.data));

			const { is_paying_customer, is_teacher } = userInfo.data || {};
			isPayingCustomer = is_paying_customer;
			isTeacher = is_teacher;
		}
		if (!(isPayingCustomer || isTeacher) || navigateFromLogin) {
			const { data } = yield call(
				processRequest,
				`public/activation_codes/validate?code=${formattedCode}`,
			);
			yield put(activationCodeActions.validateActivationCodeSuccess(data));
			yield call(historyPush, `/confirm-package/${code}`);
		}
	} catch (e) {
		const { error_code: errorCode } = e.response.data || {};
		if (navigateFromLogin && (errorCode === 'paid_user' || errorCode === 'teacher_user')) {
			yield put(
				activationCodeActions.validateActivationCodeError(
					e.response.data,
					{ code: formattedCode },
					code,
					navigateFromValidationPage,
				),
			);
			return;
		}
		if (navigateFromLogin && (errorCode === 'used_code' || errorCode === 'invalid_code')) {
			yield call(historyPush, `/activeren/${formattedCode}`);
			return;
		}
		yield put(
			activationCodeActions.validateActivationCodeError(
				e.response.data,
				{ code: formattedCode },
				code,
				navigateFromValidationPage,
			),
		);
		if (e.response.status === 422) {
			yield call(historyPush, '/activeren');
			yield put(
				stopSubmit('ActivationCodeForm', {
					code: i18n.t('activation_code.invalid_code'),
				}),
			);
		} else {
			yield put(errorHandlerActions.handleError(e, 'notification'));
		}
	}
}

export function* handleActivatePackageRequest(action) {
	try {
		const { codeId } = action.payload;

		yield call(processRequest, `public/activation_codes/${codeId}/activate`, 'POST');
		yield call(historyReplace, '/activation-code-applying');
	} catch (e) {
		yield put(activationCodeActions.activatePackageError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleApplyActivationCodeRequest() {
	try {
		let isActivationCodeApplied = false;

		while (!isActivationCodeApplied) {
			yield delay(1000);
			const { data } = yield call(processRequest, 'get_user_data');
			isActivationCodeApplied = data.is_paying_customer;
		}
		yield call(historyPush, '/success-package-activation');
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield call(historyPush, '/');
	}
}
