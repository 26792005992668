import { userRoleModalActionTypes } from './Constants';

const initialState = {
	open: false,
	loading: true,
	submitLoading: false,
	userId: '',
	infoData: {},
	publishers: [],
	callback: null,
};

export default function userRolesModalReducer(state = initialState, action) {
	const { type, payload } = action;
	const { userId, data, callback } = payload || {};

	switch (type) {
		case userRoleModalActionTypes.TOGGLE_USER_ROLE_MODAL:
			return {
				...state,
				open: !state.open,
				userId: state.open ? state.userId : userId,
				callback: state.open ? state.callback : callback,
			};
		case userRoleModalActionTypes.GET_USER_ROLES_REQUEST:
			return {
				...state,
				loading: true,
			};
		case userRoleModalActionTypes.GET_USER_ROLES_SUCCESS:
			return {
				...state,
				...data,
				loading: false,
			};
		case userRoleModalActionTypes.GET_USER_ROLES_ERROR:
			return {
				...state,
				loading: false,
			};
		case userRoleModalActionTypes.SET_USER_ROLES_REQUEST:
			return {
				...state,
				submitLoading: true,
			};
		case userRoleModalActionTypes.SET_USER_ROLES_SUCCESS:
			return {
				...state,
				submitLoading: false,
				open: false,
			};
		case userRoleModalActionTypes.SET_USER_ROLES_ERROR:
			return {
				...state,
				submitLoading: false,
			};
		default:
			return state;
	}
}
