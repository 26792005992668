import { processRequest } from '../../services/Api';
import { put, call, takeLatest, all } from 'redux-saga/effects';
import { pushNotificationActionTypes } from './Constants';
import * as pushNotificationActions from './Actions';
import * as errorHandlerActions from '../errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			pushNotificationActionTypes.REGISTER_PUSH_NOTIFICATION_REQUEST,
			handleRegisterPushNotificationRequest,
		),
		yield takeLatest(
			pushNotificationActionTypes.DEREGISTER_PUSH_NOTIFICATION_REQUEST,
			handleDeregisterPushNotificationRequest,
		),
	]);
}

export function* handleRegisterPushNotificationRequest(action) {
	const { deviceToken, ignoreError } = action.payload;
	try {
		const requestPayload = {
			notification_device: {
				token: deviceToken,
			},
		};

		yield call(processRequest, '/users/notification_devices', 'POST', requestPayload);
		yield put(pushNotificationActions.registerPushNotificationForDeviceSuccess());
	} catch (e) {
		if (ignoreError) {
			yield put(errorHandlerActions.handleError(e, 'ignore'));
		} else {
			yield put(errorHandlerActions.handleError(e, 'notification'));
		}
		yield put(pushNotificationActions.registerPushNotificationForDeviceError());
	}
}

export function* handleDeregisterPushNotificationRequest(action) {
	const { deviceToken, status, ignoreError } = action.payload;

	try {
		const requestPayload = {
			token: deviceToken,
		};
		yield call(processRequest, '/users/notification_devices/remove', 'DELETE', requestPayload);

		yield put(pushNotificationActions.deregisterPushNotificationForDeviceSuccess(status));
	} catch (e) {
		if (ignoreError) {
			yield put(errorHandlerActions.handleError(e, 'ignore'));
		} else {
			yield put(errorHandlerActions.handleError(e, 'notification'));
		}
		yield put(pushNotificationActions.deregisterPushNotificationForDeviceError());
	}
}
