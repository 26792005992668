import { languageSelectorActionTypes } from './Constants';

export function getAvailableLanguagesRequest() {
	return {
		type: languageSelectorActionTypes.GET_AVAILABLE_LANGUAGES_REQUEST,
	};
}

export function getAvailableLanguagesSuccess(locales) {
	return {
		type: languageSelectorActionTypes.GET_AVAILABLE_LANGUAGES_SUCCESS,
		payload: {
			locales,
		},
	};
}

export function getAvailableLanguagesError() {
	return {
		type: languageSelectorActionTypes.GET_AVAILABLE_LANGUAGES_ERROR,
	};
}
