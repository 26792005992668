import keyMirror from 'keymirror';

export const manageSubcategoryActionTypes = keyMirror({
	TOGGLE_ADMIN_MANAGE_SUBCATEGORY_MODAL: null,

	GET_ADMIN_SUBCATEGORY_DATA_REQUEST: null,
	GET_ADMIN_SUBCATEGORY_DATA_SUCCESS: null,
	GET_ADMIN_SUBCATEGORY_DATA_ERROR: null,

	MANAGE_ADMIN_SUBCATEGORY_REQUEST: null,
	MANAGE_ADMIN_SUBCATEGORY_SUCCESS: null,
	MANAGE_ADMIN_SUBCATEGORY_ERROR: null,

	CLEAN_ADMIN_MANAGE_SUBCATEGORY_STATE: null,
});
