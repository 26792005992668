import i18n from 'i18next';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { processRequest } from '../../../services/Api';
import { payInvoiceModalActionTypes } from './Constants';
import * as errorHandlerActions from '../../errorHandler/Actions';
import * as notificationActions from '../../notification/Actions';
import * as payInvoiceModalActions from './Actions';

export default function* () {
	yield all([
		yield takeLatest(
			payInvoiceModalActionTypes.SEND_INVOICE_TO_PARENT_REQUEST,
			handleSendInvoiceToParentRequest,
		),
	]);
}

export function* handleSendInvoiceToParentRequest({ payload }) {
	try {
		const { email, invoiceId, secret } = payload;

		yield call(processRequest, `public/invoices/${invoiceId}/email`, 'POST', { email, secret });
		yield put(payInvoiceModalActions.sendInvoiceToParentSuccess());
		yield put(
			notificationActions.createNotification(
				i18n.t('modals.parent_request.request_notification'),
				null,
				false,
				5000,
			),
		);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(payInvoiceModalActions.sendInvoiceToParentError());
	}
}
