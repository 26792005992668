import { reportActionTypes } from './Constants';
import _union from 'lodash/union';

const initialState = {
	loading: false,
	playListSection: null,
	playListSections: {},
};

export default function reportReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data, playedDays } = payload || {};

	switch (type) {
		case reportActionTypes.GET_PLAYED_DAYS_SUCCESS:
			return {
				...state,
				playedDays: _union(playedDays, state.playedDays),
			};

		case reportActionTypes.GET_PLAYLIST_SECTION_REPORT_REQUEST:
			return {
				...state,
				loading: true,
			};

		case reportActionTypes.GET_PLAYLIST_SECTION_REPORT_SUCCESS:
			return {
				...state,
				playListSection: data,
				playListSections: { ...state.playListSections, [data.id]: data },
				loading: false,
			};

		case reportActionTypes.GET_PLAYLIST_SECTION_REPORT_ERROR:
			return {
				...state,
				loading: false,
			};

		case reportActionTypes.CLEAR_STATE:
			return {
				...state,
				playListSection: {},
			};

		default:
			return state;
	}
}
