import keyMirror from 'keymirror';
import { ACCESS_TOKEN_EXPIRATION_DAYS, COOKIES } from '../../services/Constants';
import { isSecure, removeCookie, setCookie } from '../../services/Helper';

export const activationCodeActionTypes = keyMirror({
	VALIDATE_ACTIVATION_CODE_REQUEST: null,
	VALIDATE_ACTIVATION_CODE_SUCCESS: null,
	VALIDATE_ACTIVATION_CODE_ERROR: null,

	ACTIVATE_PACKAGE_REQUEST: null,
	ACTIVATE_PACKAGE_SUCCESS: null,
	ACTIVATE_PACKAGE_ERROR: null,

	APPLY_ACTIVATION_CODE_REQUEST: null,
});

export const formatCode = (code) => code.replace(/[-_]/g, '').toLowerCase();
export const ROLES = ['Plus', 'Premium', 'Pro', 'Leraar'];

export const setActivationCodeCookie = (activationCode) => {
	setCookie(COOKIES.activationCode, activationCode, {
		expires: ACCESS_TOKEN_EXPIRATION_DAYS,
		secure: isSecure(),
	});
};

export const removeActivationCodeCookie = () => {
	removeCookie(COOKIES.activationCode);
};

export const activationCodeForm = 'ActivationCodeForm';

