import { adminActivationCodesActionTypes } from './Constants';

export function getActivationCodes(data) {
	return {
		type: adminActivationCodesActionTypes.GET_ACTIVATION_CODES_REQUEST,
		payload: { data },
	};
}

export function getActivationCodesSuccess(data) {
	return {
		type: adminActivationCodesActionTypes.GET_ACTIVATION_CODES_SUCCESS,
		payload: { data },
	};
}

export function getActivationCodesError() {
	return {
		type: adminActivationCodesActionTypes.GET_ACTIVATION_CODES_ERROR,
	};
}

export function changeActivationCodesFilter(data) {
	return {
		type: adminActivationCodesActionTypes.CHANGE_ACTIVATION_CODES_FILTERS,
		payload: { data },
	};
}

export function getActivationCodeInfo(id, withoutReloading) {
	return {
		type: adminActivationCodesActionTypes.GET_ACTIVATION_CODE_INFO_REQUEST,
		payload: { id, withoutReloading },
	};
}

export function getActivationCodeInfoSuccess(data) {
	return {
		type: adminActivationCodesActionTypes.GET_ACTIVATION_CODE_INFO_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getActivationCodeInfoError() {
	return {
		type: adminActivationCodesActionTypes.GET_ACTIVATION_CODE_INFO_ERROR,
	};
}

export function changeActivationCodeState(id, state, reserve, source, history) {
	return {
		type: adminActivationCodesActionTypes.CHANGE_ACTIVATION_CODE_STATUS_REQUEST,
		payload: { id, state, reserve, source, history },
	};
}

export function changeActivationCodeStateSuccess(data, source) {
	return {
		type: adminActivationCodesActionTypes.CHANGE_ACTIVATION_CODE_STATUS_SUCCESS,
		payload: {
			data,
			source,
		},
	};
}

export function changeActivationCodeStateError() {
	return {
		type: adminActivationCodesActionTypes.CHANGE_ACTIVATION_CODE_STATUS_ERROR,
	};
}

export function clearActivationCodeInfo() {
	return {
		type: adminActivationCodesActionTypes.CLEAR_ACTIVATION_CODE_INFO,
	};
}
