import { activityActionTypes } from './Constants';
import _union from 'lodash/union';

const initialState = {
	loading: false,
	activity: {},
	startedDate: '',
	playedDays: [],
	activeDate: new Date(),
	previousDate: null,
	nextDate: null,
};

export default function reportReducer(state = initialState, action) {
	const { type, payload } = action;
	const { activeDate, activity, startedDate, playedDays, previousDate, nextDate } = payload || {};

	switch (type) {
		case activityActionTypes.GET_REPORT_ACTIVITY_REQUEST:
			return {
				...state,
				loading: true,
			};

		case activityActionTypes.GET_REPORT_ACTIVITY_SUCCESS:
			return {
				...state,
				loading: false,
				activity,
				startedDate,
			};

		case activityActionTypes.GET_REPORT_ACTIVITY_ERROR:
			return {
				...state,
				loading: false,
			};

		case activityActionTypes.GET_PLAYED_DAYS_SUCCESS:
			return {
				...state,
				playedDays: _union(playedDays, state.playedDays),
			};

		case activityActionTypes.SET_ACTIVE_DATE:
			return {
				...state,
				activeDate,
			};

		case activityActionTypes.SET_PREVIOUS_DATE:
			return {
				...state,
				previousDate,
			};

		case activityActionTypes.SET_NEXT_DATE:
			return {
				...state,
				nextDate,
			};

		default:
			return state;
	}
}
