import { createActivationCodeActionTypes } from './Constants';
import { reset } from 'redux-form';

export function toggleCreateActivationCodeModal(data) {
	return {
		type: createActivationCodeActionTypes.TOGGLE_CREATE_ACTIVATION_CODE_MODAL,
		payload: {
			data,
		},
	};
}

export function getPackages() {
	return {
		type: createActivationCodeActionTypes.GET_ADMIN_PACKAGES,
	};
}

export function getPackagesSuccess(data) {
	return {
		type: createActivationCodeActionTypes.GET_ADMIN_PACKAGES_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getPackagesError(error) {
	return {
		type: createActivationCodeActionTypes.GET_ADMIN_PACKAGES_ERROR,
		payload: {
			error,
		},
	};
}

export function createCode(data) {
	return {
		type: createActivationCodeActionTypes.CREATE_ACTIVATION_CODE,
		payload: { data },
	};
}

export function createCodeSuccess() {
	return {
		type: createActivationCodeActionTypes.CREATE_ACTIVATION_CODE_SUCCESS,
	};
}

export function createCodeError(error) {
	return {
		type: createActivationCodeActionTypes.CREATE_ACTIVATION_CODE_ERROR,
		payload: {
			error,
		},
	};
}

export function resetForm(formName) {
	return reset(formName);
}

export function cleanState() {
	return {
		type: createActivationCodeActionTypes.CLEAN_ACTIVATION_CODE_MODAL_STATE,
	};
}
