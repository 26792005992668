import moment from 'moment';

export const getPreviousReportActivityDate = (previousDate, activeDate) => {
	const lastDate = previousDate ? moment(previousDate) : moment(activeDate);
	return lastDate.subtract(3, 'weeks').format('YYYY-MM-DD');
};

export const getNextReportActivityDate = (previousDate, activeDate) => {
	const lastDate = previousDate ? moment(previousDate) : moment(activeDate);
	return lastDate.add(3, 'weeks').format('YYYY-MM-DD');
};

export const isEmptyReportActivity = (activity) => {
	let userPlaylistSectionsCount = 0;
	for (const key in activity) {
		if (activity[key].user_playlist_sections?.length) {
			userPlaylistSectionsCount += activity[key].user_playlist_sections.length;
		}
	}
	return userPlaylistSectionsCount < 5;
};
