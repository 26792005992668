import keyMirror from 'keymirror';

export const manageCategoryActionTypes = keyMirror({
	TOGGLE_ADMIN_MANAGE_CATEGORY_MODAL: null,

	GET_ADMIN_CATEGORY_DATA_REQUEST: null,
	GET_ADMIN_CATEGORY_DATA_SUCCESS: null,
	GET_ADMIN_CATEGORY_DATA_ERROR: null,

	MANAGE_ADMIN_CATEGORY_REQUEST: null,
	MANAGE_ADMIN_CATEGORY_SUCCESS: null,
	MANAGE_ADMIN_CATEGORY_ERROR: null,

	CLEAN_ADMIN_MANAGE_CATEGORY_STATE: null,
});
