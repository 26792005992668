import { put, call, takeLatest, all } from 'redux-saga/effects';
import { manageDifficultyActionTypes } from './Constants';
import { processRequest } from '../../../../services/Api';
import * as manageDifficultyActions from './Actions';
import * as adminPlaylistActions from '../../../pages/quiz/Actions';
import * as errorHandlerActions from '../../../../components/errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			manageDifficultyActionTypes.MANAGE_DIFFICULTY_REQUEST,
			handleManageDifficultyRequest,
		),
	]);
}

export function* handleManageDifficultyRequest({ payload }) {
	try {
		const { playlistId, formData, callback } = payload || {};
		const data = {};

		if (formData.difficulty_level) {
			data.difficulty_level = formData.difficulty_level.key;
		}

		if (formData.repeat_until) {
			data.repeat_until = formData.repeat_until.key;
		}

		yield call(processRequest, `admin/playlist_sections/${playlistId}`, 'PUT', {
			playlist_section: data,
		});

		callback();

		yield put(manageDifficultyActions.manageDifficultySuccess());
		yield put(adminPlaylistActions.getPlaylistRequest(playlistId, null, false));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(adminPlaylistActions.managePlaylistError(e.response?.data?.error));
	}
}
