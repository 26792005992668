import { searchActionTypes } from './Constants';
import { handleSearchGroupResults } from './Services';

const initialState = {
	loading: false,
	result_sections: [],
	search_terms: '',
	noResults: false,
	allSearchData: [],
};

export default function searchReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data, error, groupId, status } = payload || {};

	switch (type) {
		case searchActionTypes.SEARCH_REQUEST:
			return {
				...state,
				loading: true,
			};

		case searchActionTypes.SET_SEARCH_VALUE:
			return {
				...state,
				...data,
			};

		case searchActionTypes.SET_NO_RESULTS:
			return {
				...state,
				noResults: false,
			};

		case searchActionTypes.SEARCH_SUCCESS:
			return {
				...state,
				...data,
				loading: false,
			};

		case searchActionTypes.CLEAR_RESULT_SECTIONS:
			return {
				...state,
				search_terms: '',
				result_sections: [],
			};

		case searchActionTypes.SEARCH_ERROR:
			return {
				...state,
				error: error.message,
				loading: false,
			};

		case searchActionTypes.CLEAN_SEARCH_STATE:
			return {
				...state,
				search_terms: '',
				result_sections: [],
			};

		case searchActionTypes.UPDATE_GROUP_RESULTS:
			return {
				...state,
				result_sections: handleSearchGroupResults(state.result_sections, status, groupId),
			};

		default:
			return state;
	}
}
