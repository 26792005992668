import { processRequest } from '../../../services/Api';
import { put, call, takeLatest, takeEvery, all } from 'redux-saga/effects';
import i18n from 'i18next';
import { subjectDetailsActionTypes } from './Constants';
import * as subjectDetailsActions from './Actions';
import * as errorHandlerActions from '../../../components/errorHandler/Actions';
import * as notificationActions from '../../../components/notification/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			subjectDetailsActionTypes.GET_ADMIN_SUBJECT_DETAILS_REQUEST,
			handleGetSubjectDetailsRequest,
		),
		yield takeLatest(
			subjectDetailsActionTypes.GET_ADMIN_SUBJECT_CATEGORIES_REQUEST,
			handleGetSubjectCategoriesRequest,
		),
		yield takeLatest(
			subjectDetailsActionTypes.CHANGE_ADMIN_CATEGORY_POSITION_REQUEST,
			handleChangeCategoryPositionRequest,
		),
		yield takeLatest(
			subjectDetailsActionTypes.DELETE_ADMIN_CATEGORY_REQUEST,
			handleDeleteCategoryRequest,
		),
		yield takeEvery(
			subjectDetailsActionTypes.GET_ADMIN_SUBCATEGORIES_REQUEST,
			handleGetSubcategoriesRequest,
		),
		yield takeLatest(
			subjectDetailsActionTypes.DELETE_ADMIN_SUBCATEGORY_REQUEST,
			handleDeleteSubcategoryRequest,
		),
		yield takeLatest(
			subjectDetailsActionTypes.CHANGE_ADMIN_SUBCATEGORY_POSITION_REQUEST,
			handleChangeSubcategoryPositionRequest,
		),
	]);
}

export function* handleGetSubjectDetailsRequest({ payload }) {
	try {
		const { subjectId } = payload || {};

		const { data } = yield call(processRequest, `admin/subjects/${subjectId}`, 'GET');

		yield put(subjectDetailsActions.getSubjectDetailsSuccess(data.subject));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(subjectDetailsActions.getSubjectDetailsError());
	}
}

export function* handleGetSubjectCategoriesRequest({ payload }) {
	try {
		const { subjectId, data: queries } = payload || {};

		const { data } = yield call(
			processRequest,
			`admin/subjects/${subjectId}/topic_categories`,
			'GET',
			queries,
		);

		yield put(subjectDetailsActions.getSubjectCategoriesSuccess(data.topic_categories));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(subjectDetailsActions.getSubjectCategoriesError());
	}
}

export function* handleChangeCategoryPositionRequest({ payload }) {
	try {
		const { data } = payload || {};
		const { id, position, callback } = data || {};

		yield call(processRequest, `admin/topic_categories/${id}`, 'PUT', { position });
		callback && callback();

		yield put(subjectDetailsActions.changeCategoryPositionSuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(subjectDetailsActions.changeCategoryPositionError());
	}
}

export function* handleDeleteCategoryRequest({ payload }) {
	try {
		const { categoryId, callback } = payload || {};

		yield call(processRequest, `admin/topic_categories/${categoryId}`, 'DELETE');
		callback && callback();

		yield put(
			notificationActions.createNotification(
				i18n.t('admin_subjects.delete_notification'),
				null,
				false,
				3000,
			),
		);
		yield put(subjectDetailsActions.deleteCategorySuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(subjectDetailsActions.deleteCategoryError());
	}
}

export function* handleGetSubcategoriesRequest({ payload }) {
	try {
		const { categoryId } = payload || {};

		const { data } = yield call(
			processRequest,
			`admin/topic_categories/${categoryId}/topic_subcategories`,
		);
		yield put(
			subjectDetailsActions.getSubcategoriesSuccess({
				categoryId,
				subcategories: data.results,
			}),
		);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(subjectDetailsActions.getSubcategoriesError());
	}
}

export function* handleDeleteSubcategoryRequest({ payload }) {
	try {
		const { subcategoryId, callback } = payload || {};

		yield call(processRequest, `admin/topic_subcategories/${subcategoryId}`, 'DELETE');
		callback && callback();

		yield put(
			notificationActions.createNotification(
				i18n.t('admin_subjects.delete_notification'),
				null,
				false,
				3000,
			),
		);
		yield put(subjectDetailsActions.deleteCategorySuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(subjectDetailsActions.deleteCategoryError());
	}
}

export function* handleChangeSubcategoryPositionRequest({ payload }) {
	try {
		const { data: formData } = payload || {};
		const { id, position, callback } = formData || {};

		yield call(processRequest, `admin/topic_subcategories/${id}`, 'PUT', { position });
		callback && callback();

		yield put(subjectDetailsActions.changeSubcategoryPositionSuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(subjectDetailsActions.changeSubcategoryPositionError());
	}
}
