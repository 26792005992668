import keyMirror from 'keymirror';

export const dailyReportActionTypes = keyMirror({
	GET_DAILY_REPORT_REQUEST: null,
	GET_DAILY_REPORT_SUCCESS: null,
	GET_DAILY_REPORT_ERROR: null,
});

export const HIDE_REPORT_FEATURE_INFO = 'hideReportFeatureInfo';

export const HIDE_REPORT_FEATURE_HIGHLIGHT = 'hideReportFeatureHighlight';

export const HIDE_REPORT_ITEM_FEATURE_HIGHLIGHT = 'hideReportItemFeatureHighlight';

export const DAILY_PROGRESS_TYPES = {
	SHORT: 'short',
	SMALL: 'small',
	FULL: 'full',
};
