import { resetPasswordActionTypes } from './Constants';

const initialState = {
	loading: false,
	error: '',
};

export default function resetPasswordReducer(state = initialState, action) {
	const { type, payload } = action;
	const { error } = payload || {};

	switch (type) {
		case resetPasswordActionTypes.RESET_PASSWORD_REQUEST:
			return {
				...state,
				loading: true,
			};

		case resetPasswordActionTypes.RESET_PASSWORD_SUCCESS:
			return {
				...state,
				error: '',
				loading: false,
			};

		case resetPasswordActionTypes.RESET_PASSWORD_ERROR:
			return {
				...state,
				error: error.message,
				loading: false,
			};

		default:
			return state;
	}
}
