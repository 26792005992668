import { addWysiwygAnswerModalActionTypes } from './Constants';

const initialState = {
	open: false,
	addEmbedBlock: () => {},
	answerData: null
};

export default function addWysiwygAnswerModalReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data } = payload || {};

	switch (type) {
		case addWysiwygAnswerModalActionTypes.HANDLE_ADD_WYSIWYG_ANSWER_MODAL:
			return {
				...state,
				...data,
			};
		default:
			return state;
	}
}
