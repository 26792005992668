import { profileTypes, groupBySections } from './Constants';
import { removeAccessToken, setAccessToken } from '../../services/AccessToken';

const initialState = {
	loading: false,
	loadingAvatarImage: false,
	loadingPersonalData: false,
	isChangeEmailModal: false,
	isChangePasswordModal: false,
	isDeleteAccountModal: false,
	isCancelSubscriptionModal: false,
	loadingDeleteModal: false,
	loadingCheckPublicName: false,
	loadingSubscription: false,
	loadingCancel: false,
	preferences: null,
	userDetails: null,
	userSubscription: null,
};

export default function profileReducer(state = initialState, action) {
	const { type, payload } = action;
	const { file, status, token, userDetails, subscriptions, preferences, error } = payload || {};

	switch (type) {
		case profileTypes.GET_USER_DETAILS_SUCCESS:
			return {
				...state,
				userDetails,
			};

		case profileTypes.GET_USER_DETAILS_ERROR:
		case profileTypes.GET_NOTIFICATION_PREFERENCES_ERROR:
		case profileTypes.GET_USER_EDUCATION_DETAILS_ERROR:
			return {
				...state,
				error,
			};
		case profileTypes.SET_AVATAR_IMAGE:
			return {
				...state,
				loadingAvatarImage: true,
				file,
			};
		case profileTypes.SET_AVATAR_IMAGE_SUCCESS:
		case profileTypes.SET_AVATAR_IMAGE_ERROR:
			return {
				...state,
				loadingAvatarImage: false,
			};

		case profileTypes.CHECK_PUBLIC_NAME_REC:
			return {
				...state,
				loadingCheckPublicName: true,
			};
		case profileTypes.CHECK_PUBLIC_NAME_RES:
			return {
				...state,
				loadingCheckPublicName: false,
			};

		case profileTypes.TOGGLE_CHANGE_PASSWORD_MODAL:
			return {
				...state,
				isChangePasswordModal: status,
			};
		case profileTypes.SET_NEW_PASSWORD:
			return {
				...state,
				loading: true,
			};
		case profileTypes.SET_NEW_PASSWORD_SUCCESS:
			return {
				...state,
				loading: false,
				isChangePasswordModal: false,
			};
		case profileTypes.SET_NEW_PASSWORD_ERROR:
			return {
				...state,
				loading: false,
			};

		case profileTypes.TOGGLE_CHANGE_EMAIL_MODAL:
			return {
				...state,
				isChangeEmailModal: status,
			};
		case profileTypes.SET_NEW_EMAIL:
			return {
				...state,
				loading: true,
			};
		case profileTypes.SET_NEW_EMAIL_SUCCESS:
			setAccessToken(token);
			return {
				...state,
				loading: false,
				isChangeEmailModal: false,
			};
		case profileTypes.SET_NEW_EMAIL_ERROR:
			return {
				...state,
				loading: false,
			};

		case profileTypes.SET_PERSONAL_DATA_REQUEST:
			return {
				...state,
				loadingPersonalData: true,
			};
		case profileTypes.SET_PERSONAL_DATA_SUCCESS:
		case profileTypes.SET_PERSONAL_DATA_ERROR:
			return {
				...state,
				loadingPersonalData: false,
			};

		case profileTypes.TOGGLE_DELETE_MODAL:
			return {
				...state,
				isDeleteAccountModal: status,
			};
		case profileTypes.DELETE_ACCOUNT_REQUEST:
			return {
				...state,
				loadingDeleteModal: true,
			};
		case profileTypes.DELETE_ACCOUNT_SUCCESS:
			removeAccessToken();
			return {
				...state,
				isDeleteAccountModal: false,
				loadingDeleteModal: false,
			};
		case profileTypes.DELETE_ACCOUNT_ERROR:
			return {
				...state,
				loadingDeleteModal: false,
			};

		case profileTypes.GET_NOTIFICATION_PREFERENCES_REQUEST:
			return {
				...state,
				loading: true,
			};

		case profileTypes.GET_NOTIFICATION_PREFERENCES_SUCCESS:
			return {
				...state,
				preferences: groupBySections(preferences),
			};

		case profileTypes.CLEAN_PROFILE_SETTINGS_STATE:
			return initialState;

		case profileTypes.GET_USER_SUBSCRIPTION:
			return {
				...state,
				loadingSubscription: true,
			};

		case profileTypes.GET_USER_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				userSubscription: subscriptions.length && subscriptions[0],
				loadingSubscription: false,
			};

		case profileTypes.GET_USER_SUBSCRIPTION_ERROR:
			return {
				...state,
				loadingSubscription: false,
			};

		case profileTypes.TOGGLE_CANCEL_SUBSCRIPTION_MODAL:
			return {
				...state,
				isCancelSubscriptionModal: status,
			};

		case profileTypes.CANCEL_SUBSCRIPTION_REQUEST:
			return {
				...state,
				loadingCancel: true,
			};

		case profileTypes.CANCEL_SUBSCRIPTION_SUCCESS:
		case profileTypes.CANCEL_SUBSCRIPTION_ERROR:
			return {
				...state,
				loadingCancel: false,
			};

		default:
			return state;
	}
}
