/* eslint-disable array-callback-return */
import queryString from 'qs';
import { processRequest } from '../../services/Api';
import { put, call, takeEvery, all, select } from 'redux-saga/effects';
import { historyReplace } from '../../services/History';
import { searchActionTypes, DEFAULT_LIMIT, DEFAULT_OFFSET } from './Constants';
import * as searchActions from './Actions';
import { getSearchTerms } from './Selectors';
import { preparedData } from './Services';
import * as errorHandlerActions from '../../components/errorHandler/Actions';

export default function* () {
	yield all([yield takeEvery(searchActionTypes.SEARCH_REQUEST, handleSearchRequest)]);
}

export function* handleSearchRequest({ payload }) {
	try {
		const {
			apply_default_filters = false,
			offset = DEFAULT_OFFSET,
			limit = DEFAULT_LIMIT,
			country_code,
		} = payload.data || {};
		const search_terms = yield select(getSearchTerms);
		const query = {
			apply_default_filters,
			search_terms,
			limit,
			offset,
		};
		if (country_code) query.country_code = country_code;

		const { data } = yield call(processRequest, `search`, 'GET', query);

		const mappedData = preparedData(data);

		yield put(
			searchActions.searchSuccess({
				result_sections: mappedData,
				noResults: !data.length,
				allSearchData: data.result_sections,
			}),
		);
		const filters = { search_terms: search_terms.split(' ').join('+') };
		if (country_code) filters.country_code = country_code;
		const queryS = queryString.stringify(filters, { arrayFormat: 'brackets', encode: false });

		yield call(historyReplace, `/search?${queryS}`);
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(searchActions.searchError());
	}
}
