import keyMirror from 'keymirror';

export const sidebarActionTypes = keyMirror({
	SET_EXPANDED_MENU: null,
	SET_SETTINGS_MENU: null,
});

export const MIN_DESKTOP_WIDTH = 1024;

export const sectionIcons = {
	questions: 'icon-forum',
	users: 'icon-person',
	methods: 'icon-menu-book-outline',
	publishers: 'icon-business',
	subjects: 'icon-grid',
	activation_codes: 'icon-ticket',
	education_types: 'icon-academic',
	topics: 'icon-bookshelf',
};
