import keyMirror from 'keymirror';

export const addWysiwygFormulaModalActionTypes = keyMirror({
	HANDLE_ADD_WYSIWYG_FORMULA_MODAL: null,
});

export const defaultFormulas = [
	{ icon: 'icon-superscript', value: 'x^2' },
	{ icon: 'icon-math', value: '\\sqrt{x}' },
	{ icon: 'icon-division', value: '\\frac{x}{y}' },
	{ icon: 'icon-subscript', value: 'x_2' },
	{ icon: 'icon-pi', value: '\\Pi' },
];
