import keyMirror from 'keymirror';
import { MARKETING_URL } from '../../services/Constants';

export const shopActionTypes = keyMirror({
	GET_PAYMENT_REQUESTS_SHOP_DATA: null,
	GET_PAYMENT_REQUESTS_SHOP_DATA_SUCCESS: null,

	GET_PAYMENT_METHODS_REQUEST: null,
	GET_PAYMENT_METHODS_SUCCESS: null,

	HANDLE_SUBSCRIPTION_REQUEST: null,
	HANDLE_SUBSCRIPTION_SUCCESS: null,

	CLEAN_SHOP_DATA: null,

	GET_FAILED_PAYMENTS_REQUEST: null,
	GET_FAILED_PAYMENTS_SUCCESS: null,
	//TODO need to refactor name of constants
	GET_RECEIPT_DATA: null,
	GET_RECEIPT_DATA_SUCCESS: null,

	CREATE_USER_SHOP_FORM: null,
	CREATE_USER_SHOP_FORM_SUCCESS: null,
	CREATE_USER_SHOP_FORM_ERROR: null,

	GET_SHOP_USER_DATA_REQUEST: null,
	GET_SHOP_USER_DATA_SUCCESS: null,
	GET_SHOP_USER_DATA_ERROR: null,

	VALIDATE_COUPON_REQUEST: null,
	VALIDATE_COUPON_SUCCESS: null,
	VALIDATE_COUPON_ERROR: null,

	CLEAR_VALIDATE_COUPON: null,

	SET_INITIAL_COUPON_CODE: null,
});

export const paymentType = {
	bcmc: 'bancontact',
	scheme: 'card',
};

export const TRIAL_DAYS_PERIOD = 14;

export const TRIAL_DATE_FORMAT = 'DD-MM-YYYY';

export const SHOP_FORM = 'shopForm';
export const SHOP_COUPON_FORM = 'shopCouponForm';

export const TEACHER_PLAN_ID = 6;

export const DISCOUNT_CODE_KEY = 'discountCode';

export const TERMS_AND_CONDITIONS_URL = `${MARKETING_URL}algemene-voorwaarden`;

export const PRIVACY_URL = `${MARKETING_URL}privacy`;
