import { manageCategoryActionTypes } from './Constants';

export function toggleManageCategoryModal({ open, categoryId, subject }) {
	return {
		type: manageCategoryActionTypes.TOGGLE_ADMIN_MANAGE_CATEGORY_MODAL,
		payload: {
			open,
			categoryId,
			subject,
		},
	};
}

export function manageCategoryRequest({ formData, categoryId, subjectId, callback }) {
	return {
		type: manageCategoryActionTypes.MANAGE_ADMIN_CATEGORY_REQUEST,
		payload: {
			formData,
			categoryId,
			subjectId,
			callback,
		},
	};
}

export function manageCategorySuccess() {
	return {
		type: manageCategoryActionTypes.MANAGE_ADMIN_CATEGORY_SUCCESS,
	};
}

export function manageCategoryError(error) {
	return {
		type: manageCategoryActionTypes.MANAGE_ADMIN_CATEGORY_ERROR,
		payload: {
			error,
		},
	};
}

export function cleanManageCategoryState() {
	return {
		type: manageCategoryActionTypes.CLEAN_ADMIN_MANAGE_CATEGORY_STATE,
	};
}

export function getCategoryDataRequest(id) {
	return {
		type: manageCategoryActionTypes.GET_ADMIN_CATEGORY_DATA_REQUEST,
		payload: { id },
	};
}

export function getCategoryDataSuccess({ name, subject }) {
	return {
		type: manageCategoryActionTypes.GET_ADMIN_CATEGORY_DATA_SUCCESS,
		payload: {
			name,
			subject,
		},
	};
}

export function getCategoryDataError(error) {
	return {
		type: manageCategoryActionTypes.GET_ADMIN_CATEGORY_DATA_ERROR,
		payload: {
			error,
		},
	};
}
