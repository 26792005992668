import { resetPasswordActionTypes } from './Constants';

export function resetPasswordRequest(resetToken, password) {
	return {
		type: resetPasswordActionTypes.RESET_PASSWORD_REQUEST,
		payload: {
			resetToken,
			password,
		},
	};
}

export function resetPasswordSuccess() {
	return {
		type: resetPasswordActionTypes.RESET_PASSWORD_SUCCESS,
	};
}

export function resetPasswordError(error) {
	return {
		type: resetPasswordActionTypes.RESET_PASSWORD_ERROR,
		payload: {
			error,
		},
	};
}
