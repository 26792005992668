import Bugsnag from '@bugsnag/js';
import { errorHandlerActionTypes } from './Constants';

const initialState = {
	error: null,
	statusCode: '',
	ignoreError: false,
	displayNotification: false,
	hasError: false,
	typeError: 'page-blocking',
	customNotification: null,
};

export default function errorHandlerReducer(state = initialState, action) {
	const { type, payload } = action;
	const { error, typeError, customNotification } = payload || {};
	const { data, status } = (error && error.response) || {};

	switch (type) {
		case errorHandlerActionTypes.HANDLE_ERROR:
			let params = {};

			if (state.hasError && (typeError === 'notification' || typeError === 'ignore')) {
				params = {
					...state,
				};
			} else {
				params = {
					ignoreError: typeError === 'ignore',
					displayNotification: typeError === 'notification',
				};
			}

			Bugsnag.notify(error);

			return {
				...params,
				hasError: true,
				error: data,
				statusCode: status,
				typeError,
				customNotification,
			};

		case errorHandlerActionTypes.CLEAR_ERROR:
			return initialState;

		default:
			return state;
	}
}
