import { notificationActionTypes } from './Constants';

export function createNotification(title, subtitle, error, timeout, clearPreviously) {
	return {
		type: notificationActionTypes.CREATE_NOTIFICATION_MESSAGE,
		payload: {
			title,
			subtitle,
			error,
			timeout,
			clearPreviously
		},
	};
}

export function closeNotification(id) {
	return {
		type: notificationActionTypes.NOTIFICATION_MESSAGE_CLOSE,
		payload: {
			id,
		},
	};
}

export function clearNotification() {
	return {
		type: notificationActionTypes.CLEAR_STATE,
	};
}
