import { forgotPasswordActionTypes } from './Constants';

const initialState = {
	loading: false,
	initialEmail: null,
};

export default function forgotPasswordReducer(state = initialState, action) {
	const { type, payload } = action;
	const { email } = payload || {};

	switch (type) {
		case forgotPasswordActionTypes.FORGOT_PASSWORD_REQUEST:
			return {
				...state,
				loading: true,
			};

		case forgotPasswordActionTypes.FORGOT_PASSWORD_SUCCESS:
		case forgotPasswordActionTypes.FORGOT_PASSWORD_ERROR:
			return {
				...state,
				loading: false,
			};

		case forgotPasswordActionTypes.SET_INITIAL_EMAIL:
			return {
				...state,
				initialEmail: email,
			};

		default:
			return state;
	}
}
