import { shareVideoModalActionTypes } from './Constants';

const initialState = {
	open: false,
	loading: false,
	summaryId: null,
	type: null,
	linkData: {},
};

export default function manageQAModalReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data } = payload || {};

	switch (type) {
		case shareVideoModalActionTypes.TOGGLE_SHARE_VIDEO_MODAL:
			return {
				...state,
				...data,
			};
		case shareVideoModalActionTypes.GET_SHARE_VIDEO_LINK:
			return {
				...state,
				loading: true,
			};
		case shareVideoModalActionTypes.GET_SHARE_VIDEO_LINK_SUCCESS:
			return {
				...state,
				linkData: data,
				loading: false,
			};
		case shareVideoModalActionTypes.GET_SHARE_VIDEO_LINK_ERROR:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}
