import { shopActionTypes } from './Constants';

const initialState = {
	loading: false,
	shopData: {},
	discountCode: {},
	paymentMethods: null,
	paymentIsFulfilled: false,
	loadingReceiptData: false,
	loadingCreateUser: false,
	loadingGetUser: false,
	loadingValidateCoupon: false,
	userData: {},
	validateCouponData: {},
};

export default function shopReducer(state = initialState, action) {
	const { type, payload } = action;
	const { paymentMethods, shopData, discountCode, paymentType, userData, data, couponCode } =
		payload || {};

	switch (type) {
		case shopActionTypes.GET_PAYMENT_REQUESTS_SHOP_DATA:
		case shopActionTypes.GET_FAILED_PAYMENTS_REQUEST:
			return {
				...state,
				loading: !paymentType,
			};
		case shopActionTypes.GET_PAYMENT_REQUESTS_SHOP_DATA_SUCCESS:
			return {
				...state,
				shopData,
				discountCode,
				loading: false,
			};

		case shopActionTypes.GET_FAILED_PAYMENTS_SUCCESS:
			return {
				...state,
				shopData,
				discountCode,
				loading: false,
			};

		case shopActionTypes.GET_PAYMENT_METHODS_SUCCESS:
			return {
				...state,
				paymentMethods,
			};

		case shopActionTypes.HANDLE_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				paymentIsFulfilled: true,
			};
		case shopActionTypes.GET_RECEIPT_DATA:
			return {
				...state,
				loadingReceiptData: true,
			};
		case shopActionTypes.GET_RECEIPT_DATA_SUCCESS:
			return {
				...state,
				shopData,
				loadingReceiptData: false,
			};
		case shopActionTypes.CLEAN_SHOP_DATA:
			return {
				...state,
				loading: false,
				shopData: {},
				paymentMethods: null,
				paymentIsFulfilled: false,
			};
		case shopActionTypes.CREATE_USER_SHOP_FORM:
			return {
				...state,
				loadingCreateUser: true,
			};
		case shopActionTypes.CREATE_USER_SHOP_FORM_SUCCESS:
			return {
				...state,
				loadingCreateUser: false,
			};
		case shopActionTypes.CREATE_USER_SHOP_FORM_ERROR:
			return {
				...state,
				loadingCreateUser: false,
			};
		case shopActionTypes.GET_SHOP_USER_DATA_REQUEST:
			return {
				...state,
				loadingGetUser: true,
			};
		case shopActionTypes.GET_SHOP_USER_DATA_SUCCESS:
			return {
				...state,
				userData,
				loadingGetUser: false,
			};
		case shopActionTypes.GET_SHOP_USER_DATA_ERROR:
			return {
				...state,
				loadingGetUser: false,
			};
		case shopActionTypes.VALIDATE_COUPON_REQUEST:
			return {
				...state,
				loadingValidateCoupon: true,
			};
		case shopActionTypes.VALIDATE_COUPON_SUCCESS:
			return {
				...state,
				validateCouponData: data,
				loadingValidateCoupon: false,
			};
		case shopActionTypes.VALIDATE_COUPON_ERROR:
			return {
				...state,
				loadingValidateCoupon: false,
			};
		case shopActionTypes.CLEAR_VALIDATE_COUPON:
			return {
				...state,
				validateCouponData: {},
			};
		default:
			return state;
	}
}
