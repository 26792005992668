import { pushNotificationActionTypes } from './Constants';

export function registerPushNotificationForDeviceRequest(deviceToken, ignoreError = true) {
	return {
		type: pushNotificationActionTypes.REGISTER_PUSH_NOTIFICATION_REQUEST,
		payload: {
			deviceToken,
			ignoreError,
		},
	};
}

export function registerPushNotificationForDeviceSuccess() {
	return {
		type: pushNotificationActionTypes.REGISTER_PUSH_NOTIFICATION_SUCCESS,
	};
}

export function registerPushNotificationForDeviceError() {
	return {
		type: pushNotificationActionTypes.REGISTER_PUSH_NOTIFICATION_ERROR,
	};
}

export function deregisterPushNotificationForDeviceRequest(
	deviceToken,
	status = 'deregistered',
	ignoreError = true,
) {
	return {
		type: pushNotificationActionTypes.DEREGISTER_PUSH_NOTIFICATION_REQUEST,
		payload: {
			deviceToken,
			status,
			ignoreError,
		},
	};
}

export function deregisterPushNotificationForDeviceSuccess(status) {
	return {
		type: pushNotificationActionTypes.DEREGISTER_PUSH_NOTIFICATION_SUCCESS,
		payload: {
			status,
		},
	};
}

export function deregisterPushNotificationForDeviceError() {
	return {
		type: pushNotificationActionTypes.DEREGISTER_PUSH_NOTIFICATION_ERROR,
	};
}
