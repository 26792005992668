import { getLocalStorageItem } from '../../services/Helper';

export const getCountryCode = (onlyCode) => {
	const countryCode = getLocalStorageItem('selectedCountry');

	return countryCode
		? onlyCode
			? countryCode
			: { country_code: countryCode }
		: onlyCode
			? ''
			: {};
};
