import { notificationsActionTypes } from './Constants';

export function toggleNotificationsMenu(status) {
	return {
		type: notificationsActionTypes.TOGGLE_NOTIFICATIONS_MENU,
		payload: {
			status,
		},
	};
}

export function getNotificationsRequest() {
	return {
		type: notificationsActionTypes.GET_NOTIFICATIONS_REQUEST,
	};
}

export function getNotificationsSuccess(notificationsData) {
	return {
		type: notificationsActionTypes.GET_NOTIFICATIONS_SUCCESS,
		payload: {
			notificationsData,
		},
	};
}

export function getNotificationsError() {
	return {
		type: notificationsActionTypes.GET_NOTIFICATIONS_ERROR,
	};
}

export function updateNotificationStatusRequest(notificationId) {
	return {
		type: notificationsActionTypes.UPDATE_NOTIFICATION_STATUS_REQUEST,
		payload: {
			notificationId,
		},
	};
}

export function updateNotificationStatusError() {
	return {
		type: notificationsActionTypes.UPDATE_NOTIFICATION_STATUS_ERROR,
	};
}
