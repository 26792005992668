import './styles/main.scss';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'moment/min/locales';
import React, { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { ConnectedRouter } from 'connected-react-router';
import { LastLocationProvider } from 'react-router-last-location';
import './utils/localization/i18next';
import { IS_MOBILE_APP, IS_ANDROID_APP, IS_IOS_APP, IS_ADMIN } from './services/Constants';
import {
	setClientVersion,
	setClientBuildNumber,
	handleRatingPlugin,
	initializeBugsnag,
} from './services/Helper';
import { history, store } from './store';
import { componentLoader } from './componentLoader';
import ConditionalWrapper from './components/ConditionalWrapper';

let App;
const RootElement = ReactDOM.createRoot(document.getElementById('root'));

async function startApp() {
	if (IS_ADMIN) {
		App = lazy(() => componentLoader(() => import('./wrts-admin/AdminApp')), 3);
	} else {
		App = lazy(() => componentLoader(() => import('./App')), 3);
	}

	const html = document.getElementsByTagName('html')[0];

	if (!IS_MOBILE_APP) {
		// To prevent rendertron from injecting base tag with its origin,
		// which will results in CORS issue on loading static (js/css) files.
		// https://github.com/GoogleChrome/rendertron/blob/7999fc591901e2038f4b664e5098e6d8589da177/src/renderer.ts#L72
		const head = html.getElementsByTagName('head')[0];
		const base = document.createElement('base');
		const port = window.location.port ? `:${window.location.port}` : '';
		const origin = `${window.location.protocol}//${window.location.hostname}${port}`;
		base.setAttribute('href', origin);
		head.append(base);
	}

	window.addEventListener('keyboardDidShow', () => {
		html.classList.add('keyboard-on');
	});

	window.addEventListener('keyboardDidHide', () => {
		html.classList.remove('keyboard-on');
	});

	if (IS_MOBILE_APP) {
		const appVersion = await window.cordova.getAppVersion.getVersionNumber();
		const appBuildNumber = await window.cordova.getAppVersion.getVersionCode();
		await setClientVersion(appVersion);
		await setClientBuildNumber(appBuildNumber);

		window.open = window.cordova.InAppBrowser.open;

		html.classList.add('cordova');

		if (IS_ANDROID_APP) {
			html.classList.add('cordova-android');
		} else if (IS_IOS_APP) {
			html.classList.add('cordova-ios');
			document.body.id = 'scrollable';
		}

		window.cutout.has().then((result) => {
			if (result) {
				html.classList.add('cordova-notch');
			}
		});

		handleRatingPlugin();
	}

	const ErrorBoundary = initializeBugsnag();

	RootElement.render(
		<ConditionalWrapper
			condition={ErrorBoundary}
			wrapper={(children) => <ErrorBoundary children={children} />}
		>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<LastLocationProvider>
						<Suspense fallback={<></>}>
							<App />
						</Suspense>
					</LastLocationProvider>
				</ConnectedRouter>
			</Provider>
		</ConditionalWrapper>,
	);
}

if (IS_MOBILE_APP) {
	document.addEventListener('deviceready', startApp, false);
} else {
	startApp();
}
