import { all, call, put, takeLatest } from 'redux-saga/effects';
import i18n from 'i18next';
import { processRequest } from '../../../services/Api';
import { historyPush } from '../../../services/History';
import * as adminInstructionActions from './Actions';
import * as errorHandlerActions from '../../../components/errorHandler/Actions';
import * as notificationActions from '../../../components/notification/Actions';
import * as exerciseActions from '../../../pages/exercise/Actions';
import { adminInstructionActionTypes } from './Constants';

export default function* () {
	yield all([
		yield takeLatest(
			adminInstructionActionTypes.GET_TOPIC_INSTRUCTION_REQUEST,
			handleTopicInstructionRequest,
		),
		yield takeLatest(
			adminInstructionActionTypes.MANAGE_TOPIC_INSTRUCTION_REQUEST,
			handleManageTopicInstructionRequest,
		),
		yield takeLatest(
			adminInstructionActionTypes.PREVIEW_TOPIC_INSTRUCTION_REQUEST,
			handlePreviewInstructionRequest,
		),
		yield takeLatest(
			adminInstructionActionTypes.PUBLISH_TOPIC_INSTRUCTION_REQUEST,
			handlePublishInstructionRequest,
		),
		yield takeLatest(
			adminInstructionActionTypes.RESET_TOPIC_INSTRUCTION_REQUEST,
			handleResetInstructionRequest,
		),
		yield takeLatest(
			adminInstructionActionTypes.DELETE_TOPIC_INSTRUCTION_REQUEST,
			handleDeleteInstructionRequest,
		),
	]);
}

export function* handleTopicInstructionRequest({ payload }) {
	const { topicId } = payload || {};
	let result = {};

	try {
		const { data } = yield call(processRequest, `admin/topics/${topicId}/instruction`);
		result = data.instruction;

		yield put(adminInstructionActions.getInstructionSuccess(result));
	} catch (e) {
		if (e.response.status === 404) {
			const {
				data: { topic },
			} = yield call(processRequest, `admin/topics/${topicId}`);

			result.topic = topic;

			yield put(adminInstructionActions.getInstructionSuccess(result));
		} else {
			yield put(errorHandlerActions.handleError(e));
			yield put(adminInstructionActions.getInstructionError());
		}
	}
}

export function* handleManageTopicInstructionRequest({ payload }) {
	try {
		const { data } = payload || {};
		const { topicId, type, title, content } = data || {};
		const requestType = type === 'create' ? 'POST' : 'PUT';

		const {
			data: { instruction },
		} = yield call(processRequest, `admin/topics/${topicId}/instruction`, requestType, {
			title,
			content,
		});

		yield put(adminInstructionActions.manageInstructionSuccess(instruction));

		yield put(
			notificationActions.createNotification(
				i18n.t(`admin_instruction_editor.instruction_updated`),
				null,
				false,
				3000,
			),
		);
	} catch (e) {
		const { response } = e;
		const { data } = response || {};
		yield put(
			errorHandlerActions.handleError(e, 'notification', {
				code: 422,
				message: data.error,
			}),
		);
		yield put(adminInstructionActions.manageInstructionError());
	}
}

export function* handlePreviewInstructionRequest({ payload }) {
	try {
		const { topicId } = payload || {};

		const { data } = yield call(processRequest, `admin/topics/${topicId}/instruction/preview`);

		yield call(historyPush, `/question-set/${topicId}/preview`);

		yield put(exerciseActions.getExerciseSuccess(data));

		yield put(adminInstructionActions.previewInstructionSuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(adminInstructionActions.previewInstructionError());
	}
}

export function* handlePublishInstructionRequest({ payload }) {
	try {
		const { topicId } = payload || {};

		const {
			data: { instruction },
		} = yield call(processRequest, `admin/topics/${topicId}/instruction/publish`, 'POST');

		yield put(adminInstructionActions.publishInstructionSuccess(instruction));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(adminInstructionActions.publishInstructionError());
	}
}

export function* handleDeleteInstructionRequest({ payload }) {
	try {
		const { data } = payload || {};
		const { topicId, callback } = data || {};

		yield call(processRequest, `admin/topics/${topicId}/instruction`, 'DELETE');

		callback && callback();

		yield put(adminInstructionActions.deleteInstructionSuccess());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(adminInstructionActions.deleteInstructionError());
	}
}

export function* handleResetInstructionRequest({ payload }) {
	try {
		const { topicId } = payload || {};

		yield call(processRequest, `admin/topics/${topicId}/instruction/reset`, 'POST');

		yield put(adminInstructionActions.resetInstructionSuccess());
		yield put(adminInstructionActions.clearState());
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(adminInstructionActions.resetInstructionError());
	}
}
