import { dailyReportActionTypes } from './Constants';

export function getDailyReportRequest(date) {
	return {
		type: dailyReportActionTypes.GET_DAILY_REPORT_REQUEST,
		payload: {
			date,
		},
	};
}

export function getDailyReportSuccess(data) {
	return {
		type: dailyReportActionTypes.GET_DAILY_REPORT_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getDailyReportError(error) {
	return {
		type: dailyReportActionTypes.GET_DAILY_REPORT_ERROR,
		error: true,
		payload: {
			error,
		},
	};
}
