import { memo } from 'react';
import PropTypes from 'prop-types';

const ConditionalWrapper = ({ condition, wrapper, children }) =>
	condition ? wrapper(children) : children;

ConditionalWrapper.propTypes = {
	wrapper: PropTypes.func,
	children: PropTypes.object.isRequired,
};

export default memo(ConditionalWrapper);
