import { progressActionTypes } from './Constants';

const initialState = {
	loading: false,
	activity: {},
	subjects: [],
	openInfoModal: null,
	progressDetails: {
		topic_levels: [],
	},
};

export default function progressReducer(state = initialState, action) {
	const { type, payload } = action;
	const { activity, subjects, openInfoModal, progressDetails } = payload || {};

	switch (type) {
		case progressActionTypes.GET_PROGRESS_OVERVIEW_REQUEST:
			return {
				...state,
				loading: true,
			};

		case progressActionTypes.GET_PROGRESS_OVERVIEW_SUCCESS:
			return {
				...state,
				loading: false,
				activity,
				subjects,
			};

		case progressActionTypes.GET_PROGRESS_OVERVIEW_ERROR:
			return {
				...state,
				loading: false,
			};

		case progressActionTypes.GET_PROGRESS_DETAILS_REQUEST:
			return {
				...state,
				loading: true,
			};

		case progressActionTypes.GET_PROGRESS_DETAILS_SUCCESS:
			return {
				...state,
				loading: false,
				progressDetails,
			};

		case progressActionTypes.GET_PROGRESS_DETAILS_ERROR:
			return {
				...state,
				loading: false,
			};

		case progressActionTypes.TOGGLE_INFO_MODAL:
			return {
				...state,
				openInfoModal,
			};

		case progressActionTypes.CLEAR_PROGRESS_DETAILS:
			return {
				...state,
				progressDetails: initialState.progressDetails,
			};

		default:
			return state;
	}
}
