import { getLocalStorageItem } from '../../services/Helper';
import { deleteExperiment, handleExperiment, parentShopTypes } from './Constants';
import { put, call, takeLatest, all, take } from 'redux-saga/effects';
import { processRequest } from '../../services/Api';
import * as parentShopActions from './Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(parentShopTypes.GET_LEVELS_REQUEST, handleGetLevelsRequest),
		yield takeLatest(parentShopTypes.GET_PACKAGES_REQUEST, handleGetPackagesRequest),
		yield takeLatest(parentShopTypes.HANDLE_USER_EXPERIMENT, handleUserExperimentRequest),
	]);
}

export function* handleUserExperimentRequest(action) {
	const { experimentName } = action.payload;

	try {
		const { data } = yield call(processRequest, `public/experiments/${experimentName}`);
		if (data.experiment) {
			yield call(handleExperiment, data.experiment);
		}
		yield put(parentShopActions.handleUserExperimentSuccess(experimentName));
	} catch (e) {
		yield call(deleteExperiment, experimentName);
		yield put(parentShopActions.handleUserExperimentSuccess(experimentName));
		yield put(errorHandlerActions.handleError(e, 'ignore'));
	}
}

export function* handleGetLevelsRequest() {
	try {
		//later way how we handle experiment can be changed
		yield put(parentShopActions.handleUserExperiment('2022-Q1-two-next-packages'));
		yield take(parentShopTypes.HANDLE_USER_EXPERIMENT_SUCCESS);

		const discountCode = getLocalStorageItem('discountCode');
		const requestPayload = {};

		if (discountCode) {
			requestPayload.discount_code = discountCode;
		}

		const { data } = yield call(processRequest, 'public/shop_types', 'GET', requestPayload);

		yield put(parentShopActions.getLevelsSuccess(data));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(parentShopActions.getLevelsError(e));
	}
}

export function* handleGetPackagesRequest(action) {
	try {
		const { id } = action.payload;

		//later way how we handle experiment can be changed
		yield put(parentShopActions.handleUserExperiment('2022-Q1-two-next-packages'));
		yield take(parentShopTypes.HANDLE_USER_EXPERIMENT_SUCCESS);

		const discountCode = getLocalStorageItem('discountCode');
		const requestPayload = {};

		if (discountCode) {
			requestPayload.discount_code = discountCode;
		}

		const { data } = yield call(
			processRequest,
			`public/shop_types/${id}/packages`,
			'GET',
			requestPayload,
		);

		yield put(parentShopActions.getPackagesSuccess(data));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
		yield put(parentShopActions.getPackagesError(e));
	}
}
