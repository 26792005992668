import { addWysiwygImageModalActionTypes } from './Constants';

const initialState = {
	loading: false,
	open: false,
	quill: null,
	inputFile: null,
};

export default function addWysiwygImageModalReducer(state = initialState, action) {
	const { type, payload } = action;
	const { data } = payload || {};

	switch (type) {
		case addWysiwygImageModalActionTypes.HANDLE_ADD_WYSIWYG_IMAGE_MODAL:
			return {
				...state,
				...data,
			};
		case addWysiwygImageModalActionTypes.UPLOAD_WYSIWYG_IMAGE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case addWysiwygImageModalActionTypes.UPLOAD_WYSIWYG_IMAGE_SUCCESS:
			return {
				...state,
				loading: false,
				open: false,
			};
		case addWysiwygImageModalActionTypes.UPLOAD_WYSIWYG_IMAGE_ERROR:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
}
