import { manageDifficultyActionTypes } from './Constants';

const initialState = {
	open: false,
	loading: false,
	playlist: null,
};

export default function manageDifficultyReducer(state = initialState, action) {
	const { type, payload } = action;
	const { open, playlist } = payload || {};

	switch (type) {
		case manageDifficultyActionTypes.TOGGLE_MANAGE_DIFFICULTY_MODAL:
			return {
				...state,
				open,
				playlist,
			};

		case manageDifficultyActionTypes.MANAGE_DIFFICULTY_REQUEST:
			return {
				...state,
				loading: true,
			};

		case manageDifficultyActionTypes.MANAGE_DIFFICULTY_SUCCESS:
		case manageDifficultyActionTypes.MANAGE_DIFFICULTY_ERROR:
			return {
				...state,
				loading: false,
			};

		case manageDifficultyActionTypes.CLEAN_MANAGE_DIFFICULTY_STATE:
			return initialState;

		default:
			return state;
	}
}
