import { select, takeLatest, all, call, put } from 'redux-saga/effects';
import { stickersOnboardingActionTypes } from './Constants';
import { getUserId } from '../learningPath/Selectors';
import { processRequest } from '../../services/Api';
import * as sharedActions from '../../shared/Actions';
import * as stickersOnboardedActions from './Actions';
import * as learningPathActions from '../learningPath/Actions';
import * as dailyGoalActions from '../dailyGoal/Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			stickersOnboardingActionTypes.SEND_STICKERS_ONBOARDED_REQUEST,
			handleSendStickersOnboardedRequest,
		),
	]);
}

export function* handleSendStickersOnboardedRequest() {
	try {
		const userId = yield select(getUserId);

		yield call(processRequest, `users/${userId}`, 'PUT', {
			user: { sticker_onboarded: true },
		});

		yield put(stickersOnboardedActions.sendStickersOnboardedSuccess());

		yield put(sharedActions.getUserDataRequest());
		yield put(dailyGoalActions.getUserStickersRequest());
		yield put(learningPathActions.setLearningPathUpdated(true));
		yield put(learningPathActions.setFinishTileAnimation(true));
	} catch (e) {
		yield put(stickersOnboardedActions.sendStickersOnboardedError());
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}
